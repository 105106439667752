<template>
  <div>
    <div class="card-settings-title">
      <div>
        {{ $t('Deposit fee') }}
      </div>
    </div>
    <div class="card-settings-body h-scroll">
      <table class="w-full">
        <tbody>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Mint fee') }}</th>
          <td>{{ settings['5'] }} %</td>
          <td class="pl-3 text-right">
            <span :class="[fixs['5'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['5'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Users minimal fee') }}</th>
          <td>{{ `${settings['7']} ${symbol}`}}</td>
          <td class="pl-3 text-right">
            <span :class="[fixs['7'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['7'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Owners percent of users fee') }}</th>
          <td>{{ settings['8'] }} %</td>
          <td class="pl-3 text-right">
            <span :class="[fixs['8'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['8'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('contractsettings', {
      settings: 'settings',
      fixs: 'fixs',
      symbol: 'symbol',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
