<template>
  <div
    aria-hidden="true"
    class="copy"
    :class="{'hoverhint': hoverHint}"
    :data-tooltip="hoverText"
    @click="clickHandler"
  >
    <feather-icon v-if="check" type="check-circle" size="18" />
    <feather-icon v-else type="copy" class="mt-2px" size="18" />
  </div>
</template>

<script>
import { copyToClipboard } from '@/utils';

export default {
  props: {
    textToCopy: {
      type: String,
      required: true,
    },

    hoverHint: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      timer: 0,
      check: false,
      hoverText: 'Copy to clipboard',
    };
  },

  methods: {
    clickHandler() {
      copyToClipboard(this.textToCopy);
      this.hoverText = 'Copied';
      this.check = true;

      this.timer = setTimeout(() => {
        this.hoverText = 'Copy to clipboard';
        this.check = false;
      }, 1000);
    },
  },

  beforeUnmount() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.copy {
  display: flex;
  position: relative;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.hoverhint {
  transition: all 0.1s;

  &:hover {
    &::after {
      background-color: #6b7280;
      bottom: 30px;
      color: #fff;
      content: attr(data-tooltip);
      left: 0;
      padding: 2px;
      position: absolute;
      z-index: 98;
      min-width: 120px;
      font-size: 0.675rem;
      text-align: center;
    }
  }
}
</style>
