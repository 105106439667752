<template>
  <div
    class="relative"
    v-click-out="closeNetworkMenu"
  >
    <div
      aria-hidden="true"
      class="network-selected"
      @click="showNetworkMenu"
    >
      <div class="flex items-center gap-2">
        <img
          :src="resolveImgUrl(getSelectedNetwork.img)"
          alt="img"
          class="w-24px"
        >
        <span class="network-selected-title">
          {{ getSelectedNetwork.title }}
        </span>
      </div>
      <feather-icon
        type="chevron-down"
        size="18"
        :class="{'rotate': isShowNetworkMenu}"
      />
    </div>
    <ul
      v-if="isShowNetworkMenu"
      class="network-select-menu"
      @click.stop
    >
      <li
        v-for="(network, i) in networkOptions"
        :key="i"
        aria-hidden="true"
        class="network-select-item"
        :class="{'selected': network.chainId === getSelectedNetwork.chainId}"
        @click="changeNetworkHandler(network)"
      >
        <div class="flex items-center gap-2">
          <img
            :src="resolveImgUrl(network.img)"
            alt="img"
            class="w-24px"
          >
          <div>{{ network.title }}</div>
        </div>
        <feather-icon
          v-if="network.chainId === getSelectedNetwork.chainId"
          class="icon-check"
          type="check"
          size="18"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      isShowNetworkMenu: false,
    };
  },

  computed: {
    ...mapState('app', {
      networkOptions: 'networkOptions',
    }),
    ...mapGetters('app', {
      getSelectedNetwork: 'getSelectedNetwork',
    }),
  },

  methods: {
    ...mapMutations('app', ['setNetwork']),
    ...mapActions('app', ['networkChange']),

    resolveImgUrl(path) {
      const images = require.context('@/assets/img/', false, /\.png$|\.jpg$|\.svg$/);
      return images(`./${path}`);
    },

    showNetworkMenu() {
      this.isShowNetworkMenu = !this.isShowNetworkMenu;
    },

    closeNetworkMenu() {
      this.isShowNetworkMenu = false;
    },

    async changeNetworkHandler(network) {
      try {
        await this.networkChange(network);
        this.setNetwork(network.chainId);
      } catch (e) {
        console.error(e);
      }
      this.closeNetworkMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
.network-selected {
  @apply h-36px rounded-lg px-12px flex items-center gap-2;
  @apply hover:bg-gray-200 cursor-pointer;
  @apply border-solid border-1 border-gray-200;
}

.network-select-menu {
  @apply shadow-primary rounded-md flex flex-col p-2;
  width: 250px;
  position: absolute;
  right: 0;
  top: 44px;
  left: auto;
  background: #fff;
  border: 1px solid theme('colors.border-color');
  z-index: 101;
}

.network-select-item {
  @apply flex items-center gap-2 px-2 py-2 justify-between;
  @apply cursor-pointer hover:bg-gray-200 rounded-md;
}

.selected {
  .icon-check {
    @apply text-primary-500;
  }
}

.network-selected-title {
  @include tablet {
    @apply hidden;
  }
}

.rotate {
  transform: rotate(-180deg);
}

.checked {
  @apply text-primary-500;
}

.chain-change {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
