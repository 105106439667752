import { Decimal } from 'decimal.js';
import {
  numberFormatFloat,
  currencyPriceFormatter,
} from '@/utils';

Decimal.set({ toExpPos: 40 });

export default {
  namespaced: true,

  state: {
    name: '',
    symbol: '',
    decimals: '',
    totalSupply: '',
    totalSupplyFormat: '',

    collateral: '',
    owner: '',
    host: '',
    price: '',
    marketCap: '',

    myBalance: '',
    collateralBalance: '',
    collateralSymbol: '',
    collateralDecimals: '',

    settings: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: '',

      5: '', // depositFee
      6: 100, // depositFeeDivider
      7: '', // minDepositFee
      8: '', // ownerDepositFee
      9: 100, // ownerDepositFeeDivider

      10: '', // withdrawalFee
      11: 100, // withdrawalFeeDivider
      12: '', // minWithdrawalFee
      13: '', // ownerWithdrawalFee
      14: 100, // ownerWithdrawalFeeDivider

      15: '', // lockWithdrawal
      16: '', // lockLost
      17: '', // percentBurn
    },

    fixs: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,

      5: false, // depositFeeFix
      6: false, // depositFeeDividerFix
      7: false, // minDepositFeeFix
      8: false, // ownerDepositFeeFix
      9: false, // ownerDepositFeeDividerFix

      10: false, // withdrawalFeeFix
      11: false, // withdrawalFeeDividerFix
      12: false, // minWithdrawalFeeFix
      13: false, // ownerWithdrawalFeeFix
      14: false, // ownerWithdrawalFeeDividerFix

      15: false, // lockWithdrawalFix
      16: false, // lockLostFix
      17: false, // percentBurnFix
    },

    fixsView: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,

      5: false, // depositFeeFix
      6: false, // depositFeeDividerFix
      7: false, // minDepositFeeFix
      8: false, // ownerDepositFeeFix
      9: false, // ownerDepositFeeDividerFix

      10: false, // withdrawalFeeFix
      11: false, // withdrawalFeeDividerFix
      12: false, // minWithdrawalFeeFix
      13: false, // ownerWithdrawalFeeFix
      14: false, // ownerWithdrawalFeeDividerFix

      15: false, // lockWithdrawalFix
      16: false, // lockLostFix
      17: false, // percentBurnFix
    },
  },

  getters: {
    price(state, getters, rootState, rootGetters) {
      const wbtcPrice = rootGetters['app/wbtcPrice'];
      const priceUsd = new Decimal(state.price).mul(wbtcPrice);
      return currencyPriceFormatter(priceUsd);
    },

    marketCap(state, getters, rootState, rootGetters) {
      const wbtcPrice = rootGetters['app/wbtcPrice'];
      const marketCapUsd = new Decimal(state.marketCap).mul(wbtcPrice);
      return currencyPriceFormatter(marketCapUsd);
    },

    settings(state) {
      return {
        ...state.settings,
        0: state.settings['0']
          ? state.settings['0']
          : 0,
        1: state.settings['1']
          ? state.settings['1']
          : 0,
        2: state.settings['2']
          ? state.settings['2']
          : 0,
        3: state.settings['3']
          ? state.settings['3']
          : 0,
        4: state.settings['4']
          ? state.settings['4']
          : 0,
        7: state.settings['7'] && state.decimals
          ? new Decimal(state.settings['7'])
            .mul(Decimal.pow(10, state.decimals))
            .toString()
          : state.settings['7'],
        12: state.settings['12'] && state.collateralDecimals
          ? new Decimal(state.settings['12'])
            .mul(Decimal.pow(10, state.collateralDecimals))
            .toString()
          : state.settings['7'],
        15: state.settings['15']
          ? +state.settings['15'] * 86400
          : state.settings['15'],
        16: state.settings['16']
          ? +state.settings['16'] * 86400
          : state.settings['16'],
      };
    },
  },

  mutations: {
    setName(state, value) {
      state.name = value;
    },

    setSymbol(state, value) {
      state.symbol = value;
    },

    setDecimals(state, value) {
      state.decimals = value;
    },

    setTotalSupply(state, value) {
      state.totalSupply = value;
    },

    setTotalSupplyFormat(state, value) {
      state.totalSupplyFormat = value;
    },

    setCollateral(state, value) {
      state.collateral = value;
    },

    setOwner(state, value) {
      state.owner = value;
    },

    setHost(state, value) {
      state.host = value;
    },

    setPrice(state, value) {
      state.price = value;
    },

    setMarketCap(state, value) {
      state.marketCap = value;
    },

    setMyBalance(state, value) {
      state.myBalance = value;
    },

    setCollateralBalance(state, value) {
      state.collateralBalance = value;
    },

    setCollateralSymbol(state, value) {
      state.collateralSymbol = value;
    },

    setCollateralDecimals(state, value) {
      state.collateralDecimals = value;
    },

    setSettingsInfo(state, value) {
      state.settings = value;
    },

    setFixsInfo(state, value) {
      state.fixs = value;
    },

    setFixsView(state, value) {
      state.fixsView = value;
    },

    setSettings(state, { value, i }) {
      state.settings[i] = value;
    },

    setFixs(state, { value, i }) {
      state.fixs[i] = value;
    },
  },

  actions: {
    setTokenInfo(context, info) {
      const { name, symbol, decimals, totalSupply, tokenAddress, owner, price, marketCap } = info;

      context.commit('setName', name);
      context.commit('setSymbol', symbol);
      context.commit('setDecimals', decimals);
      context.commit('setTotalSupply', totalSupply);
      context.commit('setTotalSupplyFormat', numberFormatFloat(totalSupply));
      context.commit('setCollateral', tokenAddress);
      context.commit('setOwner', owner);
      context.commit('setPrice', price);
      context.commit('setMarketCap', marketCap);
    },

    setTokenSettings(context, value) {
      const settings = {
        ...value[0],
        7: new Decimal(value[0][7])
          .dividedBy(Decimal.pow(10, context.state.decimals))
          .toString(),
        12: context.state.collateralDecimals
          ? new Decimal(value[0][12])
            .dividedBy(Decimal.pow(10, context.state.collateralDecimals))
            .toString()
          : value[0][12],
        15: value[0][15] / 86400,
        16: value[0][16] / 86400,
      };
      const fixs = { ...value[1] };
      const fixsView = { ...value[1] };

      context.commit('setSettingsInfo', settings);
      context.commit('setFixsInfo', fixs);
      context.commit('setFixsView', fixsView);
    },
  },
};
