import { Decimal } from 'decimal.js';
import { useToast } from 'vue-toastification';
import { delay } from '@/utils';
import ERC20 from '@/abis/ERC20.json';
import WrappedToken from '@/abis/WrappedToken.json';

Decimal.set({ toExpPos: 40 });

const toast = useToast();

export default {
  namespaced: true,

  state: {
    approveAmount: '115792089237316195423570985008687907853269984665640564039457584007913129639935',

    wbtcBalance: '',
    wbtcDecimals: '',

    wwbtcBalance: '',
    wwbtcDecimals: '',

    switched: false,
    wrapValue: '',

    loadingMainBtn: false,
    isShowModal: false,
    pending: false,

    allowance: '',
    activeStep: 1,

    loadingStepApproveBtn: false,
    loadingStepConfirmBtn: false,
    loadingUnWrapConfirmBtn: false,
  },

  getters: {
    getWrapValue(state) {
      const dec = state.switched
        ? state.wwbtcDecimals
        : state.wbtcDecimals;

      return state.wrapValue && dec
        ? new Decimal(state.wrapValue)
          .mul(Decimal.pow(10, dec))
          .toString()
        : state.wrapValue;
    },

    isApprove(state, getters) {
      return Number(state.allowance) > Number(getters.getWrapValue);
    },

    willReceive(state) {
      return state.wrapValue ? new Decimal(state.wrapValue).minus(
        new Decimal(state.wrapValue).mul(new Decimal(2).dividedBy(100)),
      ).toFixed() : 0;
    },
  },

  mutations: {
    setWbtcBalance(state, value) {
      state.wbtcBalance = value;
    },

    setWbtcDecimals(state, value) {
      state.wbtcDecimals = value;
    },

    setWwbtcBalance(state, value) {
      state.wwbtcBalance = value;
    },

    setWwbtcDecimals(state, value) {
      state.wwbtcDecimals = value;
    },

    setSwitched(state) {
      state.switched = !state.switched;
    },

    setWrapValue(state, value) {
      state.wrapValue = value;
    },

    setLoadingMainBtn(state, value) {
      state.loadingMainBtn = value;
    },

    showModal(state, value) {
      state.isShowModal = value;
    },

    setPending(state, value) {
      state.pending = value;
    },

    setAllowance(state, value) {
      state.allowance = value;
    },

    setActiveStep(state, value) {
      state.activeStep = value;
    },

    setLoadingStepApproveBtn(state, value) {
      state.loadingStepApproveBtn = value;
    },

    setLoadingStepConfirmBtn(state, value) {
      state.loadingStepConfirmBtn = value;
    },

    setLoadingUnWrapConfirmBtn(state, value) {
      state.loadingUnWrapConfirmBtn = value;
    },
  },

  actions: {
    async getWbtcBalance(context) {
      if (context.rootState.metamask.address) {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          ERC20,
          context.rootGetters['app/wBtcContract'].contract,
        );

        const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();
        const dec = await contract.methods.decimals().call();

        const formatBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toFixed();
        context.commit('setWbtcBalance', formatBalance);
        context.commit('setWbtcDecimals', dec);
      }
    },

    async getWwbtcBalance(context) {
      if (context.rootState.metamask.address) {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          ERC20,
          context.rootGetters['app/wrappedWbtcContract'].contract,
        );

        const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();
        const dec = await contract.methods.decimals().call();

        const formatBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toString();
        context.commit('setWwbtcBalance', formatBalance);
        context.commit('setWwbtcDecimals', dec);
      }
    },

    async checkAllowance(context) {
      try {
        context.commit('setLoadingMainBtn', true);
        context.commit('setActiveStep', 1);
        await context.dispatch('getAllowance');
        await delay(200);
        context.commit('setLoadingMainBtn', false);
        context.commit('showModal', true);
      } catch (e) {
        context.commit('setLoadingMainBtn', false);
        context.commit('showModal', false);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async getAllowance(context) {
      const web3 = await context.dispatch('app/web3Instance', null, { root: true });

      const contract = new web3.eth.Contract(
        ERC20,
        context.rootGetters['app/wBtcContract'].contract,
      );

      const allowance = await contract.methods.allowance(
        context.rootState.metamask.address,
        context.rootGetters['app/wrappedWbtcContract'].contract,
      ).call();

      context.commit('setAllowance', allowance);
    },

    async approveSpend(context) {
      try {
        context.commit('setLoadingStepApproveBtn', true);
        context.commit('setPending', true);

        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          ERC20,
          context.rootGetters['app/wBtcContract'].contract,
        );

        contract.methods.approve(
          context.rootGetters['app/wrappedWbtcContract'].contract,
          context.state.approveAmount,
        )
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.commit('setPending', false);
            context.commit('setLoadingStepApproveBtn', false);
            context.commit('setActiveStep', 2);
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setLoadingStepApproveBtn', false);
            context.commit('setPending', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async wrapWBTC(context) {
      try {
        context.commit('setLoadingStepConfirmBtn', true);
        context.commit('setPending', true);

        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          WrappedToken,
          context.rootGetters['app/wrappedWbtcContract'].contract,
        );

        contract.methods.wrap(
          context.rootState.metamask.address,
          context.getters.getWrapValue,
        )
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.dispatch('getWbtcBalance');
            context.dispatch('getWwbtcBalance');

            context.commit('setPending', false);
            context.commit('setLoadingStepConfirmBtn', false);
            context.commit('setActiveStep', 3);
            context.commit('showModal', false);

            toast.success('Success!', {
              timeout: 3000,
            });
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setLoadingStepConfirmBtn', false);
            context.commit('setPending', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async unWrapWBTC(context) {
      try {
        context.commit('setLoadingUnWrapConfirmBtn', true);
        context.commit('setPending', true);

        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          WrappedToken,
          context.rootGetters['app/wrappedWbtcContract'].contract,
        );

        contract.methods.unwrap(
          context.getters.getWrapValue,
        )
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.dispatch('getWbtcBalance');
            context.dispatch('getWwbtcBalance');

            context.commit('setPending', false);
            context.commit('setLoadingUnWrapConfirmBtn', false);
            context.commit('showModal', false);

            toast.success('Success!', {
              timeout: 3000,
            });
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setLoadingUnWrapConfirmBtn', false);
            context.commit('setPending', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },
  },
};
