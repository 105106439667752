import { useToast } from 'vue-toastification';
import { Decimal } from 'decimal.js';
import { numberFormatFloat, calculatePrice, supplyFormat } from '@/utils';
import NativePriceContractAbi from '@/abis/NativePriceContract.json';
import SettingsRetrieverAbi from '@/abis/SettingsRetriever.json';
import DerivativeNextAbi from '@/abis/DerivativeNext.json';
import ERC20 from '@/abis/ERC20.json';

Decimal.set({ toExpPos: 40 });

const toast = useToast();

export default {
  namespaced: true,

  state: {
    address: '',
    isShowModal: false,
    pending: false,

    wrappedWbtcBalance: 0,
    wrappedWbtcSymbol: '',
    wrappedWbtcDecimals: '',

    isVerified: null,
    isShowHostAndOwnerModal: false,
  },

  getters: {},

  mutations: {
    setAddress(state, value) {
      state.address = value;
    },

    showModal(state, value) {
      state.isShowModal = value;
    },

    setPending(state, value) {
      state.pending = value;
    },

    setWrappedWbtcBalance(state, value) {
      state.wrappedWbtcBalance = value;
    },

    setWrappedWbtcSymbol(state, value) {
      state.wrappedWbtcSymbol = value;
    },

    setWrappedWbtcDecimals(state, value) {
      state.wrappedWbtcDecimals = value;
    },

    setIsVerified(state, value) {
      state.isVerified = value;
    },

    setIsShowHostAndOwnerModal(state, value) {
      state.isShowHostAndOwnerModal = value;
    },
  },

  actions: {
    async getTokenInfo(context, address) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });

        const contract = new web3.eth.Contract(
          NativePriceContractAbi,
          context.rootGetters['app/nativePriceContract'].contract,
        );

        const tokenInfo = await contract.methods.getTokenInfoWithPrice(
          [address],
          context.rootGetters['app/derivativeRoot'].contract,
          // 10 ** Number(dec),
          '1000000000000000000',
        ).call();

        const supply = supplyFormat(tokenInfo[0].totalSupply, tokenInfo[0].decimals);
        const price = calculatePrice(tokenInfo[0].value, tokenInfo[0].decimals);
        const marketCap = new Decimal(price).mul(supply);

        await context.dispatch('contractsettings/setTokenInfo', {
          ...tokenInfo[0],
          totalSupply: supply,
          price: price.toFixed(),
          marketCap: marketCap.toFixed(),
        }, { root: true });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async getTokenSettings(context, address) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          SettingsRetrieverAbi,
          context.rootGetters['app/settingsRetrieverContract'].contract,
        );

        const settings = await contract.methods.retrieveSettings(address, 0, 17).call();
        await context.dispatch('contractsettings/setTokenSettings', settings, { root: true });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async myTokenBalance(context, address) {
      if (context.rootState.metamask.address) {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(ERC20, address);

        const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();
        const dec = await contract.methods.decimals().call();

        const myBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toFixed();

        context.commit('contractsettings/setMyBalance', numberFormatFloat(myBalance), { root: true });
      }
    },

    async myCollateralBalance(context, { collateral, contractAddress }) {
      const web3 = await context.dispatch('app/web3Instance', null, { root: true });
      const contract = new web3.eth.Contract(ERC20, collateral);
      const dec = await contract.methods.decimals().call();
      const symbol = await contract.methods.symbol().call();
      if (context.rootState.metamask.address) {
        const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();
        const myBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toFixed();

        context.commit('contractsettings/setCollateralBalance', numberFormatFloat(myBalance), { root: true });
      }
      context.commit('contractsettings/setCollateralSymbol', symbol, { root: true });
      context.commit('contractsettings/setCollateralDecimals', dec, { root: true });
      await context.dispatch('getTokenSettings', contractAddress);
    },

    async getWrappedWbtcBalance(context) {
      if (context.rootState.metamask.address) {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          ERC20,
          context.rootGetters['app/wrappedWbtcContract'].contract,
        );
        const dec = await contract.methods.decimals().call();
        const symbol = await contract.methods.symbol().call();
        const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();
        const formatBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toFixed();

        context.commit('setWrappedWbtcBalance', formatBalance);
        context.commit('setWrappedWbtcDecimals', dec);
        context.commit('setWrappedWbtcSymbol', symbol);
      }
    },

    async changeSettings(context, contractAddress) {
      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(DerivativeNextAbi, contractAddress);
        context.commit('setPending', true);

        const settings = Object.values(context.rootGetters['contractsettings/settings']);
        const fixs = Object.values(context.rootState.contractsettings.fixs);

        contract.methods.changeSettings(settings, fixs, 0, 17)
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.commit('setPending', false);
            context.commit('showModal', false);

            toast.success('Settings changed', {
              timeout: 3000,
            });
            context.dispatch('getTokenSettings', contractAddress);
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setPending', false);
            context.commit('showModal', false);

            toast.error(error.message, {
              timeout: 3000,
            });

            throw error;
          });
      } catch (e) {
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async isContractVerified(context, contractAddress) {
      try {
        const isVerified = await context.dispatch('verify/isVerified', contractAddress, { root: true });
        const isVerifiedValue = isVerified ? 'verified' : 'unverified';
        context.commit('setIsVerified', isVerifiedValue);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async getHost(context, contractAddress) {
      const web3 = await context.dispatch('app/web3Instance', null, { root: true });
      const contract = new web3.eth.Contract(DerivativeNextAbi, contractAddress);
      const host = await contract.methods._host().call();
      context.commit('contractsettings/setHost', host, { root: true });
    },

    async changeHost(context, { contractAddress, newHost }) {
      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(DerivativeNextAbi, contractAddress);

        context.commit('setPending', true);

        contract.methods.changeHost(newHost)
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.commit('setPending', false);
            context.commit('setIsShowHostAndOwnerModal', false);

            toast.success('Host changed', {
              timeout: 3000,
            });
            context.dispatch('getHost', contractAddress);
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setPending', false);
            context.commit('setIsShowHostAndOwnerModal', false);

            toast.error(error.message, {
              timeout: 3000,
            });

            throw error;
          });
      } catch (e) {
        console.error(e);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async getOwner(context, contractAddress) {
      const web3 = await context.dispatch('app/web3Instance', null, { root: true });
      const contract = new web3.eth.Contract(DerivativeNextAbi, contractAddress);
      const owner = await contract.methods.owner().call();
      context.commit('contractsettings/setOwner', owner, { root: true });
    },

    async changeOwner(context, { contractAddress, newOwner }) {
      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(DerivativeNextAbi, contractAddress);

        context.commit('setPending', true);

        contract.methods.transferOwnership(newOwner)
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.commit('setPending', false);
            context.commit('setIsShowHostAndOwnerModal', false);

            toast.success('Owner changed', {
              timeout: 3000,
            });
            context.dispatch('getOwner', contractAddress);
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setPending', false);
            context.commit('setIsShowHostAndOwnerModal', false);

            toast.error(error.message, {
              timeout: 3000,
            });

            throw error;
          });
      } catch (e) {
        console.error(e);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },
  },
};
