const clickOutside = {
  beforeMount(el, binding) {
    binding.event = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        if (binding.value instanceof Function) {
          binding.value(event);
        }
      }
    };
    document.body.addEventListener('click', binding.event);
  },
  unmounted(el, binding) {
    document.body.removeEventListener('click', binding.event);
  },
};

export default clickOutside;
