<template>
  <div class="wrapper py-10 flex flex-col gap-4">

    <skeleton-token-info v-if="tokenInfoLoading" />
    <token-info v-if="!tokenInfoLoading" />

    <skeleton-actions-settings v-if="actionsSettingsLoading" />
    <actions-settings v-if="!actionsSettingsLoading" />

    <ui-modal v-model="show" :mask-closable="false">
      <change-settings-modal-content />
    </ui-modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import TokenInfo from '@/components/contract/TokenInfo.vue';
import ActionsSettings from '@/components/contract/ActionsSettings.vue';
import ChangeSettingsModalContent from '@/components/contract/ChangeSettingsModalContent.vue';
import UiModal from '@/components/ui/UiModal.vue';
import SkeletonTokenInfo from '@/components/skeleton/SkeletonTokenInfo.vue';
import SkeletonActionsSettings from '@/components/skeleton/SkeletonActionsSettings.vue';

export default {
  components: {
    TokenInfo,
    ActionsSettings,
    ChangeSettingsModalContent,
    UiModal,
    SkeletonTokenInfo,
    SkeletonActionsSettings,
  },

  data() {
    return {
      tokenInfoLoading: true,
      actionsSettingsLoading: true,
    };
  },

  computed: {
    ...mapState('metamask', {
      address: 'address',
    }),
    ...mapState('contract', {
      isShowModal: 'isShowModal',
    }),
    ...mapState('contractsettings', {
      collateral: 'collateral',
    }),

    contractAddress() {
      return this.$route.params.contract_address;
    },

    show: {
      get() {
        return this.isShowModal;
      },

      set(value) {
        this.showModal(value);
      },
    },
  },

  watch: {
    collateral() {
      this.getMyData();
    },
  },

  async created() {
    this.setContractAddress();
    await this.getTokenInfoData();
    await this.getTokenSettingsData();
    await this.getMyData();
    await this.getHost(this.contractAddress);
  },

  unmounted() {
    this.setIsVerified(null);
  },

  methods: {
    ...mapActions('contract', [
      'getTokenInfo',
      'getTokenSettings',
      'myTokenBalance',
      'myCollateralBalance',
      'getWrappedWbtcBalance',
      'getHost',
    ]),
    ...mapMutations('contract', [
      'setAddress',
      'showModal',
      'setPending',
      'setIsVerified',
    ]),
    ...mapActions('contract', ['isContractVerified']),

    setContractAddress() {
      this.setAddress(this.contractAddress);
    },

    async getTokenInfoData() {
      try {
        await this.getTokenInfo(this.contractAddress);
        await this.isContractVerified(this.contractAddress);
        this.tokenInfoLoading = false;
      } catch (e) {
        this.tokenInfoLoading = false;
      }
    },

    async getTokenSettingsData() {
      try {
        await this.getTokenSettings(this.contractAddress);
        this.actionsSettingsLoading = false;
      } catch (e) {
        this.actionsSettingsLoading = false;
      }
    },

    async getMyData() {
      await this.myTokenBalance(this.contractAddress);
      await this.myCollateralBalance({
        collateral: this.collateral,
        contractAddress: this.contractAddress,
      });
      await this.getWrappedWbtcBalance();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  @apply max-w-848px mx-auto;

  @include laptop {
    @apply max-w-full;
  }
}
</style>
