<template>
  <div>
    <div class="my-4">
      <ui-pending-preloader v-if="pending" />
      <div
        v-else
        class="w-full h-86px flex items-center justify-center font-600"
      >
        {{ $t('Liquidity Added') }}
      </div>
    </div>
    <steps-wrapper />
    <div class="mt-6">
      <ui-button-loading
        :loading="loadingSetSettingsBtn"
        class="button button-primary px-3 py-2 w-full"
        @click="setSettingsHandler"
      >
        {{ $t('Set Settings') }}
      </ui-button-loading>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import StepsWrapper from '@/components/create/StepsWrapper.vue';

export default {
  components: {
    UiPendingPreloader,
    UiButtonLoading,
    StepsWrapper,
  },

  computed: {
    ...mapState('create', {
      pending: 'pending',
      loadingSetSettingsBtn: 'loadingSetSettingsBtn',
    }),
  },

  methods: {
    ...mapActions('create', ['setSettings']),

    setSettingsHandler() {
      this.setSettings();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
