<template>
  <div class="flex justify-center mt-20">
    <div class="flex flex-col gap-4">
      <div class="text-center font-600 text-gray-600">
        <div class="text-4xl mb-2">404</div>
        <div class="text-xl">
          {{ $t('Page not found') }}
        </div>
      </div>
      <div class="flex justify-center">
        <router-link
          to="/"
          class="button button-primary px-4 py-2 flex"
        >
          {{ $t('Back') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>
