<template>
  <div class="select-wrapper">
    <select
      class="select"
      :id="id"
      :value="modelValue"
      @input="selectHandler"
    >
      <option
        v-for="(option, i) in options"
        :key="i"
        :value="option.value"
      >
        {{ option.title }}
      </option>
    </select>
    <feather-icon
      type="chevron-down"
      size="16"
      class="angle-down"
    />
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    id: {
      type: [String, Number],
      required: false,
    },
    modelValue: {
      type: [String, Number],
    },
  },

  emits: ['update:modelValue', 'change'],

  methods: {
    selectHandler(e) {
      const value = e.target.value;
      this.$emit('update:modelValue', value);
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  @apply relative;
}
.select {
  @apply w-full text-sm leading-relaxed border rounded h-full px-2 pr-8 outline-none appearance-none;
  @apply cursor-pointer;
}

.angle-down {
  @apply absolute pointer-events-none top-1/2 right-0 z-1 px-2;
  transform: translateY(-50%);
}
</style>
