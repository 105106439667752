<template>
  <div class="py-10">
    <div
      class="flex items-center gap-4 mb-10"
    >
      <div class="page-title">
        Derivative Wrapped Contracts
      </div>
      <div class="flex items-center gap-2">
        <feather-icon
          type="alert-triangle"
          size="30"
          class="text-red-500"
        />
        <span class="text-red-500 font-600 text-lg">
          {{ $t('Unverified') }}!
        </span>
      </div>
    </div>

    <unverified-token-list />
  </div>
</template>

<script>
import UnverifiedTokenList from '@/components/UnverifiedTokenList.vue';

export default {
  components: {
    UnverifiedTokenList,
  },
};
</script>
