<template>
  <div class="card">
    <div class="flex flex-col gap-2 mb-4">
      <div class="mb-3">
        <ui-skeleton-box height="40px" width="100%" />
      </div>
      <div>
        <ui-skeleton-box height="28px" width="100%" />
      </div>
      <div v-if="address && isConnected" class="font-600 text-lg">
        <ui-skeleton-box height="28px" width="100%" />
      </div>
      <div v-if="address && isConnected" class="font-600 text-lg">
        <ui-skeleton-box height="28px" width="100%" />
      </div>
      <div v-if="address && isConnected" class="font-600 text-lg">
        <ui-skeleton-box height="28px" width="100%" />
      </div>
    </div>
    <div class="text-600 text-lg text-gray-600 mb-2">
      <ui-skeleton-box height="28px" width="100%" />
    </div>
    <div>
      <ui-skeleton-box height="224px" width="100%" />
    </div>
    <div v-if="address && isConnected" class="mt-6">
      <ui-skeleton-box height="40px" width="100%" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';

export default {
  components: {
    UiSkeletonBox,
  },

  computed: {
    ...mapState('metamask', {
      isConnected: 'isConnected',
      address: 'address',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
