<template>
  <div class="py-10">
    <h1 class="page-title mb-10">
      Derivative Wrapped Contracts
    </h1>
    <token-list />
  </div>
</template>

<script>
import TokenList from '@/components/TokenList.vue';

export default {
  components: {
    TokenList,
  },
};
</script>
