import { Decimal } from 'decimal.js';
import {
  paginate,
  supplyFormat,
  calculatePrice,
  currencyPriceFormatter,
  sliceIntoChunks,
} from '@/utils';
import DerivativeNextFabAbi from '@/abis/DerivativeNextFab.json';
import NativePriceContractAbi from '@/abis/NativePriceContract.json';
import VerifiedAddressesAbi from '@/abis/VerifiedAddresses.json';
import ERC20 from '@/abis/ERC20.json';

Decimal.set({ toExpPos: 40 });

export default {
  namespaced: true,

  state: {
    verifiedList: [],
    verifiedListPaginate: [],
    verifiedListTotal: 0,
    verifiedListLoaded: false,

    unverifiedList: [],
    unverifiedListPaginate: [],
    unverifiedListTotal: 0,
    unverifiedListLoaded: false,

    portfolioList: [],
    portfolioListPaginate: [],
    portfolioListTotal: 0,
    portfolioListLoaded: false,

    listChunkSize: 10,
  },

  getters: {
    verifiedList(state, getters, rootState, rootGetters) {
      const wbtcPrice = rootGetters['app/wbtcPrice'];

      return [...state.verifiedList].map((item) => {
        const priceUsd = new Decimal(item.price).mul(wbtcPrice);
        const marketCapUsd = new Decimal(item.marketCap).mul(wbtcPrice);

        return {
          ...item,
          price: currencyPriceFormatter(priceUsd),
          marketCap: currencyPriceFormatter(marketCapUsd),
        };
      });
    },

    unverifiedList(state, getters, rootState, rootGetters) {
      const wbtcPrice = rootGetters['app/wbtcPrice'];

      return [...state.unverifiedList].map((item) => {
        const priceUsd = new Decimal(item.price).mul(wbtcPrice);
        const marketCapUsd = new Decimal(item.marketCap).mul(wbtcPrice);

        return {
          ...item,
          price: currencyPriceFormatter(priceUsd),
          marketCap: currencyPriceFormatter(marketCapUsd),
        };
      });
    },

    portfolioList(state, getters, rootState, rootGetters) {
      const wbtcPrice = rootGetters['app/wbtcPrice'];

      return [...state.portfolioList].map((item) => {
        const priceUsd = new Decimal(item.price).mul(wbtcPrice);
        const balanceUsd = new Decimal(item.balance).mul(priceUsd);

        return {
          ...item,
          price: currencyPriceFormatter(priceUsd),
          balance: item.balance,
          balanceUsd: currencyPriceFormatter(balanceUsd),
        };
      });
    },
  },

  mutations: {
    setVerifiedList(state, value) {
      state.verifiedList = value;
    },

    setVerifiedListPaginate(state, value) {
      state.verifiedListPaginate = value;
    },

    setVerifiedListTotal(state, value) {
      state.verifiedListTotal = value;
    },

    setVerifiedListLoaded(state, value) {
      state.verifiedListLoaded = value;
    },

    setUnverifiedList(state, value) {
      state.unverifiedList = value;
    },

    setUnverifiedListPaginate(state, value) {
      state.unverifiedListPaginate = value;
    },

    setUnverifiedListTotal(state, value) {
      state.unverifiedListTotal = value;
    },

    setUnverifiedListLoaded(state, value) {
      state.unverifiedListLoaded = value;
    },

    setPortfolioList(state, value) {
      state.portfolioList = value;
    },

    setPortfolioListPaginate(state, value) {
      state.portfolioListPaginate = value;
    },

    setPortfolioListTotal(state, value) {
      state.portfolioListTotal = value;
    },

    setPortfolioListLoaded(state, value) {
      state.portfolioListLoaded = value;
    },
  },

  actions: {
    async loadVerifiedList(context, addressOwner) {
      try {
        const web3 = await context.dispatch('app/web3Instance', true, { root: true });

        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });

        const contract = new web3.eth.Contract(
          VerifiedAddressesAbi,
          context.rootGetters['app/verifyContract'].contract,
        );

        const options = {
          fromBlock: context.rootGetters['app/verifyContract'].block,
          toBlock: 'latest',
        };

        const response = await contract.getPastEvents('ContractVerified', options);

        const result = response.map((item) => (
          ({ token }) => (
            { token }
          )
        )(item.returnValues));

        const addressArray = result.map((item) => item.token);
        const chunksArray = sliceIntoChunks(addressArray, context.state.listChunkSize);

        const tokensInfo = (await Promise.all(chunksArray.map(async (chunk) => {
          return context.dispatch('getTokensInfo', chunk);
        }))).flat();

        const addCurrentArray = tokensInfo.map((item, i) => ({
          current: addressArray[i],
          ...item,
        }));

        const ownerList = addCurrentArray.filter((item) => {
          return item.owner.toLowerCase() === addressOwner.toLowerCase();
        });

        context.commit('setVerifiedListTotal', ownerList.length);

        const tokensInfoObjects = ownerList.map((item) => {
          const supply = supplyFormat(item.totalSupply, item.decimals);
          const price = calculatePrice(item.value, item.decimals);
          const marketCap = new Decimal(price).mul(supply);

          return {
            current: item.current,
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            totalSupply: supply,
            tokenAddress: item.tokenAddress,
            owner: item.owner,
            price: price.toFixed(),
            marketCap: marketCap.toFixed(),
          };
        });

        context.commit('setVerifiedList', tokensInfoObjects);
        context.commit('setVerifiedListLoaded', true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    getVerifiedListPaginate(context, { page, perPage }) {
      const paginateArray = paginate(context.getters.verifiedList, page, perPage);
      context.commit('setVerifiedListPaginate', paginateArray);
    },

    async loadUnverifiedList(context, addressOwner) {
      try {
        const web3 = await context.dispatch('app/web3Instance', true, { root: true });

        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });

        const contract = new web3.eth.Contract(
          DerivativeNextFabAbi,
          context.rootGetters['app/factoryContract'].contract,
        );

        const options = {
          fromBlock: context.rootGetters['app/factoryContract'].block,
          toBlock: 'latest',
        };

        const response = await contract.getPastEvents('CreateDerivativeNext', options);

        const result = response.map((item) => (
          ({ current }) => {
            return { current };
          }
        )(item.returnValues));

        const addressArray = result.map((item) => item.current);
        const chunksArray = sliceIntoChunks(addressArray, context.state.listChunkSize);

        const tokensInfo = (await Promise.all(chunksArray.map(async (chunk) => {
          return context.dispatch('getTokensInfo', chunk);
        }))).flat();

        const addCurrentArray = tokensInfo.map((item, i) => ({
          current: addressArray[i],
          ...item,
        }));

        const ownerList = addCurrentArray.filter((item) => {
          return item.owner.toLowerCase() === addressOwner.toLowerCase();
        });

        const isVerifiedArray = await Promise.all(ownerList.map(async (item) => {
          const isVerified = await context.dispatch('verify/isVerified', item.current, { root: true });
          return { ...item, isVerified };
        }));

        const unverified = isVerifiedArray.filter((item) => !item.isVerified);

        context.commit('setUnverifiedListTotal', unverified.length);

        const tokensInfoObjects = unverified.map((item) => {
          const supply = supplyFormat(item.totalSupply, item.decimals);
          const price = calculatePrice(item.value, item.decimals);
          const marketCap = new Decimal(price).mul(supply);

          return {
            current: item.current,
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            totalSupply: supply,
            tokenAddress: item.tokenAddress,
            owner: item.owner,
            price: price.toFixed(),
            marketCap: marketCap.toFixed(),
          };
        });

        context.commit('setUnverifiedList', tokensInfoObjects);
        context.commit('setUnverifiedListLoaded', true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    getUnverifiedListPaginate(context, { page, perPage }) {
      const paginateArray = paginate(context.getters.unverifiedList, page, perPage);
      context.commit('setUnverifiedListPaginate', paginateArray);
    },

    async loadPortfolio(context, profileAddress) {
      try {
        const web3 = await context.dispatch('app/web3Instance', true, { root: true });

        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });

        const contract = new web3.eth.Contract(
          DerivativeNextFabAbi,
          context.rootGetters['app/factoryContract'].contract,
        );

        const options = {
          fromBlock: context.rootGetters['app/factoryContract'].block,
          toBlock: 'latest',
        };

        const response = await contract.getPastEvents('CreateDerivativeNext', options);

        const result = response.map((item) => (
          ({ current }) => {
            return { current };
          }
        )(item.returnValues));

        const tokens = await Promise.all(result.map(async (item) => {
          const balance = await context.dispatch('getPortfolioTokenBalance', {
            profileAddress, contactAddress: item.current,
          });
          return { contract: item.current, balance };
        }));
        const tokensWithBalance = tokens.filter((item) => Number(item.balance) > 0);
        context.commit('setPortfolioListTotal', tokensWithBalance.length);

        const addressArray = tokensWithBalance.map((item) => item.contract);
        const chunksArray = sliceIntoChunks(addressArray, context.state.listChunkSize);

        const tokensInfo = (await Promise.all(chunksArray.map(async (chunk) => {
          return context.dispatch('getTokensInfo', chunk);
        }))).flat();

        const tokensInfoObjects = tokensInfo.map((item, i) => {
          const supply = supplyFormat(item.totalSupply, item.decimals);
          const price = calculatePrice(item.value, item.decimals);

          const balanceDec = new Decimal(tokensWithBalance[i].balance)
            .dividedBy(Decimal.pow(10, item.decimals));

          return {
            current: tokensWithBalance[i].contract,
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            totalSupply: supply,
            tokenAddress: item.tokenAddress,
            owner: item.owner,
            price: price.toFixed(),
            balance: balanceDec.toFixed(),
          };
        });

        context.commit('setPortfolioList', tokensInfoObjects);
        context.commit('setPortfolioListLoaded', true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    getPortfolioListPaginate(context, { page, perPage }) {
      const paginateArray = paginate(context.getters.portfolioList, page, perPage);
      context.commit('setPortfolioListPaginate', paginateArray);
    },

    async getPortfolioTokenBalance(context, { profileAddress, contactAddress }) {
      const web3 = await context.dispatch('app/web3Instance', null, { root: true });
      const contract = new web3.eth.Contract(ERC20, contactAddress);

      const balance = await contract.methods.balanceOf(profileAddress).call();
      return balance;
    },

    async getTokensInfo(context, addressArray) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          NativePriceContractAbi,
          context.rootGetters['app/nativePriceContract'].contract,
        );
        return await contract.methods.getTokenInfoWithPrice(
          [...addressArray],
          context.rootGetters['app/derivativeRoot'].contract,
          '1000000000000000000',
        ).call();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async loadVerifiedListOld(context, { page, perPage, filter = null }) {
      try {
        const web3 = await context.dispatch('app/web3Instance', true, { root: true });

        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });
        const contract = new web3.eth.Contract(
          VerifiedAddressesAbi,
          context.rootGetters['app/verifyContract'].contract,
        );

        const options = {
          fromBlock: context.rootGetters['app/verifyContract'].block,
          toBlock: 'latest',
        };

        if (filter) {
          options.filter = filter;
        }

        const response = await contract.getPastEvents('ContractVerified', options);

        const result = response.map((item) => (
          ({ token }) => (
            { token }
          )
        )(item.returnValues));

        context.commit('setVerifiedListTotal', result.length);

        const paginatable = paginate(result, page, perPage);
        const addressArray = paginatable.map((item) => item.token);

        const tokensInfo = await context.dispatch('getTokensInfo', addressArray);

        const tokensInfoObjects = tokensInfo.map((item, i) => {
          const supply = supplyFormat(item.totalSupply, item.decimals);
          const price = calculatePrice(item.value, item.decimals);
          const marketCap = new Decimal(price).mul(supply);

          return {
            current: addressArray[i],
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            totalSupply: supply,
            tokenAddress: item.tokenAddress,
            owner: item.owner,
            price: price.toFixed(),
            marketCap: marketCap.toFixed(),
          };
        });

        context.commit('setVerifiedList', tokensInfoObjects);
        context.commit('setVerifiedListLoaded', true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async loadUnverifiedListOld(context, { page, perPage, filter = null }) {
      try {
        const web3 = await context.dispatch('app/web3Instance', true, { root: true });

        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });

        const contract = new web3.eth.Contract(
          DerivativeNextFabAbi,
          context.rootGetters['app/factoryContract'].contract,
        );

        const options = {
          fromBlock: context.rootGetters['app/factoryContract'].block,
          toBlock: 'latest',
        };

        if (filter) {
          options.filter = filter;
        }

        const response = await contract.getPastEvents('CreateDerivativeNext', options);

        const result = await Promise.all(response.map((item) => (
          async ({ current }) => {
            const isVerified = await context.dispatch('verify/isVerified', current, { root: true });
            return { current, isVerified };
          }
        )(item.returnValues)));

        const unverified = result.filter((item) => !item.isVerified);

        context.commit('setUnverifiedListTotal', unverified.length);

        const paginatable = paginate(unverified, page, perPage);
        const addressArray = paginatable.map((item) => item.current);

        const tokensInfo = await context.dispatch('getTokensInfo', addressArray);

        const tokensInfoObjects = tokensInfo.map((item, i) => {
          const supply = supplyFormat(item.totalSupply, item.decimals);
          const price = calculatePrice(item.value, item.decimals);
          const marketCap = new Decimal(price).mul(supply);

          return {
            current: addressArray[i],
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            totalSupply: supply,
            tokenAddress: item.tokenAddress,
            owner: item.owner,
            price: price.toFixed(),
            marketCap: marketCap.toFixed(),
          };
        });

        context.commit('setUnverifiedList', tokensInfoObjects);
        context.commit('setUnverifiedListLoaded', true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
