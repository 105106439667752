<template>
  <skeleton-list v-if="skeletonLoading" border-top />
  <div v-else>
    <ui-table
      v-if="derivativeList.length"
      :columns="tableColumns"
      :data="derivativeList"
      paginationable
      border-top
      :loading="tableLoading"
      :per-page-default="perPageDefault"
      :per-page-options="perPageOptions"
      :total-entries="derivativeListTotal"
      @per-page-select-changed="perPageSelectHandler"
      @page-changed="pageChangeHandler"
    >
      <template v-slot:cell-position="{row: item}">
        <span class="text-gray-500">
          {{ item.index }}
        </span>
      </template>
      <template v-slot:cell-current="{row: item}">
        <div class="flex items-center gap-2">
          <router-link
            class="bg-primary-100 py-1 px-2 rounded-md hover:bg-primary-200"
            :to="{ name: 'contract', params: { contract_address: item.current } }"
          >
            {{ `${item.current.slice(0, 7)}...${item.current.slice(-5)}` }}
          </router-link>
          <ui-copy-to-clipboard
            :text-to-copy="item.current"
          />
        </div>
      </template>
      <template v-slot:cell-name="{row: item}">
        <router-link
          class="text-primary-500 hover:text-primary-600 inline-block"
          :to="{ name: 'contract', params: { contract_address: item.current } }"
        >
          <div class="flex items-center gap-2">
            <span>{{ item.name }}</span>
            <span class="text-gray-600 text-sm">{{ item.symbol }}</span>
          </div>
        </router-link>
      </template>
    </ui-table>
    <div v-else class="text-gray-600 text-center">
      {{ $t('There are no derivative tokens. You can be the first to create a token!') }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import UiTable from '@/components/ui/UiTable.vue';
import SkeletonList from '@/components/skeleton/SkeletonList.vue';

export default {
  components: {
    UiTable,
    UiCopyToClipboard,
    SkeletonList,
  },

  data() {
    return {
      tableColumns: [
        {
          label: '#',
          field: 'position',
          textAlign: 'text-left',
        },
        {
          label: 'Contract',
          field: 'current',
          textAlign: 'text-left',
        },
        {
          label: 'Name',
          field: 'name',
          textAlign: 'text-center',
        },
        {
          label: 'Price',
          field: 'price',
          textAlign: 'text-center',
        },
        {
          label: 'Market Cap',
          field: 'marketCap',
          textAlign: 'text-right',
        },
      ],
      tableLoading: false,
      currentPage: '1',
      perPageDefault: '10',
      skeletonLoading: false,
      perPageOptions: [
        {
          title: '10',
          value: '10',
        },
        {
          title: '25',
          value: '25',
        },
      ],
    };
  },

  computed: {
    ...mapState('list', {
      derivativeList: 'derivativeListPaginate',
      derivativeListTotal: 'derivativeListTotal',
      derivativeListLoaded: 'derivativeListLoaded',
    }),
  },

  created() {
    if (!this.derivativeListLoaded) {
      this.skeletonLoading = true;
    }
    this.loadDerivativesData();
  },

  methods: {
    ...mapActions('list', ['loadDerivativeList', 'getDerivativeListPaginate']),

    async loadDerivativesData() {
      try {
        await this.loadDerivativeList();
        await this.getDerivativeListPaginate({
          page: this.currentPage,
          perPage: this.perPageDefault,
        });
        this.tableLoading = false;
        this.skeletonLoading = false;
      } catch (e) {
        this.tableLoading = false;
        this.skeletonLoading = false;
      }
    },

    perPageSelectHandler(page, perPage) {
      this.getDerivativeListPaginate({ page, perPage });
    },

    pageChangeHandler(page, perPage) {
      this.getDerivativeListPaginate({ page, perPage });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
