<template>
  <div class="card">
    <div class="flex flex-col gap-2 mb-4">
      <div class="flex justify-between items-center flex-wrap gap-4 mb-3">
        <div class="flex items-center flex-wrap gap-3">
          <div class="text-xl">
            {{ `${name} (${symbol})` }}
          </div>
          <div
            v-if="isVerified === 'verified'"
            class="flex items-center gap-2"
          >
            <img
              src="@/assets/img/verified.svg"
              alt="verified"
              class="w-30px"
            >
            <span class="text-lg font-600 text-emerald-500">
              {{ $t('Verified') }}
            </span>
          </div>
          <div
            v-if="isVerified === 'unverified'"
            class="flex items-center gap-2"
          >
            <feather-icon
              type="alert-triangle"
              size="30"
              class="text-red-500"
            />
            <span class="text-red-500 font-600 text-lg">
              {{ $t('Unverified Contract') }}!
            </span>
          </div>
        </div>
        <div v-if="isOwner && isVerified === 'unverified'" class="w-140px">
          <ui-button-loading
            :loading="verifyBtnLoading"
            class="button button-primary px-3 py-8px w-full"
            @click="verifyHandler"
          >
            {{ $t('Verify') }}
          </ui-button-loading>
        </div>
      </div>
      <div class="flex gap-2 text-lg font-600">
        <span>{{ $t('Contract Address: ') }}</span>
        <div class="flex items-center gap-2">
          <span>{{ shortAddress(contractAddress, 8, -6) }}</span>
          <ui-copy-to-clipboard :text-to-copy="contractAddress" />
        </div>
      </div>
      <div v-if="address && isConnected" class="font-600 text-lg">
        <span>{{ $t('Token Balance: ') }}</span>
        <span>{{ `${myBalance} ${symbol}` }}</span>
      </div>
      <div v-if="address && isConnected" class="font-600 text-lg">
        <span>{{ $t('Collateral Balance: ') }}</span>
        <span>{{ `${collateralBalance} ${collateralSymbol}` }}</span>
      </div>
      <div v-if="address && isConnected" class="font-600 text-lg">
        <span>{{ $t('wWBTC Balance: ') }}</span>
        <span>{{ `${wrappedWbtcBalance} ${wrappedWbtcSymbol}` }}</span>
      </div>
    </div>
    <div class="text-600 text-lg text-gray-600 mb-2">
      Token Information
    </div>
    <div class="h-scroll">
      <table class="w-full">
        <tbody>
          <tr class="text-left">
            <th class="pr-3 py-1">Price:</th>
            <td>
              {{ price }}
            </td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1">Market Cap:</th>
            <td>
              {{ marketCap }}
            </td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1">Total Supply:</th>
            <td>{{ totalSupplyFormat }} {{ symbol }}</td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1">Decimals:</th>
            <td>{{ decimals }}</td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1">Collateral token:</th>
            <td>
              <div class="flex items-center gap-2">
                <span>{{ shortAddress(collateral, 8, -6) }}</span>
                <ui-copy-to-clipboard :text-to-copy="collateral" />
              </div>
            </td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1">Owner:</th>
            <td>
              <div class="flex items-center gap-2">
                <span>{{ shortAddress(owner, 8, -6) }}</span>
                <ui-copy-to-clipboard :text-to-copy="owner" />
                <button
                  v-if="isOwner"
                  class="flex items-center ml-3 cursor-pointer hover:text-primary-500"
                  @click="changeOwnerHandler"
                >
                  <feather-icon type="edit" size="18" />
                </button>
              </div>
            </td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1">Host:</th>
            <td>
              <div class="flex items-center gap-2">
                <span>{{ shortAddress(host, 8, -6) }}</span>
                <ui-copy-to-clipboard :text-to-copy="host" />
                <button
                  v-if="isOwner"
                  class="flex items-center ml-3 cursor-pointer hover:text-primary-500"
                  @click="changeHostHandler"
                >
                  <feather-icon type="edit" size="18" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="address && isConnected" class="buttons-wrapper mt-6">
      <deposit-button class="deposit-btn" />
      <withdrawal-button class="withdrawal-btn" />
    </div>

    <ui-modal v-model="showVerifyModal">
      <verify-modal-content
        :contract-address="contractAddress"
      />
    </ui-modal>
    <ui-modal v-model="showHostAndOwnerModal">
      <change-owner-modal-content v-if="modalType === 'owner'" />
      <change-host-modal-content v-if="modalType === 'host'" />
    </ui-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import DepositButton from '@/components/contract/DepositButton.vue';
import WithdrawalButton from '@/components/contract/WithdrawalButton.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import VerifyModalContent from '@/components/verify/VerifyModalContent.vue';
import ChangeOwnerModalContent from '@/components/contract/ChangeOwnerModalContent.vue';
import ChangeHostModalContent from '@/components/contract/ChangeHostModalContent.vue';
import { shortAddress } from '@/utils';

export default {
  components: {
    UiCopyToClipboard,
    DepositButton,
    WithdrawalButton,
    UiButtonLoading,
    UiModal,
    VerifyModalContent,
    ChangeOwnerModalContent,
    ChangeHostModalContent,
  },

  data() {
    return {
      modalType: '',
    };
  },

  computed: {
    ...mapState('contractsettings', {
      name: 'name',
      symbol: 'symbol',
      decimals: 'decimals',
      totalSupply: 'totalSupply',
      totalSupplyFormat: 'totalSupplyFormat',
      collateral: 'collateral',
      owner: 'owner',
      host: 'host',
      myBalance: 'myBalance',
      collateralBalance: 'collateralBalance',
      collateralSymbol: 'collateralSymbol',
    }),
    ...mapGetters('contractsettings', {
      price: 'price',
      marketCap: 'marketCap',
    }),
    ...mapState('contract', {
      wrappedWbtcBalance: 'wrappedWbtcBalance',
      wrappedWbtcSymbol: 'wrappedWbtcSymbol',
      isVerified: 'isVerified',
      isShowHostAndOwnerModal: 'isShowHostAndOwnerModal',
    }),
    ...mapState('metamask', {
      isConnected: 'isConnected',
      address: 'address',
    }),
    ...mapState('verify', {
      isShowModalVerify: 'isShowModal',
      verifyBtnLoading: 'verifyBtnLoading',
    }),

    contractAddress() {
      return this.$route.params.contract_address;
    },

    isOwner() {
      return !this.address
        ? false
        : this.address.toLowerCase() === this.owner.toLowerCase();
    },

    showVerifyModal: {
      get() {
        return this.isShowModalVerify;
      },
      set(value) {
        this.showModalVerify(value);
      },
    },

    showHostAndOwnerModal: {
      get() {
        return this.isShowHostAndOwnerModal;
      },
      set(value) {
        this.setIsShowHostAndOwnerModal(value);
      },
    },
  },

  methods: {
    ...mapMutations('verify', {
      showModalVerify: 'showModal',
    }),
    ...mapMutations('contract', ['setIsShowHostAndOwnerModal']),
    ...mapActions('verify', ['getVerificationCost']),

    shortAddress,

    verifyHandler() {
      this.getVerificationCost();
    },

    changeOwnerHandler() {
      this.modalType = 'owner';
      this.showHostAndOwnerModal = true;
    },

    changeHostHandler() {
      this.modalType = 'host';
      this.showHostAndOwnerModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrapper {
  @apply grid grid-cols-[140px,140px] justify-between;
  grid-template-areas: "withdrawal deposit";

  @include phone-lg {
    @apply grid-cols-[1fr] gap-4;
    grid-template-areas: "deposit" "withdrawal";
  }
}
.deposit-btn {
  grid-area: deposit;
}
.withdrawal-btn {
  grid-area: withdrawal;
}
</style>
