<template>
  <div>
    <div class="card-settings-title">
      <div class="flex items-center gap-2">
        <span>{{ $t('Lock time') }}</span>
        <span class="font-600 text-gray-600">
          ({{ $t('after this time, withdrawal fee is 0') }})
        </span>
      </div>
    </div>
    <div class="card-settings-body flex flex-col gap-6">
      <div class="grid grid-cols-[1fr,84px] gap-4">
        <div class="form-group">
          <div class="flex items-center gap-2">
            <label
              for="locktimeWithdrawal"
              class="self-start flex items-center gap-2"
            >
              <span>{{ $t('Withdrawal without fees after') }}</span>
              <span class="text-gray-600">({{ $t('Days') }})</span>
            </label>
            <ui-tooltip
              class="!flex"
              :text="$t('tooltip_lock_time')"
            >
              <feather-icon
                class="opacity-70 hover:opacity-100"
                type="help-circle"
                size="18"
              />
            </ui-tooltip>
          </div>
          <input
            required
            min="0"
            step="1"
            v-model="settings15"
            id="locktimeWithdrawal"
            class="input input-blue w-full"
            type="number"
          />
        </div>
        <div class="form-group justify-self-end">
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['15']"
              @switched="setFixsHandler(15)"
            />
            <span class="inline-block w-36px">
              {{ fixs['15'] }}
            </span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-[1fr,84px] gap-4">
        <div class="form-group">
          <div class="flex items-center gap-2">
            <label
              for="locktimeLost"
              class="self-start flex items-center gap-2"
            >
              <span>{{ $t('Token is considered lost after') }}</span>
              <span class="text-gray-600">({{ $t('Days') }})</span>
            </label>
            <ui-tooltip
              class="!flex"
              :text="$t('tooltip_lost_after_time')"
            >
              <feather-icon
                class="opacity-70 hover:opacity-100"
                type="help-circle"
                size="18"
              />
            </ui-tooltip>
          </div>
          <input
            required
            min="0"
            step="1"
            v-model="settings16"
            id="locktimeLost"
            class="input input-blue w-full"
            type="number"
          />
        </div>
        <div class="form-group justify-self-end">
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['16']"
              @switched="setFixsHandler(16)"
            />
            <span class="inline-block w-36px">
              {{ fixs['16'] }}
            </span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-[1fr,84px] gap-4">
        <div class="form-group">
          <div class="flex items-center gap-2">
            <label
              for="percentBurn"
              class="self-start flex items-center gap-2"
            >
              <span>{{ $t('Percent of tokens burned after lost') }}</span>
              <span class="text-gray-600">({{ $t('Divider') }})</span>
            </label>
            <ui-tooltip
              class="!flex"
              :text="$t('tooltip_burned_after')"
            >
              <feather-icon
                class="opacity-70 hover:opacity-100"
                type="help-circle"
                size="18"
              />
            </ui-tooltip>
          </div>
          <div class="flex items-center">
            <div class="text-2xl mr-4 whitespace-nowrap">
              1 /
            </div>
            <input
              required
              min="1"
              step="1"
              max="100"
              v-model="settings17"
              id="percentBurn"
              class="input input-blue w-full !rounded-r-none"
              type="number"
            />
            <div class="percent">
              {{ percentDivider ? `≈ ${percentDivider} %` : '' }}
            </div>
          </div>
        </div>
        <div class="form-group justify-self-end">
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['17']"
              @switched="setFixsHandler(17)"
            />
            <span class="inline-block w-36px">
              {{ fixs['17'] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import UiSwitcher from '@/components/ui/UiSwitcher.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

export default {
  components: {
    UiSwitcher,
    UiTooltip,
  },

  computed: {
    ...mapState('createsettings', {
      settings: 'settings',
      fixs: 'fixs',
    }),

    settings15: {
      get() {
        return this.settings['15'];
      },
      set(value) {
        this.setSettings({ value, i: 15 });
      },
    },

    settings16: {
      get() {
        return this.settings['16'];
      },
      set(value) {
        this.setSettings({ value, i: 16 });
      },
    },

    settings17: {
      get() {
        return this.settings['17'];
      },
      set(value) {
        this.setSettings({ value, i: 17 });
      },
    },

    percentDivider() {
      return this.settings17
        ? Math.round(100 / +this.settings17)
        : '';
    },
  },

  methods: {
    ...mapMutations('createsettings', [
      'setSettings',
    ]),
    ...mapMutations('createsettings', [
      'setFixs',
    ]),

    setFixsHandler(i) {
      this.setFixs({ value: !this.fixs[i], i });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  @apply flex flex-col gap-2;
}
.percent {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0 uppercase;
  @apply flex items-center rounded-r-md bg-gray-200 whitespace-nowrap;
}
</style>
