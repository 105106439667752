<template>
  <div class="flex flex-col gap-6">
    <div class="grid grid-cols-[34px,1fr,34px] items-center">
      <feather-icon
        type="arrow-left"
        size="18"
        class="bg-primary-100 hover:bg-primary-200 rounded-md px-2 py-1 cursor-pointer"
        @click="backHandler"
      />
      <div class="text-gray-600 font-600 text-lg">
        {{ $t('Get more') }}
        {{ collateralSymbol }}
      </div>
      <span></span>
    </div>

    <form
      class="flex flex-col gap-4"
      @submit.prevent="makeDepositHandler"
    >
      <div v-if="pending">
        <ui-pending-preloader />
      </div>
      <div v-else>
        <div class="mb-6">
          <span>{{ $t('Make a deposit to') }}</span>
          <span class="font-600">
           {{ ` ${collateralSymbol} ` }}
          </span>
          <span>{{ $t('contract to get') }}</span>
          <span class="font-600">
            {{ ` ${collateralSymbol} ` }}
          </span>
          <span>{{ $t('liquidity tokens') }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="self-start font-600 text-sm">
            {{ $t('Available') }}:
            {{ wrappedWbtcBalance }}
            wWBTC
          </div>
          <div class="flex items-center">
            <input
              required
              step="any"
              type="number"
              name="collateralDeposit"
              v-model="collateralDepositValue"
              class="input input-blue w-full !rounded-r-none"
            />
            <div class="symbol">
              wWBTC
            </div>
          </div>
        </div>
        <div class="mt-4 text-sm font-600">
          <div>
            {{ $t('Not enough') }} wWBTC
          </div>
          <div>
            <router-link
              class="text-primary-500 hover:text-primary-600"
              :to="{ name: 'wrap' }"
            >
              {{ $t('Wrap your') }} WBTC
            </router-link>
          </div>
        </div>
      </div>
      <ui-button-loading
        class="button button-primary px-3 py-2 w-full"
        type="submit"
        :loading="loadingCollateralDepositBtn"
      >
        {{ $t('Make deposit to') }} {{ collateralSymbol }}
      </ui-button-loading>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';

export default {
  components: {
    UiButtonLoading,
    UiPendingPreloader,
  },

  computed: {
    ...mapState('create', {
      collateralSymbol: 'collateralSymbol',
      collateralDeposit: 'collateralDeposit',
      loadingCollateralDepositBtn: 'loadingCollateralDepositBtn',
      pending: 'pending',
      wrappedWbtcBalance: 'wrappedWbtcBalance',
    }),
    ...mapState('metamask', {
      symbol: 'symbol',
    }),

    collateralDepositValue: {
      get() {
        return this.collateralDeposit;
      },
      set(value) {
        this.setCollateralDeposit(value);
      },
    },
  },

  created() {
    this.getWrappedWbtcBalance();
  },

  methods: {
    ...mapMutations('create', [
      'setCollateralDeposit',
      'setCheckingCollateralStage',
    ]),
    ...mapActions('create', ['makeDeposit', 'getWrappedWbtcBalance']),

    backHandler() {
      this.setCheckingCollateralStage('notice');
    },

    makeDepositHandler() {
      this.makeDeposit();
    },
  },
};
</script>

<style lang="scss" scoped>
.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center justify-center rounded-r-md bg-gray-200;
}
</style>
