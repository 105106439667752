<template>
  <div class="w-340px <phone-lg:(max-w-340px w-full)">
    <div class="text-gray-600 font-600 text-lg mb-6">
      {{ $t('Make Deposit') }}
    </div>
    <form
      @submit.prevent="makeDepositHandler"
      class="flex flex-col gap-6"
    >
      <div v-if="pending">
        <ui-pending-preloader />
      </div>
      <div v-else class="flex flex-col gap-6">
        <div>
          <span>{{ $t('Make a deposit to') }}</span>
          <span class="font-600">
           {{ ` ${symbol} ` }}
          </span>
          <span>{{ $t('contract to get') }}</span>
          <span class="font-600">
            {{ ` ${symbol} ` }}
          </span>
          <span>{{ $t('liquidity tokens') }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="self-start font-600 text-sm">
            {{ $t('Available') }}:
            {{ availableBalance[0].balance }}
            {{ availableBalance[0].title }}
          </div>
          <div class="flex items-center">
            <input
              required
              step="any"
              min="0"
              type="number"
              name="depositValue"
              v-model="depositValueInput"
              class="input input-blue w-full !rounded-r-none"
              @input="calculateReceive"
            />
            <ui-select
              class="symbol"
              v-model="selectedOption"
              :options="optionsSelect"
            />
          </div>
          <div class="self-start font-600 text-sm flex gap-2 relative w-full">
            <span>{{ $t('You will receive') }}</span>
            <span>&asymp;</span>
            <span>
              {{ isNative ? nativeReceive : collateralReceive }} {{ symbol }}
            </span>
            <ui-loading :loading="priceLoading" />
          </div>
        </div>
      </div>

      <ui-button-loading
        class="button button-primary px-3 py-2 w-full"
        type="submit"
        :loading="loadingDepositBtn"
      >
        {{ $t('Make Deposit') }}
      </ui-button-loading>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import { debounce, delay } from '@/utils';

export default {
  components: {
    UiButtonLoading,
    UiSelect,
    UiPendingPreloader,
    UiLoading,
  },

  data() {
    return {
      selected: '',
      priceLoading: false,
    };
  },

  computed: {
    ...mapState('contractsettings', {
      symbol: 'symbol',
    }),
    ...mapState('deposit', {
      depositValue: 'depositValue',
      pending: 'pending',
      loadingDepositBtn: 'loadingDepositBtn',
      collateralSymbol: 'collateralSymbol',
      collateralBalance: 'collateralBalance',
      wrappedWbtcSymbol: 'wrappedWbtcSymbol',
      wrappedWbtcBalance: 'wrappedWbtcBalance',
      collateralAddress: 'collateralAddress',
    }),
    ...mapGetters('price', {
      nativeReceive: 'depositNativeReceive',
      collateralReceive: 'depositCollateralReceive',
    }),
    ...mapGetters('app', {
      wrappedWbtcContract: 'wrappedWbtcContract',
    }),

    contractAddress() {
      return this.$route.params.contract_address;
    },

    isCollateralBalance() {
      return !!Number(this.collateralBalance);
    },

    depositValueInput: {
      get() {
        return this.depositValue;
      },
      set(value) {
        this.setDepositValue(value);
      },
    },

    selectedOption: {
      get() {
        return this.selected
          ? this.selected
          : this.wrappedWbtcSymbol;
      },
      set(value) {
        this.selected = value;
      },
    },

    optionsSelect() {
      const iswwbtc = this.collateralAddress.toLowerCase()
        === this.wrappedWbtcContract.contract.toLowerCase();

      if (iswwbtc) {
        return [
          {
            title: this.wrappedWbtcSymbol,
            value: this.wrappedWbtcSymbol,
            balance: this.wrappedWbtcBalance,
          },
        ];
      }
      return [
        {
          title: this.wrappedWbtcSymbol,
          value: this.wrappedWbtcSymbol,
          balance: this.wrappedWbtcBalance,
        },
        {
          title: this.collateralSymbol,
          value: this.collateralSymbol,
          balance: this.collateralBalance,
        },
      ];
    },

    availableBalance() {
      return this.optionsSelect
        .filter((item) => item.value === this.selectedOption);
    },

    isNative() {
      return this.selectedOption === this.wrappedWbtcSymbol;
    },
  },

  mounted() {
    this.depositValueInput = '';
    this.selected = '';
  },

  methods: {
    ...mapMutations('deposit', ['setDepositValue']),
    ...mapActions('deposit', ['depositNative', 'depositToken']),
    ...mapActions('price', ['getDepositNativePrice', 'getDepositCollateralPrice']),

    makeDepositHandler() {
      if (this.isNative) {
        this.depositNative(this.contractAddress);
      } else {
        this.depositToken(this.contractAddress);
      }
    },

    async calculateReceive() {
      this.priceLoading = true;
      try {
        if (this.isNative) {
          debounce(this.getDepositNativePrice);
          await delay(300);
        } else {
          debounce(this.getDepositCollateralPrice);
          await delay(300);
        }
        this.priceLoading = false;
      } catch (e) {
        this.priceLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.symbol {
  @apply h-42px min-w-100px border border-solid border-border-color border-l-0;
  @apply flex items-center justify-center rounded-r-md bg-gray-200;
}
</style>
