import { createStore } from 'vuex';
import metamask from './metamask';
import app from './app';
import list from './list';
import create from './create';
import createsettings from './createsettings';
import contract from './contract';
import contractsettings from './contractsettings';
import deposit from './deposit';
import withdrawal from './withdrawal';
import price from './price';
import wrap from './wrap';
import profile from './profile';
import verify from './verify';

export default createStore({
  modules: {
    metamask,
    app,
    list,
    create,
    createsettings,
    contract,
    contractsettings,
    deposit,
    withdrawal,
    price,
    wrap,
    profile,
    verify,
  },
});
