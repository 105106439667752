import { Decimal } from 'decimal.js';

Decimal.set({ toExpPos: 40 });

export default {
  namespaced: true,

  state: {
    collateral: '',
    owner: '',

    name: '',
    symbol: '',
    decimals: '',
    initialSupply: '',

    liquidity: '',

    settings: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: '',

      5: '', // depositFee
      6: 100, // depositFeeDivider
      7: '', // minDepositFee
      8: '', // ownerDepositFee
      9: 100, // ownerDepositFeeDivider

      10: '', // withdrawalFee
      11: 100, // withdrawalFeeDivider
      12: '', // minWithdrawalFee
      13: '', // ownerWithdrawalFee
      14: 100, // ownerWithdrawalFeeDivider

      15: '', // lockWithdrawal
      16: '', // lockLost
      17: '', // percentBurn
    },

    fixs: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,

      5: false, // depositFeeFix
      6: false, // depositFeeDividerFix
      7: false, // minDepositFeeFix
      8: false, // ownerDepositFeeFix
      9: false, // ownerDepositFeeDividerFix

      10: false, // withdrawalFeeFix
      11: false, // withdrawalFeeDividerFix
      12: false, // minWithdrawalFeeFix
      13: false, // ownerWithdrawalFeeFix
      14: false, // ownerWithdrawalFeeDividerFix

      15: false, // lockWithdrawalFix
      16: false, // lockLostFix
      17: false, // percentBurnFix
    },
  },

  getters: {
    settings(state, getters, rootState) {
      return {
        ...state.settings,
        0: state.settings['0']
          ? state.settings['0']
          : 0,
        1: state.settings['1']
          ? state.settings['1']
          : 0,
        2: state.settings['2']
          ? state.settings['2']
          : 0,
        3: state.settings['3']
          ? state.settings['3']
          : 0,
        4: state.settings['4']
          ? state.settings['4']
          : 0,
        7: state.settings['7'] && state.decimals
          ? new Decimal(state.settings['7'])
            .mul(Decimal.pow(10, state.decimals))
            .toString()
          : state.settings['7'],
        12: state.settings['12'] && rootState.create.collateralDecimals
          ? new Decimal(state.settings['12'])
            .mul(Decimal.pow(10, rootState.create.collateralDecimals))
            .toString()
          : state.settings['7'],
        15: state.settings['15']
          ? +state.settings['15'] * 86400
          : '',
        16: state.settings['16']
          ? +state.settings['16'] * 86400
          : '',
      };
    },

    initialSupply(state) {
      return state.decimals && state.initialSupply
        ? new Decimal(state.initialSupply).mul(Decimal.pow(10, state.decimals)).toString()
        : '';
    },
  },

  mutations: {
    setCollateral(state, value) {
      state.collateral = value;
    },

    setOwner(state, value) {
      state.owner = value;
    },

    setName(state, value) {
      state.name = value;
    },

    setSymbol(state, value) {
      state.symbol = value;
    },

    setDecimals(state, value) {
      state.decimals = value;
    },

    setInitialSupply(state, value) {
      state.initialSupply = value;
    },

    setLiquidity(state, value) {
      state.liquidity = value;
    },

    /* --------------------- Settings and fixs -------------------- */

    setSettings(state, { value, i }) {
      state.settings[i] = value;
    },

    setFixs(state, { value, i }) {
      state.fixs[i] = value;
    },

    resetMainInfo(state) {
      state.collateral = '';
      state.name = '';
      state.symbol = '';
      state.decimals = '';
      state.initialSupply = '';
      state.liquidity = '';
    },

    resetSettings(state) {
      const settingsLength = Object.keys(state.settings).length;
      for (let i = 0; i < settingsLength; i++) {
        if (i === 6 || i === 9 || i === 11 || i === 14) {
          state.settings[i] = 100;
        } else {
          state.settings[i] = '';
        }
      }
    },

    resetFixs(state) {
      const fixsLength = Object.keys(state.fixs).length;
      for (let i = 0; i < fixsLength; i++) {
        state.fixs[i] = false;
      }
    },
  },

  actions: {},
};
