<template>
  <div>
    <div class="my-4">
      <div
        class="w-full h-86px flex items-center justify-center font-600"
      >
        {{ $t('Your contract is registered') }}
      </div>
      <steps-wrapper />
    </div>
    <div class="mt-6">
      <button
        class="button button-primary px-3 py-2 w-full"
        @click="doneHandler"
      >
        {{ $t('Done') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import StepsWrapper from '@/components/create/StepsWrapper.vue';

export default {
  components: {
    StepsWrapper,
  },

  computed: {
    ...mapGetters('create', ['currentContract']),
  },

  methods: {
    ...mapMutations('create', ['showModal', 'setActiveStep']),

    doneHandler() {
      this.showModal(false);
      this.$router.push({
        name: 'contract',
        params: { contract_address: this.currentContract },
      });
      setTimeout(() => {
        this.setActiveStep(1);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
