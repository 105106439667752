<template>
  <div class="my-6">
    <skeleton-list v-if="skeletonLoading" />
    <div v-else>
      <ui-table
        v-if="derivativeList.length"
        :columns="tableColumns"
        :data="derivativeList"
        paginationable
        :loading="tableLoading"
        :per-page-default="perPageDefault"
        :per-page-options="perPageOptions"
        :total-entries="derivativeListTotal"
        @per-page-select-changed="perPageSelectHandler"
        @page-changed="pageChangeHandler"
      >
        <template v-slot:cell-current="{row: item}">
          <div class="flex items-center gap-2">
            <ui-tooltip
              class="!flex"
              text="Unverified token contract. You need to verify this contract!"
            >
              <feather-icon
                type="alert-triangle"
                size="18"
                class="text-red-400 hover:text-red-600"
              />
            </ui-tooltip>
            <router-link
              class="bg-primary-100 py-1 px-2 rounded-md hover:bg-primary-200"
              :to="{ name: 'contract', params: { contract_address: item.current } }"
            >
              {{ `${item.current.slice(0, 7)}...${item.current.slice(-5)}` }}
            </router-link>
            <ui-copy-to-clipboard
              :text-to-copy="item.current"
            />
          </div>
        </template>
        <template v-slot:cell-name="{row: item}">
          <router-link
            class="text-primary-500 hover:text-primary-600 inline-block"
            :to="{ name: 'contract', params: { contract_address: item.current } }"
          >
            <div class="flex items-center gap-2">
              <span>{{ item.name }}</span>
              <span class="text-gray-600 text-sm">{{ item.symbol }}</span>
            </div>
          </router-link>
        </template>
        <template v-slot:cell-verify="{row: item}">
          <ui-button-loading
            :loading="verifyBtnLoading[item.current]"
            class="button button-primary py-1 px-3"
            @click="verifyHandler(item.current)"
          >
            {{ $t('Verify') }}
          </ui-button-loading>
        </template>
      </ui-table>
      <div v-else class="text-gray-600 text-center">
        {{ $t('There are no tokens.') }}
      </div>
    </div>
    <ui-modal v-model="show">
      <verify-modal-content
        :contract-address="contractAddress"
      />
    </ui-modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import UiTable from '@/components/ui/UiTable.vue';
import SkeletonList from '@/components/skeleton/SkeletonList.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import VerifyModalContent from '@/components/verify/VerifyModalContent.vue';

export default {
  components: {
    UiTable,
    UiCopyToClipboard,
    SkeletonList,
    UiTooltip,
    UiButtonLoading,
    UiModal,
    VerifyModalContent,
  },

  data() {
    return {
      tableColumns: [
        {
          label: 'Contract',
          field: 'current',
          textAlign: 'text-left',
        },
        {
          label: 'Name',
          field: 'name',
          textAlign: 'text-center',
        },
        {
          label: 'Price',
          field: 'price',
          textAlign: 'text-center',
        },
        {
          label: 'Market Cap',
          field: 'marketCap',
          textAlign: 'text-center',
        },
        {
          label: 'Verify',
          field: 'verify',
          textAlign: 'text-right',
        },
      ],
      tableLoading: false,
      currentPage: '1',
      perPageDefault: '10',
      skeletonLoading: false,
      contractAddress: '',
      perPageOptions: [
        {
          title: '10',
          value: '10',
        },
        {
          title: '25',
          value: '25',
        },
      ],
      verifyBtnLoading: {},
    };
  },

  computed: {
    ...mapState('profile', {
      derivativeList: 'unverifiedListPaginate',
      derivativeListTotal: 'unverifiedListTotal',
      derivativeListLoaded: 'unverifiedListLoaded',
    }),
    ...mapState('verify', {
      isShowModal: 'isShowModal',
    }),

    profileAddress() {
      return this.$route.params.address;
    },

    show: {
      get() {
        return this.isShowModal;
      },

      set(value) {
        this.showModal(value);
      },
    },
  },

  created() {
    if (!this.derivativeListLoaded) {
      this.skeletonLoading = true;
    }
    this.loadDerivativesData(this.currentPage, this.perPageDefault);
  },

  methods: {
    ...mapActions('profile', ['loadUnverifiedList', 'getUnverifiedListPaginate']),
    ...mapMutations('verify', ['showModal']),
    ...mapActions('verify', ['getVerificationCost']),

    async loadDerivativesData() {
      try {
        await this.loadUnverifiedList(this.profileAddress);
        await this.getUnverifiedListPaginate({
          page: this.currentPage,
          perPage: this.perPageDefault,
        });
        this.tableLoading = false;
        this.skeletonLoading = false;
      } catch (e) {
        this.tableLoading = false;
        this.skeletonLoading = false;
      }
    },

    perPageSelectHandler(page, perPage) {
      this.getUnverifiedListPaginate({ page, perPage });
    },

    pageChangeHandler(page, perPage) {
      this.getUnverifiedListPaginate({ page, perPage });
    },

    async verifyHandler(address) {
      try {
        this.verifyBtnLoading[address] = true;
        this.contractAddress = address;
        await this.getVerificationCost();
        this.verifyBtnLoading[address] = false;
      } catch (e) {
        this.verifyBtnLoading[address] = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
