<template>
  <div>
    <ui-button-loading
      class="button button-primary px-3 py-8px w-full"
      :loading="loadingMainWithdrawalBtn"
      @click="withdrawalBtnHandler"
    >
      {{ $t('Withdrawal') }}
    </ui-button-loading>
    <ui-modal
      v-model="show"
    >
      <withdrawal-modal-content :key="show" />
    </ui-modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import WithdrawalModalContent from '@/components/contract/WithdrawalModalContent.vue';

export default {
  components: {
    UiButtonLoading,
    UiModal,
    WithdrawalModalContent,
  },

  computed: {
    ...mapState('withdrawal', {
      isShowModal: 'isShowModal',
      pending: 'pending',
      loadingMainWithdrawalBtn: 'loadingMainWithdrawalBtn',
    }),

    show: {
      get() {
        return this.isShowModal;
      },

      set(value) {
        this.showModal(value);
      },
    },

    contractAddress() {
      return this.$route.params.contract_address;
    },
  },

  methods: {
    ...mapMutations('withdrawal', {
      showModal: 'showModal',
    }),
    ...mapActions('withdrawal', ['checkTokenForWithdrawal']),

    withdrawalBtnHandler() {
      this.checkTokenForWithdrawal(this.contractAddress);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
