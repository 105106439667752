<template>
  <aside>
    <div
      class="sidebar"
      :class="{'sidebar-show': isShowSidebar}"
    >
      <div class="sidebar-menu">
        <div class="sidebar-header">
          <logo-nav />
          <i-burger-menu
            class="navbar-header-burger"
            :toggle="isShowSidebar"
            @click="closeSidebarHandler"
          />
        </div>
        <div class="sidebar-body">
          <router-link
            class="link"
            :to="{ name: 'home' }"
            @click="closeSidebarHandler"
          >
            <feather-icon type="home" size="18" />
            <span class="mx-4">
              {{ $t('Home') }}
            </span>
          </router-link>
          <a
            href="https://dewrap.io/"
            target="_blank"
            class="link"
            @click="closeSidebarHandler"
          >
            <feather-icon type="hash" size="18" />
            <span class="mx-4">
              {{ $t('About') }}
            </span>
          </a>
          <router-link
            class="link"
            :to="{ name: 'create' }"
            @click="closeSidebarHandler"
          >
            <feather-icon type="file-plus" size="18" />
            <span class="mx-4">
              {{ $t('Create') }}
            </span>
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'wrap' }"
            @click="closeSidebarHandler"
          >
            <feather-icon type="package" size="18" />
            <span class="mx-4">
              {{ $t('Wrap') }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="backdrop"
      :class="{'backdrop-show': isShowSidebar}"
      aria-hidden="true"
      @click="closeSidebarHandler"
    ></div>
  </aside>
</template>

<script>
import IBurgerMenu from '@/components/icon/IBurgerMenu.vue';
import LogoNav from '@/components/LogoNav.vue';

export default {
  components: {
    IBurgerMenu,
    LogoNav,
  },

  props: {
    isShowSidebar: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['closeSidebar'],

  methods: {
    closeSidebarHandler() {
      this.$emit('closeSidebar');
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  @apply fixed z-999 top-0;
  transition: all .1s ease;
  transform: translateX(var(--sidebar-width--));
}

.sidebar-show {
  transform: translateX(0);
}

.sidebar-menu {
  @apply flex flex-col h-screen bg-white;
  width: var(--sidebar-width);
}

.sidebar-header {
  @apply h-60px flex items-center justify-between gap-10px px-15px;
  @apply border-solid border-b-1 border-gray-200;
}

.sidebar-body {
  @apply flex flex-col overflow-y-auto overflow-x-hidden py-10px;
}

.link {
  @apply transition-all flex items-center py-10px px-15px;
}

.link:not(.router-link-exact-active):hover,
.link:not(.router-link-active):hover {
  @apply text-primary-500;
}

.link.router-link-exact-active,
.link.router-link-active {
  @apply text-primary-500;
}

.backdrop {
  @apply fixed w-screen h-screen bg-black z-100 opacity-0 invisible top-0;
  transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
}

.backdrop-show {
  @apply visible opacity-70;
}
</style>
