<template>
  <div>
    <deposit-settings />
    <withdrawal-settings />
    <lock-time-settings />
    <flashloan-settings />
  </div>
</template>

<script>
import DepositSettings from '@/components/contract/edit/DepositSettings.vue';
import WithdrawalSettings from '@/components/contract/edit/WithdrawalSettings.vue';
import LockTimeSettings from '@/components/contract/edit/LockTimeSettings.vue';
import FlashloanSettings from '@/components/contract/edit/FlashloanSettings.vue';

export default {
  components: {
    DepositSettings,
    WithdrawalSettings,
    LockTimeSettings,
    FlashloanSettings,
  },
};
</script>

<style lang="scss" scoped>
</style>
