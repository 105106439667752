import HomeView from '@/views/HomeView.vue';
import UnverifiedView from '@/views/UnverifiedView.vue';
import CreateView from '@/views/CreateView.vue';
import ContractView from '@/views/ContractView.vue';
import ProfileView from '@/views/ProfileView.vue';
import PortfolioView from '@/views/profile/PortfolioView.vue';
import VerifiedTokensView from '@/views/profile/VerifiedTokensView.vue';
import UnverifiedTokensView from '@/views/profile/UnverifiedTokensView.vue';
import WrapView from '@/views/WrapView.vue';
import PageNotFoundView from '@/views/PageNotFoundView.vue';

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/unverified-list',
    name: 'unverified-list',
    component: UnverifiedView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/create',
    name: 'create',
    component: CreateView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/contract/:contract_address',
    name: 'contract',
    component: ContractView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/address/:address',
    name: 'address',
    component: ProfileView,
    meta: {
      layout: 'DefaultLayout',
    },
    redirect: (to) => ({
      name: 'verified',
      params: { address: to.params.address },
    }),
    children: [
      {
        path: '/address/:address/tokens/verified',
        name: 'verified',
        component: VerifiedTokensView,
        meta: {
          layout: 'DefaultLayout',
        },
      },
    ],
  },
  {
    path: '/address/:address/tokens/verified',
    name: 'verified',
    component: VerifiedTokensView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/address/:address/tokens/unverified',
    name: 'unverified',
    component: UnverifiedTokensView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/address/:address/portfolio',
    name: 'portfolio',
    component: PortfolioView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/wrap',
    name: 'wrap',
    component: WrapView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFoundView,
    meta: {
      layout: 'DefaultLayout',
    },
  },
];
