import { useToast } from 'vue-toastification';
import { delay } from '@/utils';
import DerivativeNextFabAbi from '@/abis/DerivativeNextFab.json';
import DerivativeNextAbi from '@/abis/DerivativeNext.json';
import ERC20 from '@/abis/ERC20.json';
import NativeWithdrawal from '@/abis/NativeWithdrawal.json';
import { Decimal } from 'decimal.js';

Decimal.set({ toExpPos: 40 });

const toast = useToast();

export default {
  namespaced: true,

  state: {
    isShowModal: false,

    loadingMainCreateBtn: false,

    collateralBalance: 0,
    collateralSymbol: '',
    collateralDecimals: '',

    wrappedWbtcBalance: 0,
    wrappedWbtcSymbol: '',
    wrappedWbtcDecimals: '',

    checkingCollateralStage: 'notice',
    isCollateralEnough: false,

    collateralDeposit: '',
    loadingCollateralDepositBtn: false,

    activeStep: 1,
    currentContract: '',
    msgCreating: '',
    pending: false,
    loadingCreateContractBtn: false,
    loadingAddLiquidityBtn: false,
    loadingSetSettingsBtn: false,
    loadingRegisterContractBtn: false,
  },

  getters: {
    currentContract(state) {
      return state.currentContract
        || localStorage.getItem('current_contract')
        || '';
    },
  },

  mutations: {
    showModal(state, value) {
      state.isShowModal = value;
    },

    setLoadingMainCreateBtn(state, value) {
      state.loadingMainCreateBtn = value;
    },

    setCollateralBalance(state, value) {
      state.collateralBalance = value;
    },

    setCollateralSymbol(state, value) {
      state.collateralSymbol = value;
    },

    setCollateralDecimals(state, value) {
      state.collateralDecimals = value;
    },

    setWrappedWbtcBalance(state, value) {
      state.wrappedWbtcBalance = value;
    },

    setWrappedWbtcSymbol(state, value) {
      state.wrappedWbtcSymbol = value;
    },

    setWrappedWbtcDecimals(state, value) {
      state.wrappedWbtcDecimals = value;
    },

    setCheckingCollateralStage(state, value) {
      state.checkingCollateralStage = value;
    },

    setIsCollateralEnough(state, value) {
      state.isCollateralEnough = value;
    },

    setCollateralDeposit(state, value) {
      state.collateralDeposit = value;
    },

    setLoadingCollateralDepositBtn(state, value) {
      state.loadingCollateralDepositBtn = value;
    },

    setActiveStep(state, value) {
      state.activeStep = value;
    },

    setCurrentContract(state, value) {
      state.currentContract = value;
      localStorage.setItem('current_contract', value);
    },

    setMsgCreating(state, value) {
      state.msgCreating = value;
    },

    setPending(state, value) {
      state.pending = value;
    },

    setLoadingCreateContractBtn(state, value) {
      state.loadingCreateContractBtn = value;
    },

    setLoadingAddLiquidityBtn(state, value) {
      state.loadingAddLiquidityBtn = value;
    },

    setLoadingSetSettingsBtn(state, value) {
      state.loadingSetSettingsBtn = value;
    },

    setLoadingRegisterContractBtn(state, value) {
      state.loadingRegisterContractBtn = value;
    },
  },

  actions: {
    async checkCollateral(context) {
      context.commit('setCollateralBalance', 0);
      context.commit('setCollateralSymbol', '');
      context.commit('setCheckingCollateralStage', 'notice');

      try {
        context.commit('setLoadingMainCreateBtn', true);
        await context.dispatch('getCollateralBalance');
        await delay(200);
        context.commit('setLoadingMainCreateBtn', false);
        context.commit('showModal', true);
      } catch (e) {
        context.commit('setCollateralBalance', 0);
        context.commit('setCollateralSymbol', '');
        context.commit('setLoadingMainCreateBtn', false);
        context.commit('showModal', false);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async getCollateralBalance(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          ERC20,
          context.rootState.createsettings.collateral,
        );

        const dec = await contract.methods.decimals().call();
        const symbol = await contract.methods.symbol().call();

        if (context.rootState.metamask.address) {
          const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();
          const formatBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toString();
          context.commit('setCollateralBalance', formatBalance);
        }

        context.commit('setCollateralSymbol', symbol);
        context.commit('setCollateralDecimals', dec);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async getWrappedWbtcBalance(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          ERC20,
          context.rootGetters['app/wrappedWbtcContract'].contract,
        );

        const dec = await contract.methods.decimals().call();
        const symbol = await contract.methods.symbol().call();

        if (context.rootState.metamask.address) {
          const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();
          const formatBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toString();
          context.commit('setWrappedWbtcBalance', formatBalance);
        }

        context.commit('setWrappedWbtcDecimals', dec);
        context.commit('setWrappedWbtcSymbol', symbol);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async makeDeposit(context) {
      context.commit('setPending', true);
      context.commit('setLoadingCollateralDepositBtn', true);

      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = await new web3.eth.Contract(
          ERC20,
          context.rootGetters['app/wrappedWbtcContract'].contract,
        );

        const tokenAmount = new Decimal(context.state.collateralDeposit.toString())
          .times(Decimal.pow(10, context.state.wrappedWbtcDecimals));

        web3.eth.sendTransaction({
          from: context.rootState.metamask.address,
          gasPrice: context.rootGetters['app/gasPrice'],
          to: context.rootGetters['app/wrappedWbtcContract'].contract,
          data: contract.methods.transfer(
            context.rootState.createsettings.collateral,
            tokenAmount.toString(),
          ).encodeABI(),
        })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', async (receipt) => {
            console.log(receipt);
            await context.dispatch('metamask/getAccountBalance', null, { root: true });
            await context.dispatch('getCollateralBalance');
            await context.dispatch('getWrappedWbtcBalance');
            context.commit('setPending', false);
            context.commit('setLoadingCollateralDepositBtn', false);
            context.commit('setCheckingCollateralStage', 'notice');
            context.commit('setCollateralDeposit', '');
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setPending', false);
            context.commit('setLoadingCollateralDepositBtn', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        context.commit('setPending', false);
        context.commit('setLoadingCollateralDepositBtn', false);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async createContract(context) {
      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          DerivativeNextFabAbi,
          context.rootGetters['app/factoryContract'].contract,
        );

        context.commit('setLoadingCreateContractBtn', true);
        context.commit('setPending', true);

        contract.methods.newDerivative(
          context.rootGetters['app/wrappedWbtcContract'].contract,
          context.rootState.createsettings.collateral,
          context.rootState.createsettings.owner,
          context.rootState.createsettings.decimals,
          context.rootState.createsettings.name,
          context.rootState.createsettings.symbol,
          context.rootGetters['createsettings/initialSupply'],
        )
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.dispatch('setAddressAfterContractCreated', receipt);
            context.commit('setMsgCreating', 'Contract Created');
            context.commit('setActiveStep', 2);

            context.commit('setLoadingCreateContractBtn', false);
            context.commit('setPending', false);
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setMsgCreating', 'Something went wrong');
            context.commit('setLoadingCreateContractBtn', false);
            context.commit('setPending', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    setAddressAfterContractCreated(context, receipt) {
      const values = receipt.events['CreateDerivativeNext'].returnValues;
      context.commit('setCurrentContract', values.current);
    },

    async addLiquidity(context) {
      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          ERC20,
          context.rootState.createsettings.collateral,
        );

        context.commit('setLoadingAddLiquidityBtn', true);
        context.commit('setPending', true);

        const tokenAmount = new Decimal(context.rootState.createsettings.liquidity.toString())
          .times(Decimal.pow(10, context.state.collateralDecimals));

        web3.eth.sendTransaction({
          from: context.rootState.metamask.address,
          gasPrice: context.rootGetters['app/gasPrice'],
          to: context.rootState.createsettings.collateral,
          data: contract.methods.transfer(context.getters.currentContract, tokenAmount.toString()).encodeABI(),
        })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.commit('setMsgCreating', 'Liquidity Added');
            context.commit('setActiveStep', 3);
            context.commit('setLoadingAddLiquidityBtn', false);

            context.commit('setPending', false);
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setLoadingAddLiquidityBtn', false);
            context.commit('setMsgCreating', 'Something went wrong');
            context.commit('setPending', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async setSettings(context) {
      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          DerivativeNextAbi,
          context.getters.currentContract,
        );

        context.commit('setLoadingSetSettingsBtn', true);
        context.commit('setPending', true);

        const settings = Object.values(context.rootGetters['createsettings/settings']);
        const fixs = Object.values(context.rootState.createsettings.fixs);

        contract.methods.changeSettings(settings, fixs, 0, 17)
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.commit('setMsgCreating', 'Settings Applied');
            context.commit('setActiveStep', 4);
            context.commit('setLoadingSetSettingsBtn', false);

            context.commit('setPending', false);
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setLoadingSetSettingsBtn', false);
            context.commit('setMsgCreating', 'Something went wrong');
            context.commit('setPending', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async registerContract(context) {
      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          NativeWithdrawal,
          context.rootGetters['app/nativeWithdrawalContract'].contract,
        );

        context.commit('setLoadingRegisterContractBtn', true);
        context.commit('setPending', true);

        contract.methods.addNewDerivative(context.getters.currentContract)
          .send({
            from: context.rootState.metamask.address,
            gasPrice: context.rootGetters['app/gasPrice'],
          })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
            context.dispatch('metamask/getAccountBalance', null, { root: true });
            context.commit('setActiveStep', 5);
            context.commit('setLoadingRegisterContractBtn', false);
            context.commit('setMsgCreating', 'Contract registered');

            context.commit('setPending', false);
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setLoadingRegisterContractBtn', false);
            context.commit('setMsgCreating', 'Something went wrong');
            context.commit('setPending', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },
  },
};
