const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const priceFormatterDigits = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 8,
});

const capFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function currencyPriceFormatter(value) {
  if (Number(value) > 0.01) {
    return priceFormatter.format(value);
  }
  return priceFormatterDigits.format(value);
}

export function currencyCapFormatter(value) {
  return capFormatter.format(value);
}
