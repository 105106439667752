import web3Utils from 'web3-utils';
import { Decimal } from 'decimal.js';
import NativePriceContractAbi from '@/abis/NativePriceContract.json';

Decimal.set({ toExpPos: 40 });

export default {
  namespaced: true,

  state: {
    depositNativeCount: '',
    depositCollateralCount: '',

    withdrawalNativeCount: '',
    withdrawalCollateralCount: '',
  },

  getters: {
    depositNativeReceive(state, getters, rootState) {
      if (!!state.depositNativeCount && !!rootState.deposit.depositValue) {
        return new Decimal(rootState.deposit.depositValue)
          .mul(state.depositNativeCount)
          .toFixed(+rootState.contractsettings.decimals, Decimal.ROUND_DOWN);
      }
      return 0;
    },

    depositCollateralReceive(state, getters, rootState) {
      if (!!state.depositCollateralCount && !!rootState.deposit.depositValue) {
        return new Decimal(rootState.deposit.depositValue)
          .mul(state.depositCollateralCount)
          .toFixed(+rootState.contractsettings.decimals, Decimal.ROUND_DOWN);
      }
      return 0;
    },

    withdrawalNativeReceice(state, getters, rootState) {
      if (!!state.withdrawalNativeCount && !!rootState.withdrawal.withdrawalValue) {
        return new Decimal(rootState.withdrawal.withdrawalValue)
          .mul(state.withdrawalNativeCount)
          .toFixed();
      }
      return 0;
    },

    withdrawalCollateralReceive(state, getters, rootState) {
      if (!!state.withdrawalCollateralCount && !!rootState.withdrawal.withdrawalValue) {
        return new Decimal(rootState.withdrawal.withdrawalValue)
          .mul(state.withdrawalCollateralCount)
          .toFixed(+rootState.contractsettings.collateralDecimals, Decimal.ROUND_DOWN);
      }
      return 0;
    },
  },

  mutations: {
    setDepositNativeCount(state, value) {
      state.depositNativeCount = value;
    },

    setDepositCollateralCount(state, value) {
      state.depositCollateralCount = value;
    },

    setWithdrawalNativeCount(state, value) {
      state.withdrawalNativeCount = value;
    },

    setWithdrawalCollateralCount(state, value) {
      state.withdrawalCollateralCount = value;
    },
  },

  actions: {
    async getDepositNativePrice(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          NativePriceContractAbi,
          context.rootGetters['app/nativePriceContract'].contract,
        );

        const rawPrice = await contract.methods.getTokenForChain(
          context.rootState.contract.address,
          context.rootGetters['app/derivativeRoot'].contract,
          '1000000000000000000',
        ).call();

        const dec = context.rootState.contractsettings.decimals;

        const count = new Decimal(rawPrice).dividedBy(Decimal.pow(10, dec));
        context.commit('setDepositNativeCount', count.toString());
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async getDepositCollateralPrice(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          NativePriceContractAbi,
          context.rootGetters['app/nativePriceContract'].contract,
        );

        const decCol = context.rootState.contractsettings.collateralDecimals;
        const dec = context.rootState.contractsettings.decimals;

        const rawPrice = await contract.methods.getTokenForChain(
          context.rootState.contract.address,
          context.rootState.contract.address,
          (1000000 * (10 ** Number(decCol))).toString(),
        ).call();

        const prePrice = new Decimal(rawPrice).dividedBy(1000000);

        const count = new Decimal(prePrice).dividedBy(Decimal.pow(10, dec));
        context.commit('setDepositCollateralCount', count.toString());
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async getWithdrawalNativePrice(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          NativePriceContractAbi,
          context.rootGetters['app/nativePriceContract'].contract,
        );

        const dec = context.rootState.contractsettings.decimals;

        const rawPrice = await contract.methods.getSwapTokenForChain(
          context.rootState.contract.address,
          context.rootGetters['app/derivativeRoot'].contract,
          (1000000 * (10 ** Number(dec))).toString(),
        ).call();

        const prePrice = new Decimal(rawPrice).dividedBy(1000000);
        const wei = prePrice.truncated().toString();
        const count = web3Utils.fromWei(wei);

        context.commit('setWithdrawalNativeCount', count);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async getWithdrawalCollateralPrice(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          NativePriceContractAbi,
          context.rootGetters['app/nativePriceContract'].contract,
        );

        const decCol = context.rootState.contractsettings.collateralDecimals;
        const dec = context.rootState.contractsettings.decimals;

        const rawPrice = await contract.methods.getSwapTokenForChain(
          context.rootState.contract.address,
          context.rootState.contract.address,
          (1000000 * (10 ** Number(dec))).toString(),
        ).call();

        const prePrice = new Decimal(rawPrice).dividedBy(1000000);

        const count = new Decimal(prePrice).dividedBy(Decimal.pow(10, decCol));
        context.commit('setWithdrawalCollateralCount', count.toString());
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
