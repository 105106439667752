<template>
  <div>
    <ui-steps
      :steps="2"
      :active-step="activeStep"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UiSteps from '@/components/ui/UiSteps.vue';

export default {
  components: {
    UiSteps,
  },

  computed: {
    ...mapState('wrap', {
      activeStep: 'activeStep',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
