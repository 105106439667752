<template>
  <div>
    <div class="card-settings-title">
      <div>
        {{ $t('Deposit fee') }}
      </div>
    </div>
    <div class="card-settings-body flex flex-col gap-6">
      <div
        class="grid grid-cols-[1fr,84px] gap-4"
        :class="{ '!grid-cols-[1fr]': fixsView['5'] }"
      >
        <div class="form-group">
          <label
            for="mintfee"
            class="self-start flex items-center gap-2"
          >
            <span>{{ $t('Mint fee') }}</span>
            <span class="text-gray-600">({{ $t('%') }})</span>
          </label>
          <input
            required
            min="0"
            max="100"
            step="1"
            v-model="settings5"
            id="mintfee"
            class="input input-blue w-full"
            type="number"
            :disabled="fixsView['5']"
          />
        </div>
        <div
          v-if="!fixsView['5']"
          class="form-group justify-self-end"
        >
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['5']"
              @switched="setFixsHandler(5)"
            />
            <span class="inline-block w-36px">
              {{ fixs['5'] }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-[1fr,84px] gap-4"
        :class="{ '!grid-cols-[1fr]': fixsView['7'] }"
      >
        <div class="form-group">
          <label
            for="minimalFee"
            class="self-start flex items-center gap-2"
          >
            <span>{{ $t('Users minimal fee') }}</span>
            <span class="text-gray-600">({{ $t('Token count') }})</span>
          </label>
          <div class="flex items-center">
            <input
              required
              min="0"
              step="any"
              v-model="settings7"
              id="minimalFee"
              class="input input-blue w-full !rounded-r-none"
              type="number"
              :disabled="fixsView['7']"
            />
            <div class="symbol">
            </div>
          </div>
        </div>
        <div
          v-if="!fixsView['7']"
          class="form-group justify-self-end"
        >
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['7']"
              @switched="setFixsHandler(7)"
            />
            <span class="inline-block w-36px">
              {{ fixs['7'] }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-[1fr,84px] gap-4"
        :class="{ '!grid-cols-[1fr]': fixsView['8'] }"
      >
        <div class="form-group">
          <label
            for="ownerFee"
            class="self-start flex items-center gap-2"
          >
            <span>{{ $t('Owners percent of users fee') }}</span>
            <span class="text-gray-600">({{ $t('%') }})</span>
          </label>
          <input
            required
            min="0"
            max="100"
            step="1"
            v-model="settings8"
            id="ownerFee"
            class="input input-blue w-full"
            type="number"
            :disabled="fixsView['8']"
          />
        </div>
        <div
          v-if="!fixsView['8']"
          class="form-group justify-self-end"
        >
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['8']"
              @switched="setFixsHandler(8)"
            />
            <span class="inline-block w-36px">
              {{ fixs['8'] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import UiSwitcher from '@/components/ui/UiSwitcher.vue';

export default {
  components: {
    UiSwitcher,
  },

  computed: {
    ...mapState('contractsettings', {
      settings: 'settings',
      fixs: 'fixs',
      fixsView: 'fixsView',
    }),

    settings5: {
      get() {
        return this.settings['5'];
      },
      set(value) {
        this.setSettings({ value, i: 5 });
      },
    },

    settings7: {
      get() {
        return this.settings['7'];
      },
      set(value) {
        this.setSettings({ value, i: 7 });
      },
    },

    settings8: {
      get() {
        return this.settings['8'];
      },
      set(value) {
        this.setSettings({ value, i: 8 });
      },
    },
  },

  methods: {
    ...mapMutations('contractsettings', [
      'setSettings',
    ]),
    ...mapMutations('contractsettings', [
      'setFixs',
    ]),

    setFixsHandler(i) {
      this.setFixs({ value: !this.fixs[i], i });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  @apply flex flex-col gap-2;
}

.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center rounded-r-md bg-gray-200;
}
</style>
