<template>
  <div
    class="dropdown-menu"
    @click.stop
  >
    <div class="flex flex-col">
      <div class="flex items-center justify-between text-sm font-600">
        <div class="flex items-center gap-2">
          <feather-icon type="user" size="18" />
          <span>{{ shortAddress }}</span>
        </div>
        <div class="flex items-center gap-3">
          <ui-copy-to-clipboard
            :text-to-copy="address"
          />
          <a
            class="flex"
            :href="`${explorerLink}/address/${address}`"
            rel="noopener noreferrer"
            target="_blank"
          >
            <feather-icon type="external-link" size="18" />
          </a>
        </div>
      </div>
      <div class="flex flex-col items-center gap-2 border-b-1 border-solid border-border-color pb-16px pt-6">
        <div class="text-sm text-gray-500 font-600">
          {{ symbol }} {{ $t('Balance') }}
        </div>
        <div class="text-2xl font-600">
          {{ balance }} {{ symbol }}
        </div>
      </div>
      <div class="mt-16px flex flex-col gap-4">
        <router-link
          class="link"
          :to="{ name: 'portfolio', params: { address: address } }"
          @click="closeHandler"
        >
          <feather-icon type="briefcase" size="18" />
          <span class="mx-4">
            {{ $t('Portfolio') }}
          </span>
        </router-link>
        <router-link
          class="link"
          :class="{ 'router-link-active': contractsLinkActive }"
          :to="{ name: 'address', params: { address: address } }"
          @click="closeHandler"
        >
          <feather-icon type="file-text" size="18" />
          <span class="mx-4">
            {{ $t('Contracts') }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';

export default {
  components: {
    UiCopyToClipboard,
  },

  emits: ['closeAccountDropdownMenu'],

  computed: {
    ...mapState('metamask', {
      address: 'address',
      symbol: 'symbol',
      balance: 'balance',
      explorerLink: 'explorerLink',
    }),
    ...mapGetters('metamask', {
      shortAddress: 'shortAddress',
    }),

    contractsLinkActive() {
      const matches = ['verified', 'unverified'];
      const path = this.$route.path.split('/')[4];
      return matches.some((match) => match === path);
    },
  },

  methods: {
    closeHandler() {
      this.$emit('closeAccountDropdownMenu');
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  @apply shadow-primary rounded-md;
  width: 300px;
  position: absolute;
  right: 0;
  top: 44px;
  left: auto;
  background: #fff;
  border: 1px solid theme('colors.border-color');
  padding: 16px;
  z-index: 101;
}

.link {
  @apply transition-all flex items-center;
}

.link:not(.router-link-exact-active):hover,
.link:not(.router-link-active):hover {
  @apply text-primary-500;
}

.link.router-link-exact-active,
.link.router-link-active {
  @apply text-primary-500;
}
</style>
