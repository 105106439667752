<template>
  <div class="card-settings">
    <div class="text-center text-lg uppercase">
      {{ $t('Token settings') }}
    </div>
    <div class="card-settings-body flex flex-col gap-6">
      <div class="form-group">
        <label
          for="tokenName"
          class="self-start"
        >
          {{ $t('Name') }}
        </label>
        <input
          required
          v-model="tokenName"
          id="tokenName"
          class="input input-blue"
          type="text"
        />
      </div>
      <div class="grid grid-cols-2 gap-6">
        <div class="form-group">
          <label
            for="symbol"
            class="self-start"
          >
            {{ $t('Symbol') }}
          </label>
          <input
            required
            v-model="tokenSymbol"
            id="symbol"
            class="input input-blue"
            type="text"
          />
        </div>
        <div class="form-group">
          <label
            for="decimals"
            class="self-start"
          >
            {{ $t('Decimals') }}
          </label>
          <input
            required
            min="0"
            max="8"
            step="1"
            v-model="tokenDecimals"
            id="decimals"
            class="input input-blue"
            type="number"
          />
        </div>
      </div>
      <div class="form-group">
        <label
          for="initialSupply"
          class="self-start"
        >
          {{ $t('Initial Supply') }}
        </label>
        <div class="flex items-center">
          <input
            required
            min="0"
            step="any"
            v-model="tokenInitialSupply"
            id="initialSupply"
            class="input input-blue w-full !rounded-r-none"
            type="number"
          />
          <div class="symbol">
            {{ tokenSymbol }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('createsettings', [
      'name', 'symbol', 'decimals', 'initialSupply',
    ]),

    tokenName: {
      get() {
        return this.name;
      },
      set(value) {
        this.setName(value);
      },
    },

    tokenSymbol: {
      get() {
        return this.symbol;
      },
      set(value) {
        this.setSymbol(value);
      },
    },

    tokenDecimals: {
      get() {
        return this.decimals;
      },
      set(value) {
        this.setDecimals(value);
      },
    },

    tokenInitialSupply: {
      get() {
        return this.initialSupply;
      },
      set(value) {
        this.setInitialSupply(value);
      },
    },
  },

  methods: {
    ...mapMutations('createsettings', [
      'setName', 'setSymbol', 'setDecimals', 'setInitialSupply',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  @apply flex flex-col gap-2;
}
.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center justify-center rounded-r-md bg-gray-200;
}
</style>
