<template>
  <div class="w-340px <phone-lg:(max-w-340px w-full)">
    <div class="text-gray-600 font-600 text-lg mb-6">
      {{ $t('Withdrawal tokens') }}
    </div>

    <form
      @submit.prevent="withdrawalHandler"
      class="flex flex-col gap-6"
    >
      <div v-if="pending">
        <ui-pending-preloader />
      </div>
      <div v-else class="flex flex-col gap-6">
        <div>
          <span>{{ $t('Withdrawal token') }}</span>
          <span class="font-600">
           {{ ` ${myTokenSymbol} ` }}
          </span>
          <span>{{ $t('and get liquidity in') }}</span>
          <span class="font-600">
           {{ ` ${wrappedWbtcSymbol} ` }}
          </span>
          <span>
            {{ $t('or') }}
          </span>
          <span class="font-600">
           {{ ` ${collateralSymbol} ` }}
          </span>
          <span>
            {{ $t('collateral tokens') }}
          </span>
        </div>
        <div class="flex flex-col gap-5px">
          <div class="self-start font-600 text-sm">
            {{ $t('Available') }}:
            {{ myTokenBalance }}
            {{ myTokenSymbol }}
          </div>
          <div class="flex items-center">
            <input
              required
              step="any"
              min="0"
              type="number"
              name="withdrawalValue"
              v-model="withdrawalValueInput"
              class="input input-blue w-full !rounded-r-none"
              @input="calculateReceive"
            />
            <div class="symbol">
              {{ myTokenSymbol }}
            </div>
          </div>
          <div class="flex justify-center">
            <feather-icon type="arrow-down" size="18" />
          </div>
          <ui-select
            class="select"
            v-model="selectedOption"
            :options="optionsSelect"
          />
          <div class="self-start font-600 text-sm flex gap-2 relative w-full">
            <span>{{ $t('You will receive') }}</span>
            <span>&asymp;</span>
            <span>
              {{ isNative ? nativeReceive : collateralReceive }} {{ selectedOption }}
            </span>
            <ui-loading :loading="priceLoading" />
          </div>
        </div>
      </div>

      <ui-button-loading
        class="button button-primary px-3 py-2 w-full"
        type="submit"
        :loading="loadingWithdrawalBtn"
      >
        {{ $t('Withdrawal') }}
      </ui-button-loading>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import { debounce, delay } from '@/utils';

export default {
  components: {
    UiButtonLoading,
    UiPendingPreloader,
    UiSelect,
    UiLoading,
  },

  data() {
    return {
      selected: '',
      priceLoading: false,
    };
  },

  computed: {
    ...mapState('withdrawal', {
      withdrawalValue: 'withdrawalValue',
      pending: 'pending',
      myTokenSymbol: 'myTokenSymbol',
      myTokenBalance: 'myTokenBalance',
      collateralSymbol: 'collateralSymbol',
      loadingWithdrawalBtn: 'loadingWithdrawalBtn',
      wrappedWbtcSymbol: 'wrappedWbtcSymbol',
      collateralAddress: 'collateralAddress',
    }),
    ...mapGetters('price', {
      nativeReceive: 'withdrawalNativeReceice',
      collateralReceive: 'withdrawalCollateralReceive',
    }),
    ...mapGetters('app', {
      wrappedWbtcContract: 'wrappedWbtcContract',
    }),

    withdrawalValueInput: {
      get() {
        return this.withdrawalValue;
      },
      set(value) {
        this.setWithdrawalValue(value);
      },
    },

    contractAddress() {
      return this.$route.params.contract_address;
    },

    selectedOption: {
      get() {
        return this.selected
          ? this.selected
          : this.wrappedWbtcSymbol;
      },
      set(value) {
        this.selected = value;
      },
    },

    optionsSelect() {
      const iswwbtc = this.collateralAddress.toLowerCase()
        === this.wrappedWbtcContract.contract.toLowerCase();

      if (iswwbtc) {
        return [
          {
            title: this.wrappedWbtcSymbol,
            value: this.wrappedWbtcSymbol,
          },
        ];
      }
      return [
        {
          title: this.wrappedWbtcSymbol,
          value: this.wrappedWbtcSymbol,
        },
        {
          title: this.collateralSymbol,
          value: this.collateralSymbol,
        },
      ];
    },

    isNative() {
      return this.selectedOption === this.wrappedWbtcSymbol;
    },
  },

  mounted() {
    this.withdrawalValueInput = '';
  },

  methods: {
    ...mapMutations('withdrawal', ['setWithdrawalValue']),
    ...mapActions('withdrawal', ['withdrawalTokens', 'withdrawalNative']),
    ...mapActions('price', ['getWithdrawalNativePrice', 'getWithdrawalCollateralPrice']),

    withdrawalHandler() {
      if (this.isNative) {
        this.withdrawalNative(this.contractAddress);
      } else {
        this.withdrawalTokens(this.contractAddress);
      }
    },

    async calculateReceive() {
      this.priceLoading = true;
      try {
        if (this.isNative) {
          debounce(this.getWithdrawalNativePrice);
          await delay(300);
        } else {
          debounce(this.getWithdrawalCollateralPrice);
          await delay(300);
        }
        this.priceLoading = false;
      } catch (e) {
        this.priceLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center justify-center rounded-r-md bg-gray-200;
}

.select {
  @apply w-full h-42px border-1 border-solid border-border-color bg-white rounded-md;
  @apply flex items-center px-4px;
}
</style>
