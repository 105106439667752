<template>
  <div class="w-340px <phone-lg:(max-w-340px w-full)">
    <div class="text-center">
      <div class="text-2xl font-600 mb-2">
        {{ $t('Welcome to DeWrap') }}
      </div>
      <div>
        {{ $t('Install MetaMask to get started') }}
      </div>
    </div>
    <div class="relative">
      <a
        class="link"
        href="https://metamask.io/"
        rel="noopener noreferrer"
        target="_blank"
      ></a>
      <div class="install">
        <div>
          {{ $t('Install MetaMask') }}
        </div>
        <div>
          <img src="@/assets/img/metamask.svg" alt="metamask">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.link {
  @apply absolute h-full w-full left-0 top-0;

  &:hover {
    box-shadow:
      rgbA(0, 0, 0, 0.02) 0 0 0 1px,
      rgbA(0, 0, 0, 0.05) 5px 5px 20px,
      rgbA(0, 0, 0, 0.05) -5px -5px 20px;
  }
}

.install {
  @apply cursor-pointer flex justify-between items-center px-4 py-2 rounded-md mt-10;
  box-shadow:
    rgbA(0, 0, 0, 0.02) 0 0 0 1px,
    rgbA(0, 0, 0, 0.05) 5px 5px 15px,
    rgbA(0, 0, 0, 0.05) -5px -5px 15px;
}
</style>
