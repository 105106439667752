<template>
  <div>
    <div class="card-settings-title">
      <div class="flex items-center gap-2">
        <span>{{ $t('Lock time') }}</span>
        <span class="font-600 text-gray-600">
          ({{ $t('after this time, withdrawal fee is 0') }})
        </span>
      </div>
    </div>
    <div class="card-settings-body h-scroll">
      <table class="w-full">
        <tbody>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Withdrawal without fees after') }}</th>
          <td>{{ `${settings['15']} ${$t('Days')}` }} </td>
          <td class="pl-3 text-right">
            <span :class="[fixs['15'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['15'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Token is considered lost after') }}</th>
          <td>{{ `${settings['16']} ${$t('Days')}`}}</td>
          <td class="pl-3 text-right">
            <span :class="[fixs['16'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['16'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Percent of tokens burned after lost') }}</th>
          <td>~ {{ percentDivider }} %</td>
          <td class="pl-3 text-right">
            <span :class="[fixs['17'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['17'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('contractsettings', {
      settings: 'settings',
      fixs: 'fixs',
    }),

    percentDivider() {
      return this.settings['17']
        ? Math.round(100 / +this.settings['17'])
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
