import Web3 from 'web3';
import web3Utils from 'web3-utils';
import { Decimal } from 'decimal.js';
import { getProvider, chooseProvider } from '@/utils';

Decimal.set({ toExpPos: 40 });

export default {
  namespaced: true,

  state: {
    wbtcPrice: '',
    gasPrice: '',
    wbtcPriceCacheExpired: 60,
    locale: localStorage.getItem('locale') ?? 'en',
    trigger: 1,
    selectedNetwork: '',
    networkContract: '',
    networkOptions: [
      {
        title: 'Polygon',
        chainId: '0x89',
        img: 'polygon.png',
        chainName: 'Polygon',
        nativeCurrency: {
          name: 'Matic',
          symbol: 'MATIC',
          decimals: 18,
        },
        // rpcUrls: ['https://endpoints.omniatech.io/v1/matic/mainnet/public'],
        // rpcUrls: ['https://1rpc.io/matic'],
        // rpcUrls: ['https://gateway.tenderly.co/public/polygon'],
        rpcUrls: ['https://1rpc.io/matic'],
        blockExplorerUrls: ['https://polygonscan.com'],
      },
      {
        title: 'Mumbai',
        chainId: '0x13881',
        img: 'polygon.png',
        chainName: 'Mumbai',
        nativeCurrency: {
          name: 'Matic',
          symbol: 'MATIC',
          decimals: 18,
        },
        // rpcUrls: ['https://endpoints.omniatech.io/v1/matic/mumbai/public'],
        // rpcUrls: ['https://gateway.tenderly.co/public/polygon-mumbai'],
        rpcUrls: ['https://polygon-mumbai.g.alchemy.com/v2/demo'],
        blockExplorerUrls: ['https://mumbai.polygonscan.com'],
      },
      // {
      //   title: 'Local',
      //   chainId: '0x7a69',
      //   img: 'local.png',
      //   chainName: 'Local',
      //   nativeCurrency: {
      //     name: 'Eth',
      //     symbol: 'ETH',
      //     decimals: 18,
      //   },
      //   rpcUrls: ['http://localhost:8545'],
      //   blockExplorerUrls: ['http://localhost:8545'],
      // },
    ],
    unsupportedNetwork: {
      title: 'Unsupported',
      value: 'unsupported',
      img: 'unsupported.svg',
    },

    contracts: {
      derivativeRoot: {
        '0x7a69': {
          contract: '0x0F1Dc9C6AB2e2bF236a3ffea6B94dd573469f9ad',
          block: 7717,
        },
        '0x13881': {
          contract: '0xdE50fdf0d4d4DA0C1D51041F1aD9De4580869947',
          block: 34253421,
        },
        '0x89': {
          contract: '0x50c3cf5Dc1c9E86FEEF88b892041AE9F7aEfe744',
          block: 0,
        },
      },

      factoryContract: {
        '0x7a69': {
          contract: '0x403d3e131C4B20c74792643cd0E3430FF494A4FC',
          block: 7713,
        },
        '0x13881': {
          contract: '0x9f38201bd9a8d0A315bf38f5864d97b58aa1a74F',
          block: 34541901,
        },
        '0x89': {
          contract: '0xdcC5540d4205EcbAb0e8B0917Acf11B4825C291b',
          block: 41879578,
        },
      },

      nativePriceContract: {
        '0x7a69': {
          contract: '0x18074926E8740d31EBD7A68710B8b5bDbC154e49',
          block: 7636,
        },
        '0x13881': {
          contract: '0x93aA296928D50696a3E3f3F15bBAD1e959e76f0E',
          block: 34542055,
        },
        '0x89': {
          contract: '0xc50fb6EB7491FcaB7923056cc2ae61011f6a8719',
          block: 41701962,
        },
      },

      nativeWithdrawalContract: {
        '0x7a69': {
          contract: '0x441E461983276634fDfc35C391Ff9c608F229281',
          block: 7715,
        },
        '0x13881': {
          contract: '0x7076649356A2E7cA7A6B71cfD9293161A13C91C8',
          block: 34542061,
        },
        '0x89': {
          contract: '0xCD11d8eEC6ABedB6a43B6781801130F6BFD3e681',
          block: 41789191,
        },
      },

      settingsRetrieverContract: {
        '0x7a69': {
          contract: '0x07E0e38A46E6F55815574e0A3988D6707c429d92',
          block: 7637,
        },
        '0x13881': {
          contract: '0xDa55c02F1DB802195a6406860c0E8778073276E7',
          block: 34542058,
        },
        '0x89': {
          contract: '0x3195d498492377BF05896856842810028E968285',
          block: 41701965,
        },
      },

      verifyContract: {
        '0x7a69': {
          contract: '0x24ee3cA8E60FCfCB7d06417504a35e5A605B1783',
          block: 7714,
        },
        '0x13881': {
          contract: '0x29F5d0a99280d19B8AE568d7571434df0544C6e5',
          block: 34903385,
        },
        '0x89': {
          contract: '0x4B7E67CCaa28b10FBfE3d1DD23ab1ea70F71A5D3',
          block: 41880447,
        },
      },

      wrappedWbtcContract: {
        '0x7a69': {
          contract: '0x29F693773212b4602010d38129698f862c3F025d',
          block: 7632,
        },
        '0x13881': {
          contract: '0xf4200039Cb3044796251fcE319619DA4340c1Cee',
          block: 34541790,
        },
        '0x89': {
          contract: '0x7b66444f328e34D792badB0EcB67646dCEcd2b8B',
          block: 41701214,
        },
      },

      wBtcContract: {
        '0x7a69': {
          contract: '0x541e277297b495c5c20a289094931c0a3b16628A',
          block: 7532,
        },
        '0x13881': {
          contract: '0xa4f94634fC7136c80B3BbD7aCEadF8F302C52bB1',
          block: 34053231,
        },
        '0x89': {
          contract: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
          block: 0,
        },
      },
    },
  },

  getters: {
    wbtcPrice(state) {
      return state.wbtcPrice
        || localStorage.getItem('wbtc_price')
        || 0;
    },

    gasPrice(state) {
      const double = state.gasPrice
        ? new Decimal(state.gasPrice).mul(1.7).toFixed(0)
        : '800';
      return web3Utils.toWei(double, 'gwei');
    },

    web3Instance(state) {
      return async (externalRpc = false) => {
        const { ethereum } = window;
        const provider = chooseProvider(ethereum);
        if (provider) {
          const chainId = await provider.request({ method: 'eth_chainId' });
          const network = state.networkOptions
            .filter((item) => item.chainId === chainId)[0];

          if (network && !externalRpc) {
            return new Web3(provider);
          } else if (network && externalRpc) {
            return new Web3(network.rpcUrls[0]);
          }
          return new Web3(getProvider(state.networkOptions[0].rpcUrls[0]));
        }
        return new Web3(getProvider(state.networkOptions[0].rpcUrls[0]));
      };
    },

    getSelectedNetwork(state) {
      const selected = state.networkOptions
        .filter((item) => item.chainId === state.selectedNetwork)[0];
      if (selected) {
        return selected;
      }
      return state.unsupportedNetwork;
    },

    derivativeRoot(state) {
      return state.contracts.derivativeRoot[state.networkContract];
    },

    factoryContract(state) {
      return state.contracts.factoryContract[state.networkContract];
    },

    nativePriceContract(state) {
      return state.contracts.nativePriceContract[state.networkContract];
    },

    nativeWithdrawalContract(state) {
      return state.contracts.nativeWithdrawalContract[state.networkContract];
    },

    settingsRetrieverContract(state) {
      return state.contracts.settingsRetrieverContract[state.networkContract];
    },

    verifyContract(state) {
      return state.contracts.verifyContract[state.networkContract];
    },

    wrappedWbtcContract(state) {
      return state.contracts.wrappedWbtcContract[state.networkContract];
    },

    wBtcContract(state) {
      return state.contracts.wBtcContract[state.networkContract];
    },
  },

  mutations: {
    setWbtcPrice(state, value) {
      state.wbtcPrice = value;
      if (value) {
        const time = Math.round(Date.now() / 1000);
        localStorage.setItem('wbtc_price', value);
        localStorage.setItem('wbtc_price_cache_time', time.toString());
      }
    },

    setGasPrice(state, value) {
      state.gasPrice = value;
    },

    changeLocale(state, value) {
      state.locale = value;
      localStorage.setItem('locale', value);
    },

    setTrigger(state) {
      state.trigger += 1;
    },

    setNetwork(state, value) {
      const isHex = web3Utils.isHexStrict(value);
      if (isHex) {
        state.selectedNetwork = value;
      } else {
        state.selectedNetwork = web3Utils.toHex(value);
      }
    },

    setNetworkContract(state, value) {
      const isHex = web3Utils.isHexStrict(value);
      if (isHex) {
        state.networkContract = value;
      } else {
        state.networkContract = web3Utils.toHex(value);
      }
    },
  },

  actions: {
    async web3Instance(context, externalRpc) {
      const web3 = await context.getters.web3Instance(externalRpc);
      web3.eth.transactionBlockTimeout = 300;
      web3.eth.transactionPollingTimeout = 1200;
      return web3;
    },

    async networkChange(context, networkOut) {
      const network = JSON.parse(JSON.stringify(networkOut));
      const { ethereum } = window;
      const provider = chooseProvider(ethereum);
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: network.chainId }],
        });
      } catch (e) {
        if (e.code === 4902) {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: network.chainName,
                chainId: network.chainId,
                nativeCurrency: {
                  name: network.nativeCurrency.name,
                  decimals: network.nativeCurrency.decimals,
                  symbol: network.nativeCurrency.symbol,
                },
                rpcUrls: network.rpcUrls,
                blockExplorerUrls: network.blockExplorerUrls,
              },
            ],
          });
        }
        throw e;
      }
    },

    async getWbtcPriceWithCache(context) {
      const wbtcPriceCacheTime = localStorage.getItem('wbtc_price_cache_time');

      if (wbtcPriceCacheTime) {
        const now = Math.round(Date.now() / 1000);
        const diff = now - Number(wbtcPriceCacheTime);

        if (diff > context.state.wbtcPriceCacheExpired) {
          await context.dispatch('getWbtcPrice');
        }
      } else {
        await context.dispatch('getWbtcPrice');
      }
    },

    async getWbtcPrice(context) {
      const api = 'https://api.coingecko.com/api/v3/simple/token_price';
      const network = 'polygon-pos';
      const wbtcContract = '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6';
      const vsCurrency = 'usd';
      const apiUrl = `${api}/${network}?contract_addresses=${wbtcContract}&vs_currencies=${vsCurrency}`;

      try {
        const response = await fetch(apiUrl);
        if (response.ok) {
          const result = await response.json();
          const price = result[wbtcContract].usd;

          if (price) {
            context.commit('setWbtcPrice', price);
          } else {
            context.commit('setWbtcPrice', 0);
          }
        } else {
          context.commit('setWbtcPrice', 0);
        }
      } catch (e) {
        context.commit('setWbtcPrice', 0);
        console.error(e);
        throw e;
      }
    },

    async getGasPrice(context) {
      try {
        const web3 = await context.getters.web3Instance();
        const gasPrice = await web3.eth.getGasPrice();
        const gasPriceGwei = web3Utils.fromWei(gasPrice, 'gwei');
        context.commit('setGasPrice', gasPriceGwei);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
