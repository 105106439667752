<template>
  <div
    class="wrapper"
    tabindex="0"
  >
    <slot />
    <div
      class="tooltip"
    >
      <span
        class="text"
        v-html="text"
      ></span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  @apply inline relative cursor-pointer transition-all duration-100;
}

.wrapper:hover .tooltip {
  display: block;
}
.wrapper:focus .tooltip {
  display: block;
}

.tooltip {
  @apply absolute bg-white bottom-24px left-1/5 rounded-md shadow-primary p-8px;
  border: 1px solid theme('colors.border-color');
  @apply z-99 min-w-240px text-xs text-black;
  display: none;
}
</style>
