<template>
  <div class="flex flex-col gap-6">
    <div class="text-gray-600 font-600 text-lg">
      {{ $t('Collateral Checking') }}
    </div>
    <div>
      <div class="mb-4">
        <span>{{ $t('You have') }}</span>
        <span class="font-600 text-lg">{{ ` ${collateralBalance} ${collateralSymbol} ` }}</span>
        <span>{{ $t('tokens that you can use as collateral liquidity for your derivative contract.') }}</span>
      </div>
      <div>
        {{ $t('Make sure it\'s enough for you before create a contract!') }}
      </div>
    </div>
    <div class="grid grid-cols-[1fr,1fr] gap-4">
      <button
        class="button button-primary p-2 w-full"
        @click="getMoreHandler"
      >
        {{ $t('Get more') }}
      </button>
      <button
        class="button button-primary p-2 w-full"
        :disabled="!isCollateralBalance"
        @click="nextHandler"
      >
        {{ $t('Next') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('create', {
      collateralSymbol: 'collateralSymbol',
      collateralBalance: 'collateralBalance',
    }),

    isCollateralBalance() {
      return !!Number(this.collateralBalance);
    },
  },

  methods: {
    ...mapMutations('create', [
      'setCheckingCollateralStage',
      'setIsCollateralEnough',
    ]),

    getMoreHandler() {
      this.setCheckingCollateralStage('add');
    },

    nextHandler() {
      this.setIsCollateralEnough(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center justify-center rounded-r-md bg-gray-200;
}
</style>
