<template>
  <div class="w-440px <phone-lg:(max-w-340px w-full)">
    <div class="text-gray-600 font-600 text-lg mb-4">
      {{ $t('Change Host') }}
    </div>
    <form
      @submit.prevent="changeHostHandler"
      class="flex flex-col gap-6"
    >
      <div v-if="pending">
        <ui-pending-preloader />
      </div>
      <div v-else class="flex flex-col gap-6">
        <div>
          {{ $t('change_host_modal_text') }}
        </div>
        <div class="flex flex-col gap-2">
          <label
            for="newHost"
            class="self-start font-600 text-sm"
          >
            {{ $t('New Host (address)') }}
          </label>
          <input
            required
            v-model="newHost"
            id="newHost"
            class="input input-blue"
            type="text"
          />
        </div>
      </div>
      <ui-button-loading
        class="button button-primary px-3 py-2 w-full"
        type="submit"
        :loading="pending"
      >
        {{ $t('Change Host') }}
      </ui-button-loading>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default {
  components: {
    UiPendingPreloader,
    UiButtonLoading,
  },

  data() {
    return {
      newHost: '',
    };
  },

  computed: {
    ...mapState('contract', {
      pending: 'pending',
    }),

    contractAddress() {
      return this.$route.params.contract_address;
    },
  },

  methods: {
    ...mapActions('contract', ['changeHost']),

    changeHostHandler() {
      this.changeHost({
        contractAddress: this.contractAddress,
        newHost: this.newHost,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
