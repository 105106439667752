<template>
  <div>
    <ui-steps
      :steps="4"
      :active-step="activeStep"
      @set-step="setStepHandler"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import UiSteps from '@/components/ui/UiSteps.vue';

export default {
  components: {
    UiSteps,
  },

  computed: {
    ...mapState('create', {
      activeStep: 'activeStep',
    }),
  },

  methods: {
    ...mapMutations('create', ['setActiveStep']),

    setStepHandler(step) {
      this.setActiveStep(step);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
