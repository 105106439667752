<template>
  <div>
    <div>
      <ui-pending-preloader v-if="pending" />
      <div
        v-else
        class="w-full h-86px flex items-center justify-center font-600"
      >
        {{ $t('Approve spend') }}
      </div>
    </div>
    <steps-wrapper />
    <div class="mt-6">
      <ui-button-loading
        class="button button-primary px-3 py-2 w-full"
        :loading="loadingStepApproveBtn"
        @click="approveHandler"
      >
        {{ $t('Approve') }}
      </ui-button-loading>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';
import StepsWrapper from '@/components/wrap/StepsWrapper.vue';

export default {
  components: {
    UiButtonLoading,
    UiPendingPreloader,
    StepsWrapper,
  },

  computed: {
    ...mapState('wrap', {
      pending: 'pending',
      loadingStepApproveBtn: 'loadingStepApproveBtn',
    }),
  },

  methods: {
    ...mapActions('wrap', ['approveSpend']),

    approveHandler() {
      this.approveSpend();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
