<template>
  <div>
    <div class="card-settings-title">
      <div class="flex items-center gap-2">
        <span>{{ $t('Withdrawal fee') }}</span>
        <span class="font-600 text-gray-600">
          ({{ $t('before locktime ending') }})
        </span>
      </div>
    </div>
    <div class="card-settings-body flex flex-col gap-6">
      <div class="grid grid-cols-[1fr,84px] gap-4">
        <div class="form-group">
          <div class="flex items-center gap-2">
            <label
              for="withdrawalFee"
              class="self-start flex items-center gap-2"
            >
              <span>{{ $t('Withdrawal fee') }}</span>
              <span class="text-gray-600">({{ $t('%') }})</span>
            </label>
            <ui-tooltip
              class="!flex"
              :text="$t('tooltip_withdrawal_fee')"
            >
              <feather-icon
                class="opacity-70 hover:opacity-100"
                type="help-circle"
                size="18"
              />
            </ui-tooltip>
          </div>
          <input
            required
            min="0"
            max="100"
            step="1"
            v-model="settings10"
            id="withdrawalFee"
            class="input input-blue w-full"
            type="number"
          />
        </div>
        <div class="form-group justify-self-end">
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['10']"
              @switched="setFixsHandler(10)"
            />
            <span class="inline-block w-36px">
              {{ fixs['10'] }}
            </span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-[1fr,84px] gap-4">
        <div class="form-group">
          <div class="flex items-center gap-2">
            <label
              for="minimalWithdrawalFee"
              class="self-start flex items-center gap-2"
            >
              <span>{{ $t('Users minimal fee') }}</span>
              <span class="text-gray-600">({{ $t('Token count') }})</span>
            </label>
            <ui-tooltip
              class="!flex"
              :text="$t('tooltip_min_withdrawal_fee')"
            >
              <feather-icon
                class="opacity-70 hover:opacity-100"
                type="help-circle"
                size="18"
              />
            </ui-tooltip>
          </div>
          <div class="flex items-center">
            <input
              required
              min="0"
              step="any"
              v-model="settings12"
              id="minimalWithdrawalFee"
              class="input input-blue w-full !rounded-r-none"
              type="number"
            />
            <div class="symbol">
              {{ collateralSymbol }}
            </div>
          </div>
        </div>
        <div class="form-group justify-self-end">
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['12']"
              @switched="setFixsHandler(12)"
            />
            <span class="inline-block w-36px">
              {{ fixs['12'] }}
            </span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-[1fr,84px] gap-4">
        <div class="form-group">
          <div class="flex items-center gap-2">
            <label
              for="ownerWithdrawalFee"
              class="self-start flex items-center gap-2"
            >
              <span>{{ $t('Owners percent of users fee') }}</span>
              <span class="text-gray-600">({{ $t('%') }})</span>
            </label>
            <ui-tooltip
              class="!flex"
              :text="$t('tooltip_withdrawal_owners_fee')"
            >
              <feather-icon
                class="opacity-70 hover:opacity-100"
                type="help-circle"
                size="18"
              />
            </ui-tooltip>
          </div>
          <input
            required
            min="0"
            max="100"
            step="1"
            v-model="settings13"
            id="ownerWithdrawalFee"
            class="input input-blue w-full"
            type="number"
          />
        </div>
        <div class="form-group justify-self-end">
          <span>
            {{ $t('Fixed')  }}
          </span>
          <div class="h-42px flex items-center gap-2">
            <ui-switcher
              :checked="fixs['13']"
              @switched="setFixsHandler(13)"
            />
            <span class="inline-block w-36px">
              {{ fixs['13'] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import UiSwitcher from '@/components/ui/UiSwitcher.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

export default {
  components: {
    UiSwitcher,
    UiTooltip,
  },

  computed: {
    ...mapState('createsettings', {
      settings: 'settings',
      fixs: 'fixs',
    }),
    ...mapState('create', {
      collateralSymbol: 'collateralSymbol',
    }),

    settings10: {
      get() {
        return this.settings['10'];
      },
      set(value) {
        this.setSettings({ value, i: 10 });
      },
    },

    settings12: {
      get() {
        return this.settings['12'];
      },
      set(value) {
        this.setSettings({ value, i: 12 });
      },
    },

    settings13: {
      get() {
        return this.settings['13'];
      },
      set(value) {
        this.setSettings({ value, i: 13 });
      },
    },
  },

  methods: {
    ...mapMutations('createsettings', [
      'setSettings',
    ]),
    ...mapMutations('createsettings', [
      'setFixs',
    ]),

    setFixsHandler(i) {
      this.setFixs({ value: !this.fixs[i], i });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  @apply flex flex-col gap-2;
}

.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center rounded-r-md bg-gray-200;
}
</style>
