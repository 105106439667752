<template>
  <div
    v-if="loading"
    class="loading"
    :class="bgColor"
  >
    <feather-icon
      type="loader"
      :size="size"
      class="animate-spin text-gray-500"
      :class="color"
    />
  </div>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
    },
    size: {
      type: String,
      default: '18',
    },
    color: {
      type: String,
      default: 'text-gray-500',
    },
    bgColor: {
      type: String,
      default: 'loading-bg-blur',
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-bg-blur {
  background: rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(1px);
}
</style>
