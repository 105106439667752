<template>
  <div class="card">
    <div class="mb-20px">
      <ui-skeleton-box height="32px" width="100%" />
    </div>
    <div class="flex flex-col gap-24px">
      <ui-skeleton-box height="28px" width="100%" />
      <ui-skeleton-box height="28px" width="100%" />
      <ui-skeleton-box height="28px" width="100%" />
    </div>
  </div>
</template>

<script>
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';

export default {
  components: {
    UiSkeletonBox,
  },
};
</script>

<style lang="scss" scoped>
</style>
