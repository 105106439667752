<template>
  <div
    v-click-out="closeAccountDropdownMenu"
  >
    <button
      v-if="!address"
      class="button button-primary connect-button"
      @click="connectHandler"
    >
      <img src="@/assets/img/wallet.svg" class="w-24px text-white" alt="wallet">
      <span>{{ $t('Connect Wallet') }}</span>
    </button>
    <div
      v-if="address"
      aria-hidden="true"
      class="relative"
      @click="showAccountDropdownMenu"
    >
      <div
        class="short-address-wrapper"
      >
        <feather-icon type="user" size="18" />
        <span>{{ shortAddress }}</span>
        <feather-icon
          type="chevron-down"
          size="18"
          :class="{'rotate': isShowAccountDropdownMenu}"
        />
      </div>
      <account-dropdown-menu
        v-if="isShowAccountDropdownMenu"
        @close-account-dropdown-menu="closeAccountDropdownMenu"
      />
    </div>
  </div>
  <ui-modal
    v-model="show"
  >
    <install-metamask />
  </ui-modal>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import UiModal from '@/components/ui/UiModal.vue';
import InstallMetamask from '@/components/InstallMetamask.vue';
import AccountDropdownMenu from '@/components/AccountDropdownMenu.vue';

export default {
  components: {
    UiModal,
    InstallMetamask,
    AccountDropdownMenu,
  },

  data() {
    return {
      isShowAccountDropdownMenu: false,
    };
  },

  computed: {
    show: {
      get() {
        return this.isShowModal;
      },

      set(value) {
        this.showModal(value);
      },
    },

    ...mapState('metamask', {
      address: 'address',
      network: 'network',
      balance: 'balance',
      symbol: 'symbol',
      isShowModal: 'isShowModal',
    }),
    ...mapGetters('metamask', {
      shortAddress: 'shortAddress',
    }),
  },

  watch: {
    address() {
      this.getAccountBalance();
    },
    network() {
      this.getAccountBalance();
    },
  },

  async mounted() {
    await this.initialize();
  },

  methods: {
    connectHandler() {
      this.connect();
    },

    showAccountDropdownMenu() {
      this.isShowAccountDropdownMenu = !this.isShowAccountDropdownMenu;
    },

    closeAccountDropdownMenu() {
      this.isShowAccountDropdownMenu = false;
    },

    ...mapMutations('metamask', ['showModal']),
    ...mapActions('metamask', [
      'initialize',
      'getAccountBalance',
      'connect',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.short-address-wrapper {
  @apply border-solid border-2 border-primary-500 rounded-lg gap-2 text-sm font-600;
  @apply flex items-center h-36px pr-8px pl-10px cursor-pointer;

  &:hover {
    @apply text-primary-600;
  }
}
.rotate {
  transform: rotate(-180deg);
}

.connect-button {
  @apply h-36px pl-8px pr-10px flex items-center gap-1;
}
</style>
