<template>
  <teleport to="body">
    <transition name="modal">
      <div
        aria-hidden="true"
        v-show="modelValue"
        class="modal-backdrop"
        @click="backdropHandler"
      >
        <div
          class="modal-wrapper"
        >
          <div
            class="modal-container"
            role="dialog"
            aria-modal="true"
            @click.stop
          >
            <div class="flex mb-2 justify-end">
              <feather-icon
                type="x"
                class="icon-close"
                size="18"
                @click="closeHandler"
              />
            </div>
            <div>
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
    },

    maskClosable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue', 'close'],

  methods: {
    closeHandler() {
      this.$emit('update:modelValue', false);
      this.$emit('close');
    },

    backdropHandler() {
      if (this.maskClosable) {
        this.closeHandler();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  @apply fixed z-99999 inset-0 overflow-y-auto bg-black bg-opacity-50;
}

.modal-wrapper {
  @apply flex items-start justify-center min-h-screen pt-[10%] text-center;
}

.modal-container {
  @apply bg-white rounded-md overflow-hidden shadow-md p-4 pb-8 max-w-480px m-2;
}

.icon-close {
  @apply text-gray-500 cursor-pointer hover:text-dark-900;
}

.modal-leave-active,
.modal-enter-active {
  @apply transition ease-out duration-200 transform;
}

.modal-enter-to,
.modal-leave-from {
  @apply opacity-100;
}

.modal-enter-from,
.modal-leave-to {
  @apply opacity-0;
}
</style>
