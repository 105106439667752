<template>
  <div>
    <router-link
      to="/"
      class="text-2xl font-600 whitespace-nowrap flex items-center gap-2"
    >
      <img src="@/assets/img/logo.svg" alt="metamask">
      <span>DeWrap</span>
    </router-link>
  </div>
</template>

<script>
export default {
};
</script>
