<template>
  <div class="w-340px <phone-lg:(max-w-340px w-full)">
    <collateral-notice v-if="stage === 'notice'" />
    <collateral-add v-if="stage === 'add'" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CollateralNotice from '@/components/create/CollateralNotice.vue';
import CollateralAdd from '@/components/create/CollateralAdd.vue';

export default {
  components: {
    CollateralNotice,
    CollateralAdd,
  },

  computed: {
    ...mapState('create', {
      stage: 'checkingCollateralStage',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
