<template>
  <div class="w-340px <phone-lg:(max-w-340px w-full)">
    <div class="text-gray-600 font-600 text-lg mb-4">
      {{ $t('Verify Contract') }}
    </div>
    <form
      @submit.prevent="verifyHandler"
      class="flex flex-col gap-4"
    >
      <div v-if="pending">
        <ui-pending-preloader />
      </div>
      <div v-else class="flex flex-col gap-4 mb-2">
        <div class="font-600 flex gap-2 justify-center">
          <div>{{ $t('Verification Cost') }}:</div>
          <div>{{ `${verificationCost} ${nativeCurrencySymbol}` }}</div>
        </div>
        <div class="text-sm text-gray-600 font-600">
          <div>
            {{ $t('verify_contract_modal_text') }}
          </div>
        </div>
      </div>
      <ui-button-loading
        class="button button-primary px-3 py-2 w-full"
        type="submit"
        :loading="confirmBtnLoading"
      >
        {{ $t('Confirm') }}
      </ui-button-loading>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';

export default {
  components: {
    UiButtonLoading,
    UiPendingPreloader,
  },

  props: {
    contractAddress: {
      type: String,
    },
  },

  computed: {
    ...mapState('verify', {
      pending: 'pending',
      confirmBtnLoading: 'confirmBtnLoading',
      verificationCost: 'verificationCost',
    }),
    ...mapState('metamask', {
      nativeCurrencySymbol: 'symbol',
    }),
  },

  methods: {
    ...mapActions('verify', ['verifyContract']),

    verifyHandler() {
      this.verifyContract(this.contractAddress);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
