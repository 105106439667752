<template>
  <form
    @submit.prevent="addLiquidityHandler"
  >
    <ui-pending-preloader v-if="pending" />
    <div v-else class="my-4">
      <div>
        {{ $t('Add') }}
        <span class="font-600">{{ collateralSymbol }}</span>
        {{ $t('collateral token as liquidity to your') }}
      </div>
      <div class="mb-6">
        <div class="font-600">{{ $t('Created contract') }}</div>
        <div class="flex items-center justify-center gap-2 text-gray-500 font-600">
          <span>{{ shortAddress(currentContract, 8, -6) }}</span>
          <ui-copy-to-clipboard :text-to-copy="currentContract" />
        </div>
      </div>
      <div class="text-left mb-2 font-600 text-sm">
        {{ $t('Available') }}:
        {{ collateralBalance }}
        {{ collateralSymbol }}
      </div>
      <div class="flex items-center">
        <input
          required
          step="any"
          min="0"
          type="number"
          v-model="liquidityValue"
          name="collateralDeposit"
          class="input input-blue w-full !rounded-r-none"
        />
        <div class="symbol">
          {{ collateralSymbol }}
        </div>
      </div>
    </div>
    <steps-wrapper />
    <div class="mt-6">
      <ui-button-loading
        type="submit"
        :loading="loadingAddLiquidityBtn"
        class="button button-primary px-3 py-2 w-full"
      >
        {{ $t('Add Liquidity') }}
      </ui-button-loading>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { shortAddress } from '@/utils';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import StepsWrapper from '@/components/create/StepsWrapper.vue';

export default {
  components: {
    StepsWrapper,
    UiPendingPreloader,
    UiButtonLoading,
    UiCopyToClipboard,
  },

  computed: {
    ...mapState('create', {
      pending: 'pending',
      loadingAddLiquidityBtn: 'loadingAddLiquidityBtn',
      collateralSymbol: 'collateralSymbol',
      collateralBalance: 'collateralBalance',
    }),
    ...mapGetters('create', {
      currentContract: 'currentContract',
    }),
    ...mapState('createsettings', {
      liquidity: 'liquidity',
    }),

    liquidityValue: {
      get() {
        return this.liquidity;
      },
      set(value) {
        this.setLiquidity(value);
      },
    },
  },

  unmounted() {
    this.setLiquidity('');
  },

  methods: {
    ...mapActions('create', ['addLiquidity']),
    ...mapMutations('createsettings', ['setLiquidity']),
    shortAddress,

    addLiquidityHandler() {
      this.addLiquidity();
    },
  },
};
</script>

<style lang="scss" scoped>
.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center justify-center rounded-r-md bg-gray-200;
}
</style>
