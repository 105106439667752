import { createApp } from 'vue';
import Toast, { useToast } from 'vue-toastification';
import VueFeather from 'vue-feather';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';
import clickOutside from './directives/clickOutside';
import regexpValidate from './directives/regexpValidate';
// eslint-disable-next-line import/no-unresolved
import 'windi.css';
import 'vue-toastification/dist/index.css';

const toastOptions = {
  transition: 'Vue-Toastification__fade',
  timeout: false,
  draggable: false,
  closeOnClick: false,
  hideProgressBar: true,
  icon: true,
  shareAppContext: true,
  toastClassName: 'toast__class',
  bodyClassName: ['toast__class-body'],
};

const app = createApp(App);
app.use(i18n);
app.use(Toast, toastOptions);
app.use(store);
app.use(router);
app.component('feather-icon', VueFeather);
app.directive('clickOut', clickOutside);
app.directive('regexp-validate', regexpValidate);
app.mount('#app');

app.config.globalProperties.$toast = useToast();
