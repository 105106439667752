<template>
  <div>
    <main-header
      :is-show-sidebar="isShowSidebar"
      @show-sidebar="showSidebarHandler"
    />
    <sidebar-menu
      :is-show-sidebar="isShowSidebar"
      @close-sidebar="closeSidebar"
    />
    <div class="relative">
      <div class="app-container">
        <nav-tabs v-if="showProfileNavTabs" />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import SidebarMenu from '@/components/SidebarMenu.vue';
import NavTabs from '@/components/profile/NavTabs.vue';

export default {
  components: {
    MainHeader,
    SidebarMenu,
    NavTabs,
  },

  data() {
    return {
      isShowSidebar: false,
    };
  },

  computed: {
    showProfileNavTabs() {
      const matches = ['verified', 'unverified'];
      const path = this.$route.path.split('/')[4];
      return matches.some((match) => match === path);
    },
  },

  methods: {
    showSidebarHandler() {
      this.isShowSidebar = !this.isShowSidebar;
    },

    closeSidebar() {
      this.isShowSidebar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-warning {
  @apply absolute w-full h-full min-h-screen top-0 left-0;
  background: rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(1px);
}
</style>
