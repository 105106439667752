<template>
  <div class="card-settings">
    <div class="text-center text-lg uppercase">
      {{ $t('Main settings') }}
    </div>
    <div class="card-settings-body flex flex-col gap-6">
      <div class="form-group relative">
        <div class="flex items-center gap-2">
          <label
            for="collateral"
            class="self-start flex items-center gap-2"
          >
            <span>{{ $t('Collateral') }}</span>
            <span class="text-gray-600">({{ $t('Derivative token referral') }})</span>
          </label>
          <ui-tooltip
            class="!flex"
            :text="tooltipText"
          >
            <feather-icon
              class="opacity-70 hover:opacity-100"
              type="help-circle"
              size="18"
            />
          </ui-tooltip>
        </div>
        <input
          required
          v-model="collateralAddress"
          id="collateral"
          class="input"
          :class="{ 'invalid': invalid }"
          type="text"
          placeholder="0xaBc...dEf"
          @blur="collateralAddressHandler"
        />
        <div
          v-if="invalid"
          class="absolute -bottom-20px text-xs text-red-500"
        >
          {{ $t('Invalid contract') }}
        </div>
      </div>
      <div class="form-group">
        <label
          for="owner"
          class="self-start"
        >
          {{ $t('Owner') }}
        </label>
        <input
          required
          readonly
          v-model="ownerAddress"
          id="owner"
          class="input input-blue"
          type="text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import web3Utils from 'web3-utils';

export default {
  components: {
    UiTooltip,
  },

  data() {
    return {
      invalid: false,
    };
  },

  computed: {
    ...mapState('createsettings', [
      'collateral',
      'owner',
    ]),
    ...mapState('metamask', {
      address: 'address',
      symbol: 'symbol',
    }),
    ...mapState('app', ['networkContract']),
    ...mapGetters('app', ['derivativeRoot']),
    ...mapState('create', {
      collateralSymbol: 'collateralSymbol',
    }),

    collateralAddress: {
      get() {
        return this.collateral;
      },
      set(value) {
        this.setCollateral(value);
      },
    },

    ownerAddress: {
      get() {
        return this.owner;
      },
      set(value) {
        this.setOwner(value);
      },
    },

    tooltipText() {
      return `This token is used to set the initial liquidity of your token. If you do not have set a value then <br> ${this.collateralSymbol ? this.collateralSymbol.bold() : ''} token contract ${this.derivativeRoot?.contract.bold()} is used by default as collateral`;
    },
  },

  watch: {
    address() {
      this.ownerAddress = this.address;
      this.setReferral();
    },
  },

  created() {
    this.ownerAddress = this.address;
    this.setReferral();
  },

  methods: {
    ...mapMutations('createsettings', [
      'setCollateral',
      'setOwner',
    ]),
    ...mapActions('create', ['getCollateralBalance']),

    setReferral() {
      if (this.$route.query.ref) {
        this.collateralAddress = this.$route.query.ref;
      } else {
        this.collateralAddress = this.derivativeRoot?.contract;
      }

      if (this.collateralAddress) {
        this.getCollateralBalance();
      }
    },

    async collateralAddressHandler() {
      this.invalid = false;
      if (this.collateralAddress) {
        if (web3Utils.isAddress(this.collateralAddress)) {
          try {
            await this.getCollateralBalance();
          } catch (e) {
            this.invalid = true;
          }
        } else {
          this.invalid = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  @apply flex flex-col gap-2;
}
.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center justify-center rounded-r-md bg-gray-200;
}
.invalid {
  @apply border-red-500 #{!important};
}
</style>
