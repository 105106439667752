function regexpValidate(el, binding) {
  const regex = new RegExp(el.pattern);
  if (regex.test(el.value)) {
    el.setCustomValidity('');
  } else {
    el.setCustomValidity(binding.value);
  }
}

export default regexpValidate;
