<template>
  <component :is="resolveLayout">
    <router-view :key="trigger" />
  </component>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export default {
  components: {
    DefaultLayout,
  },

  computed: {
    ...mapState('app', {
      trigger: 'trigger',
    }),

    resolveLayout() {
      return this.$route.meta.layout || 'default-layout';
    },
  },

  watch: {
    trigger() {
      this.getWbtcPriceWithCache();
    },
  },

  created() {
    this.getWbtcPriceWithCache();
  },

  methods: {
    ...mapActions('app', ['getWbtcPriceWithCache']),
  },
};
</script>

<style lang="scss">
#app {
  height: 100vh;
}
body {}
*, ::after, ::before {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
</style>
