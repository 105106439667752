<template>
  <button
    class="relative"
    :disabled="loading || disabled"
  >
    <span :class="{'opacity-0': loading }">
      <slot></slot>
    </span>
    <span
      v-if="loading"
      class="spinner"
    >
      <feather-icon
        type="loader"
        size="24"
        class="animate-spin text-white"
      />
    </span>
  </button>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
</style>
