import web3Utils from 'web3-utils';
import { useToast } from 'vue-toastification';
import { delay } from '@/utils';
import router from '@/router';
import VerifiedAddressesAbi from '@/abis/VerifiedAddresses.json';

const toast = useToast();

export default {
  namespaced: true,

  state: {
    verificationCostRaw: '',
    verificationCost: '',

    isShowModal: false,
    pending: false,
    verifyBtnLoading: false,
    confirmBtnLoading: false,
  },

  getters: {

  },

  mutations: {
    setVerificationCostRaw(state, value) {
      state.verificationCostRaw = value;
    },

    setVerificationCost(state, value) {
      state.verificationCost = value;
    },

    showModal(state, value) {
      state.isShowModal = value;
    },

    setPending(state, value) {
      state.pending = value;
    },

    setVerifyBtnLoading(state, value) {
      state.verifyBtnLoading = value;
    },

    setConfirmBtnLoading(state, value) {
      state.confirmBtnLoading = value;
    },
  },

  actions: {
    async getVerificationCost(context) {
      try {
        context.commit('setVerifyBtnLoading', true);

        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          VerifiedAddressesAbi,
          context.rootGetters['app/verifyContract'].contract,
        );

        const verificationCostRaw = await contract.methods.payValue().call();
        const verificationCost = web3Utils.fromWei(verificationCostRaw);
        context.commit('setVerificationCostRaw', verificationCostRaw);
        context.commit('setVerificationCost', verificationCost);
        await delay(200);
        context.commit('showModal', true);
        context.commit('setVerifyBtnLoading', false);
      } catch (e) {
        context.commit('setVerifyBtnLoading', false);
        console.error(e);
        throw e;
      }
    },

    async verifyContract(context, address) {
      try {
        context.commit('setConfirmBtnLoading', true);
        context.commit('setPending', true);

        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          VerifiedAddressesAbi,
          context.rootGetters['app/verifyContract'].contract,
        );

        await contract.methods.verifyContract(address).send({
          from: context.rootState.metamask.address,
          gasPrice: context.rootGetters['app/gasPrice'],
          value: context.state.verificationCostRaw,
        })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', async (receipt) => {
            console.log(receipt);
            await context.dispatch('metamask/getAccountBalance', null, { root: true });

            context.commit('setPending', false);
            context.commit('setConfirmBtnLoading', false);
            context.commit('showModal', false);

            if (router.currentRoute.value.name === 'contract') {
              await context.dispatch('contract/isContractVerified', address, { root: true });
            }

            if (router.currentRoute.value.name === 'unverified') {
              context.commit('profile/setVerifiedListLoaded', false, { root: true });
              await router.push({
                name: 'verified',
                params: { address: context.rootState.metamask.address },
              });
            }

            toast.success('Сontract has been successfully verified', {
              timeout: 3000,
            });
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setPending', false);
            context.commit('setConfirmBtnLoading', false);
            context.commit('showModal', false);
            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        context.commit('setPending', false);
        context.commit('setConfirmBtnLoading', false);
        context.commit('showModal', false);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async isVerified(context, address) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          VerifiedAddressesAbi,
          context.rootGetters['app/verifyContract'].contract,
        );

        return await contract.methods.verifiedAddresses(address).call();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
