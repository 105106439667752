<template>
  <div class="steps">
    <div
      class="flex items-center justify-between"
      v-for="step in steps"
      :key="step"
    >
      <div
        aria-hidden="true"
        class="step cursor-pointer"
        :class="{
          'step-active': activeStep === step,
          'step-leave': activeStep > step
        }"
        @click="setStepHandler(step)"
      >
        <div
          v-if="activeStep <= step"
          class="font-600"
        >
          {{ step }}
        </div>
        <div v-else>
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 11.17L1.83016 7.00003L0.410156 8.41003L6.00016 14L18.0002 2.00003L16.5902 0.590027L6.00016 11.17Z" fill="currentColor"/>
          </svg>
        </div>
      </div>
      <div v-if="step !== steps" class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
    },

    activeStep: {
      type: Number,
    },
  },

  emits: ['setStep'],

  methods: {
    setStepHandler(step) {
      this.$emit('setStep', step);
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
}

.step {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: theme('colors.gray.100');
  color: theme('colors.gray.600');
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  width: 16px;
  height: 1px;
  background-color: theme('colors.gray.300');
  margin-left: 5px;
}

.step-active {
  background-color: theme('colors.primary.500');
  color: #fff;
}

.step-leave {
  border: 2px solid theme('colors.primary.500');
  background-color: #fff;
  color: theme('colors.primary.500');
  box-sizing: border-box;
}
</style>
