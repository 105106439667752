import router from '@/router';
import { getData, getNetwork, getBalance, chooseProvider } from '@/utils';

const { ethereum } = window;
const provider = chooseProvider(ethereum);

export default {
  namespaced: true,

  state: {
    address: '',
    balance: '',
    network: '',
    chainId: '',
    symbol: '',
    explorerLink: '',
    explorerName: '',
    isMetaMaskInstall: false,
    isConnected: false,
    isShowModal: false,
  },

  getters: {
    network(state) {
      return state.network.toLowerCase();
    },

    shortAddress(state) {
      if (state.address) {
        return `${state.address.slice(0, 6)}...${state.address.slice(-4)}`;
      }
      return '';
    },
  },

  mutations: {
    metamaskInstall(state, bool) {
      state.isMetaMaskInstall = bool;
    },

    setBalance(state, balance) {
      state.balance = balance;
    },

    setAddress(state, address) {
      state.address = address;
    },

    setNetwork(state, net) {
      state.network = net;
    },

    setChainId(state, id) {
      state.chainId = id;
    },

    setSymbol(state, symbol) {
      state.symbol = symbol;
    },

    setExplorerLink(state, value) {
      state.explorerLink = value;
    },

    setExplorerName(state, value) {
      state.explorerName = value;
    },

    connected(state, bool) {
      state.isConnected = bool;
    },

    showModal(state, bool) {
      state.isShowModal = bool;
    },
  },

  actions: {
    async initialize({ commit, dispatch }) {
      if (provider && provider.isMetaMask) {
        console.info('Metamask installed!');
        commit('metamaskInstall', true);

        dispatch('getAccountData');
        dispatch('chainChanged');
        dispatch('accountsChanged');
      } else {
        console.warn('Metamask not installed!');
        commit('metamaskInstall', false);
      }
    },

    async getAccountBalance({ state, commit }) {
      if (state.address !== '') {
        const balance = await getBalance(provider, state.address);
        const shortBalance = balance.toString().slice(0, (balance.indexOf('.')) + 5);
        commit('setBalance', shortBalance);
      } else {
        this.balance = '';
        commit('setBalance', '');
      }
    },

    async getAccountData({ commit }) {
      const result = await getData(provider);

      if (result) {
        const [
          account,
          network,
          symbol,
          chainId,
          explorerLink,
          explorerName,
        ] = result;

        commit('setAddress', account);
        commit('setNetwork', network);
        commit('setChainId', chainId);
        commit('setSymbol', symbol);
        commit('setExplorerLink', explorerLink);
        commit('setExplorerName', explorerName);
        commit('connected', true);

        commit('app/setNetwork', chainId, { root: true });
        commit('app/setNetworkContract', chainId, { root: true });
      } else {
        commit('connected', false);
      }
    },

    chainChanged({ commit, dispatch, rootState }) {
      provider.on('chainChanged', async (_chainId) => {
        const result = await getNetwork(provider, _chainId);
        const [network, symbol, chainId] = result;

        commit('setNetwork', network);
        commit('setChainId', chainId);
        commit('setSymbol', symbol);

        commit('app/setNetwork', chainId, { root: true });
        commit('app/setNetworkContract', chainId, { root: true });

        dispatch('getAccountData');

        if (router.currentRoute.value.name === 'contract') {
          await router.push({ name: 'home' });
        }

        const isNetworkExists = rootState.app.networkOptions
          .filter((item) => item.chainId === chainId)[0];

        if (isNetworkExists) {
          commit('app/setTrigger', null, { root: true });
          commit('list/setDerivativeListLoaded', false, { root: true });
          commit('list/setUnverifiedDerivativeListLoaded', false, { root: true });
        }
      });
    },

    accountsChanged({ commit }) {
      provider.on('accountsChanged', async (accounts) => {
        if (accounts[0]) {
          commit('setAddress', accounts[0]);
          if (
            router.currentRoute.value.name === 'verified'
            || router.currentRoute.value.name === 'unverified'
            || router.currentRoute.value.name === 'portfolio'
          ) {
            await router.replace({
              name: router.currentRoute.value.name,
              params: { address: accounts[0] },
            });
            commit('app/setTrigger', null, { root: true });
            commit('profile/setVerifiedListLoaded', false, { root: true });
            commit('profile/setUnverifiedListLoaded', false, { root: true });
            commit('profile/setPortfolioListLoaded', false, { root: true });
          }
        } else {
          commit('setAddress', '');
          commit('setNetwork', '');
          commit('setChainId', '');
          commit('setSymbol', '');
          commit('connected', false);
        }
        commit('app/setTrigger', null, { root: true });
      });
    },

    async connect({ state, commit, rootState, dispatch }) {
      if (state.isMetaMaskInstall) {
        const result = await provider.request({ method: 'eth_requestAccounts' });
        const [
          account,
          network,
          symbol,
          chainId,
          explorerLink,
          explorerName,
        ] = await getData(provider, result);

        commit('setAddress', account);
        commit('setNetwork', network);
        commit('setChainId', chainId);
        commit('setSymbol', symbol);
        commit('setExplorerLink', explorerLink);
        commit('setExplorerName', explorerName);
        commit('connected', true);

        const networkSet = rootState.app.networkOptions[0];
        await dispatch('app/networkChange', networkSet, { root: true });
        commit('app/setNetwork', chainId, { root: true });
        commit('app/setNetworkContract', chainId, { root: true });
      } else {
        console.warn('Please install MetaMask!');
        commit('showModal', true);
      }
    },
  },
};
