import web3Utils from 'web3-utils';

export async function getBalance(provider, address) {
  const balance = await provider.request({
    method: 'eth_getBalance',
    params: [address, 'latest'],
  });

  const finalBalance = web3Utils.hexToNumberString(balance);

  return web3Utils.fromWei(finalBalance, 'ether');
}

export async function getNetwork(provider, chainIdOut) {
  let chainIdLocal;

  if (chainIdOut) {
    chainIdLocal = chainIdOut;
  } else {
    const chainId = await provider.request({
      method: 'eth_chainId',
    });

    chainIdLocal = chainId;
  }

  const result = await new Promise((resolve) => {
    switch (chainIdLocal) {
      case '0x1':
        resolve([
          'Mainnet',
          'ETH',
          '0x1',
          'https://etherscan.io',
          'Etherscan',
        ]);
        break;
      case '0x2a':
        resolve([
          'Kovan',
          'KOV',
          '0x2a',
          'https://etherscan.io',
          'Etherscan',
        ]);
        break;
      case '0x4':
        resolve([
          'Rinkeby',
          'RIN',
          '0x4',
          'https://etherscan.io',
          'Etherscan',
        ]);
        break;
      case '0x5':
        resolve([
          'Goerli',
          'GoETH',
          '0x5',
          'https://goerli.etherscan.io',
          'Etherscan',
        ]);
        break;
      case '0x38':
        resolve([
          'BSC',
          'BNB',
          '0x38',
          'https://bscscan.com',
          'BscScan',
        ]);
        break;
      case '0x61':
        resolve([
          'BSC',
          'TestBNB',
          '0x61',
          'https://testnet.bscscan.com',
          'BscScan',
        ]);
        break;
      case '0x7a69':
        resolve([
          'Local',
          'ETH',
          '0x7a69',
          'https://etherscan.io',
          'Etherscan',
        ]);
        break;
      case '0x13881':
        resolve([
          'Mumbai',
          'MATIC',
          '0x13881',
          'https://mumbai.polygonscan.com',
          'Polygonscan',
        ]);
        break;
      case '0x89':
        resolve([
          'Polygon',
          'MATIC',
          '0x89',
          'https://polygonscan.com',
          'Polygonscan',
        ]);
        break;
      default:
        resolve([
          'Unsupported',
          '?',
          chainIdLocal,
          'http://undefined.com',
          'Undefinedscan',
        ]);
        break;
    }
  });

  return result;
}

export async function getData(provider, accountsOut) {
  if (accountsOut) {
    const [network, symbol, chainId, explorerLink, explorerName] = await getNetwork(provider);
    return [accountsOut[0], network, symbol, chainId, explorerLink, explorerName];
  }
  const accounts = await provider.request({
    method: 'eth_accounts',
  });

  if (accounts[0]) {
    const [network, symbol, chainId, explorerLink, explorerName] = await getNetwork(provider);
    return [accounts[0], network, symbol, chainId, explorerLink, explorerName];
  }
  return false;
}
