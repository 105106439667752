<template>
  <div class="py-10">
    <h1 class="page-title text-center">
      Wrap Your WBTC
    </h1>
    <form
      class="form"
      @submit.prevent="wrapHandler"
    >
      <div
        class="wrapper-cards"
        :class="{'flex-row-reverse': switched }"
      >
        <div class="card-instance">
          <span class="w-60px text-center">
            WBTC
          </span>
        </div>
        <div
          aria-hidden="true"
          @click="switchHandler"
          class="switch-icon"
        >
          <img src="@/assets/img/switch.svg" alt="switch">
        </div>
        <div class="card-instance">
          <span class="w-60px text-center">
            wWBTC
          </span>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <div class="self-start font-600 text-sm">
          {{ $t('Available') }}: {{ wrappedTokenBalance }} {{ switched ? 'wWBTC' : 'WBTC' }}
        </div>
        <div class="flex items-center">
          <div class="w-full relative">
            <input
              required
              min="0"
              step="any"
              v-model="wrapInputValue"
              id="initialSupply"
              class="input input-blue w-full !rounded-r-none"
              :class="{'!pr-54px': disabledBtc}"
              type="number"
            />
            <div
              v-if="disabledBtc"
              aria-hidden="true"
              class="input-max-btn"
              @click="useMaxHandler"
            >
              max
            </div>
          </div>
          <div class="symbol">
            {{ switched ? 'wWBTC' : 'WBTC' }}
          </div>
        </div>
        <div class="self-start font-600 text-sm flex gap-2 w-full">
          <span>{{ $t('You will receive') }}</span>
          <span>&asymp;</span>
          <span>
            {{ !switched ? willReceive: wrapInputValue ? wrapInputValue : 0 }}
            {{ switched ? 'WBTC' : 'wWBTC' }}
          </span>
        </div>
      </div>

      <ui-button-loading
        v-if="switched"
        class="button button-primary px-3 py-8px mt-4"
        :loading="loadingMainBtn"
        :disabled="!disabledBtc"
      >
        {{ $t('UnWrap WBTC') }}
      </ui-button-loading>
      <ui-button-loading
        v-else
        class="button button-primary px-3 py-8px mt-4"
        :loading="loadingMainBtn"
        :disabled="!disabledBtc"
      >
        {{ $t('Wrap WBTC') }}
      </ui-button-loading>
    </form>
    <ui-modal v-model="show">
      <un-wrap-modal-content v-if="switched" />
      <wrap-modal-content v-else />
    </ui-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import WrapModalContent from '@/components/wrap/WrapModalContent.vue';
import UnWrapModalContent from '@/components/wrap/UnWrapModalContent.vue';

export default {
  components: {
    UiButtonLoading,
    UiModal,
    WrapModalContent,
    UnWrapModalContent,
  },

  computed: {
    ...mapState('wrap', {
      wbtcBalance: 'wbtcBalance',
      wwbtcBalance: 'wwbtcBalance',
      isShowModal: 'isShowModal',
      loadingMainBtn: 'loadingMainBtn',
      switched: 'switched',
      wrapValue: 'wrapValue',
    }),
    ...mapState('metamask', {
      address: 'address',
    }),
    ...mapGetters('wrap', {
      willReceive: 'willReceive',
    }),

    wrapInputValue: {
      get() {
        return this.wrapValue;
      },

      set(value) {
        this.setWrapValue(value);
      },
    },

    wrappedTokenBalance() {
      return this.switched ? this.wwbtcBalance : this.wbtcBalance;
    },

    disabledBtc() {
      return !!Number(this.wrappedTokenBalance);
    },

    show: {
      get() {
        return this.isShowModal;
      },

      set(value) {
        this.showModal(value);
      },
    },
  },

  watch: {
    address() {
      this.getBalances();
    },
  },

  created() {
    this.getBalances();
  },

  methods: {
    ...mapActions('wrap', [
      'getWbtcBalance',
      'getWwbtcBalance',
      'checkAllowance',
    ]),
    ...mapMutations('wrap', [
      'showModal',
      'setWrapValue',
      'setSwitched',
    ]),

    getBalances() {
      this.getWbtcBalance();
      this.getWwbtcBalance();
    },

    switchHandler() {
      this.setSwitched();
    },

    wrapHandler() {
      if (this.switched) {
        this.show = true;
      } else {
        this.checkAllowance();
      }
    },

    useMaxHandler() {
      this.wrapInputValue = Number(this.wrappedTokenBalance);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  @apply max-w-600px mx-auto py-6 flex flex-col gap-6;

  @include tablet {
    @apply max-w-full;
  }
}

.wrapper-cards {
  @apply flex items-center gap-4 justify-between;
}

.card-instance {
  @apply card border-b-1 border-solid border-border-color font-600;
  @apply flex justify-center items-center h-100px;
}

.switch-icon {
  @apply w-32px bg-primary-100 rounded-md hover:bg-primary-200;
  @apply p-1 cursor-pointer;
}

.input-max-btn {
  @apply absolute right-12px top-1/2 text-primary-500 hover:text-primary-600;
  @apply cursor-pointer;
  transform: translateY(-50%);
}

.symbol {
  @apply px-3 h-42px min-w-90px border border-solid border-border-color border-l-0;
  @apply flex items-center justify-center rounded-r-md bg-gray-200;
}

.gray-text {
  color: #7780A0;
}
</style>
