<template>
  <div class="flex gap-6">
    <a
      href="https://dewrap.io/"
      target="_blank"
      class="link"
    >
      About
    </a>
    <router-link
      class="link"
      :to="{ name: 'create' }"
    >
      Create
    </router-link>
    <router-link
      class="link"
      :to="{ name: 'wrap' }"
    >
      Wrap
    </router-link>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.link {
  @apply transition-all;
}

.link:not(.router-link-exact-active):hover,
.link:not(.router-link-active):hover {
  @apply text-blue-500;
}

.link.router-link-exact-active,
.link.router-link-active {
  @apply text-blue-500;
}
</style>
