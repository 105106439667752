<template>
  <div class="w-340px <phone-lg:(max-w-340px w-full)">
    <div
      class="text-gray-600 font-600"
      :class="{ 'mb-10': !pending }"
    >
      {{ $t('Please confirm changes') }}
    </div>
    <ui-pending-preloader v-if="pending"  />
    <ui-button-loading
      class="button button-primary px-3 py-2 w-full"
      :loading="pending"
      @click="confirmHandler"
    >
      {{ $t('Confirm') }}
    </ui-button-loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiPendingPreloader from '@/components/ui/UiPendingPreloader.vue';

export default {
  components: {
    UiButtonLoading,
    UiPendingPreloader,
  },

  computed: {
    ...mapState('contract', {
      pending: 'pending',
    }),

    contractAddress() {
      return this.$route.params.contract_address;
    },
  },

  methods: {
    ...mapActions('contract', ['getTokenSettings', 'changeSettings']),

    confirmHandler() {
      this.changeSettings(this.contractAddress);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
