<template>
  <div>
    <div class="flex justify-between items-center my-2">
      <div class="text-sm uppercase font-500 text-card-title-color overflow-auto">
        <div class="px-6 py-1 border-l-5 border-solid border-primary-500 <phone-lg:px-4">
          {{ $t('Flashloan (Advanced)') }}
        </div>
      </div>
      <ui-switcher
        class="mr-6"
        :checked="isShowFlashloanSettings"
        @switched="showFlashloanSettings"
      />
    </div>

    <ui-expand :is-expanded="isShowFlashloanSettings">
      <div class="card-settings-body h-scroll">
        <table class="w-full">
          <tbody>
          <tr class="text-left">
            <th class="pr-3 py-1 w-360px">{{ $t('_settings[0]') }}</th>
            <td>{{ settings['0'] }} </td>
            <td class="pl-3 text-right">
            <span :class="[fixs['0'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['0'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
            </td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1 w-360px">{{ $t('_settings[1]') }}</th>
            <td>{{ settings['1'] }}</td>
            <td class="pl-3 text-right">
            <span :class="[fixs['1'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['1'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
            </td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1 w-360px">{{ $t('_settings[2]') }}</th>
            <td>{{ settings['2'] }}</td>
            <td class="pl-3 text-right">
            <span :class="[fixs['2'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['2'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
            </td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1 w-360px">{{ $t('_settings[3]') }}</th>
            <td>{{ settings['3'] }}</td>
            <td class="pl-3 text-right">
            <span :class="[fixs['3'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['3'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
            </td>
          </tr>
          <tr class="text-left">
            <th class="pr-3 py-1 w-360px">{{ $t('_settings[4]') }}</th>
            <td>{{ settings['4'] }}</td>
            <td class="pl-3 text-right">
            <span :class="[fixs['4'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['4'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-settings-body !pt-0 flex flex-col gap-2">
        <p>{{ $t('settings0') }}</p>
        <p>{{ $t('settings1') }}</p>
        <p>{{ $t('settings2') }}</p>
        <p>{{ $t('settings3') }}</p>
        <p>{{ $t('settings4') }}</p>
      </div>
    </ui-expand>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UiSwitcher from '@/components/ui/UiSwitcher.vue';
import UiExpand from '@/components/ui/UiExpand.vue';

export default {
  components: {
    UiSwitcher,
    UiExpand,
  },

  data() {
    return {
      isShowFlashloanSettings: false,
    };
  },

  computed: {
    ...mapState('contractsettings', {
      settings: 'settings',
      fixs: 'fixs',
    }),
  },

  methods: {
    showFlashloanSettings() {
      this.isShowFlashloanSettings = !this.isShowFlashloanSettings;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
