<template>
  <div>
    <div class="header">
      <div class="app-container flex h-full">
        <div class="flex w-full items-center justify-between">
          <div class="flex items-center gap-60px h-full border-show">
            <div class="flex items-center gap-4">
              <i-burger-menu
                class="navbar-header-burger"
                :toggle="isShowSidebar"
                @click="showSidebarHandler"
              />
              <logo-nav class="logo-hide" />
            </div>
            <header-nav-menu
              class="<laptop:hidden"
            />
          </div>
          <div class="flex items-center gap-2">
            <network-select v-if="address" />
            <connect-wallet />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConnectWallet from '@/components/ConnectWallet.vue';
import IBurgerMenu from '@/components/icon/IBurgerMenu.vue';
import LogoNav from '@/components/LogoNav.vue';
import HeaderNavMenu from '@/components/HeaderNavMenu.vue';
import NetworkSelect from '@/components/NetworkSelect.vue';

export default {
  components: {
    ConnectWallet,
    IBurgerMenu,
    LogoNav,
    HeaderNavMenu,
    NetworkSelect,
  },

  props: {
    isShowSidebar: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['showSidebar'],

  computed: {
    ...mapState('metamask', {
      address: 'address',
    }),
  },

  methods: {
    showSidebarHandler() {
      this.$emit('showSidebar');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  @apply h-72px w-full bg-white border-solid border-b-1 border-gray-200 relative z-9;

  @include laptop {
    @apply h-60px;
  }
}

.navbar-header-burger {
  @apply pr-21px pl-6px hidden;

  @include laptop {
    @apply flex;
  }
}

.logo-hide {
  @include laptop {
    @apply hidden;
  }
}

.border-show {
  @include laptop {
    @apply border-solid border-r-1 border-gray-200;
  }
}
</style>
