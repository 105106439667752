<template>
  <div class="w-340px <phone-lg:(max-w-340px w-full)">
    <div v-if="isApprove">
      <confirm-action />
    </div>
    <div v-else>
      <step-approve v-if="activeStep === 1" />
      <step-confirm v-if="activeStep === 2" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ConfirmAction from '@/components/wrap/ConfirmAction.vue';
import StepApprove from '@/components/wrap/StepApprove.vue';
import StepConfirm from '@/components/wrap/StepConfirm.vue';

export default {
  components: {
    ConfirmAction,
    StepApprove,
    StepConfirm,
  },

  computed: {
    ...mapState('wrap', {
      activeStep: 'activeStep',
    }),
    ...mapGetters('wrap', {
      isApprove: 'isApprove',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
