<template>
  <div class="w-340px <phone-lg:(max-w-340px w-full)">
    <div class="text-gray-600 font-600">
      {{ $t('Do not close the modal!') }}
    </div>
    <create-contract-step1 v-if="activeStep === 1" />
    <create-contract-step2 v-if="activeStep === 2" />
    <create-contract-step3 v-if="activeStep === 3" />
    <create-contract-step4 v-if="activeStep === 4" />
    <create-contract-step5 v-if="activeStep === 5" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CreateContractStep1 from '@/components/create/steps/CreateContractStep1.vue';
import CreateContractStep2 from '@/components/create/steps/CreateContractStep2.vue';
import CreateContractStep3 from '@/components/create/steps/CreateContractStep3.vue';
import CreateContractStep4 from '@/components/create/steps/CreateContractStep4.vue';
import CreateContractStep5 from '@/components/create/steps/CreateContractStep5.vue';

export default {
  components: {
    CreateContractStep1,
    CreateContractStep2,
    CreateContractStep3,
    CreateContractStep4,
    CreateContractStep5,
  },

  computed: {
    ...mapState('create', {
      activeStep: 'activeStep',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
