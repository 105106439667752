import { Decimal } from 'decimal.js';
import { useToast } from 'vue-toastification';
import { delay } from '@/utils';
import ERC20 from '@/abis/ERC20.json';

Decimal.set({ toExpPos: 40 });

const toast = useToast();

export default {
  namespaced: true,

  state: {
    loadingMainDepositBtn: false,

    isShowModal: false,
    pending: false,

    depositValue: '',
    loadingDepositBtn: false,

    collateralBalance: 0,
    collateralSymbol: '',
    collateralDecimals: '',
    collateralAddress: '',

    wrappedWbtcBalance: 0,
    wrappedWbtcSymbol: '',
    wrappedWbtcDecimals: '',
  },

  getters: {},

  mutations: {
    setLoadingMainDepositBtn(state, value) {
      state.loadingMainDepositBtn = value;
    },

    showModal(state, value) {
      state.isShowModal = value;
    },

    setPending(state, value) {
      state.pending = value;
    },

    setDepositValue(state, value) {
      state.depositValue = value;
    },

    setLoadingDepositBtn(state, value) {
      state.loadingDepositBtn = value;
    },

    setCollateralBalance(state, value) {
      state.collateralBalance = value;
    },

    setCollateralSymbol(state, value) {
      state.collateralSymbol = value;
    },

    setCollateralDecimals(state, value) {
      state.collateralDecimals = value;
    },

    setCollateralAddress(state, value) {
      state.collateralAddress = value;
    },

    setWrappedWbtcBalance(state, value) {
      state.wrappedWbtcBalance = value;
    },

    setWrappedWbtcSymbol(state, value) {
      state.wrappedWbtcSymbol = value;
    },

    setWrappedWbtcDecimals(state, value) {
      state.wrappedWbtcDecimals = value;
    },
  },

  actions: {
    async checkCollateral(context) {
      try {
        context.commit('setLoadingMainDepositBtn', true);
        await context.dispatch('getCollateralBalance');
        await context.dispatch('getWrappedWbtcBalance');
        await delay(200);
        context.commit('setLoadingMainDepositBtn', false);
        context.commit('showModal', true);
      } catch (e) {
        context.commit('setLoadingMainDepositBtn', false);
        context.commit('showModal', false);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async getCollateralBalance(context) {
      const web3 = await context.dispatch('app/web3Instance', null, { root: true });
      const contract = new web3.eth.Contract(
        ERC20,
        context.rootState.contractsettings.collateral,
      );

      const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();
      const dec = await contract.methods.decimals().call();
      const symbol = await contract.methods.symbol().call();
      const address = context.rootState.contractsettings.collateral;

      const formatBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toFixed();

      context.commit('setCollateralBalance', formatBalance);
      context.commit('setCollateralSymbol', symbol);
      context.commit('setCollateralDecimals', dec);
      context.commit('setCollateralAddress', address);
    },

    async getWrappedWbtcBalance(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(
          ERC20,
          context.rootGetters['app/wrappedWbtcContract'].contract,
        );

        const dec = await contract.methods.decimals().call();
        const symbol = await contract.methods.symbol().call();
        const balance = await contract.methods.balanceOf(context.rootState.metamask.address).call();

        const formatBalance = new Decimal(balance).dividedBy(Decimal.pow(10, dec)).toFixed();

        context.commit('setWrappedWbtcBalance', formatBalance);
        context.commit('setWrappedWbtcDecimals', dec);
        context.commit('setWrappedWbtcSymbol', symbol);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async depositNative(context, contractAddress) {
      context.commit('setPending', true);
      context.commit('setLoadingDepositBtn', true);

      try {
        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = await new web3.eth.Contract(
          ERC20,
          context.rootGetters['app/wrappedWbtcContract'].contract,
        );

        const tokenAmount = new Decimal(context.state.depositValue.toString())
          .times(Decimal.pow(10, context.state.wrappedWbtcDecimals));

        web3.eth.sendTransaction({
          from: context.rootState.metamask.address,
          gasPrice: context.rootGetters['app/gasPrice'],
          to: context.rootGetters['app/wrappedWbtcContract'].contract,
          data: contract.methods.transfer(
            contractAddress,
            tokenAmount.toString(),
          ).encodeABI(),
        })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', async (receipt) => {
            console.log(receipt);
            await context.dispatch('metamask/getAccountBalance', null, { root: true });
            await context.dispatch('contract/myTokenBalance', contractAddress, { root: true });
            await context.dispatch('contract/getWrappedWbtcBalance', null, { root: true });
            await context.dispatch('contract/myCollateralBalance', {
              collateral: context.state.collateralAddress,
              contractAddress,
            }, { root: true });

            context.commit('setPending', false);
            context.commit('setLoadingDepositBtn', false);
            context.commit('showModal', false);
            context.commit('setDepositValue', '');

            toast.success('Deposit success', {
              timeout: 3000,
            });
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setPending', false);
            context.commit('setLoadingDepositBtn', false);
            context.commit('showModal', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        context.commit('setPending', false);
        context.commit('setLoadingDepositBtn', false);
        context.commit('showModal', false);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },

    async depositToken(context, contractAddress) {
      try {
        context.commit('setPending', true);
        context.commit('setLoadingDepositBtn', true);

        await context.dispatch('app/getGasPrice', null, { root: true });
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });

        const contract = new web3.eth.Contract(ERC20, context.state.collateralAddress);

        const tokenAmount = new Decimal(context.state.depositValue.toString())
          .times(Decimal.pow(10, context.state.collateralDecimals));

        web3.eth.sendTransaction({
          from: context.rootState.metamask.address,
          gasPrice: context.rootGetters['app/gasPrice'],
          to: context.state.collateralAddress,
          data: contract.methods.transfer(contractAddress, tokenAmount.toString()).encodeABI(),
        })
          .on('transactionHash', (hash) => {
            console.log(hash);
          })
          .on('receipt', async (receipt) => {
            console.log(receipt);
            await context.dispatch('metamask/getAccountBalance', null, { root: true });
            await context.dispatch('contract/myTokenBalance', contractAddress, { root: true });
            await context.dispatch('contract/myCollateralBalance', {
              collateral: context.state.collateralAddress,
              contractAddress,
            }, { root: true });

            context.commit('setPending', false);
            context.commit('setLoadingDepositBtn', false);
            context.commit('showModal', false);

            toast.success('Deposit success', {
              timeout: 3000,
            });
          })
          .on('error', (error) => {
            console.error(error);
            context.commit('setPending', false);
            context.commit('setLoadingDepositBtn', false);
            context.commit('showModal', false);

            toast.error(error.message, {
              timeout: 3000,
            });
            throw error;
          });
      } catch (e) {
        context.commit('setPending', false);
        context.commit('setLoadingDepositBtn', false);
        context.commit('showModal', false);
        toast.error(e.message, {
          timeout: 3000,
        });
        throw e;
      }
    },
  },
};
