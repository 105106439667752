<template>
  <div class="py-10">
    <div class="flex flex-col gap-2 text-center">
      <h1 class="page-title">
        {{ $t('Create Contract Token') }}
      </h1>
      <div class="text-gray-500">
        {{ $t('Create your own derivative token') }}
      </div>
    </div>

    <form
      class="form"
      @submit.prevent="createHandler"
    >
      <main-settings />
      <token-settings />
      <div class="card-settings">
        <div class="text-center text-lg uppercase">
          {{ $t('Actions Settings') }}
        </div>
        <deposit-settings />
        <withdrawal-settings />
        <lock-time-settings />
        <flashloan-settings />
      </div>
      <ui-button-loading
        :loading="loadingMainCreateBtn"
        class="button button-primary px-3 py-8px"
      >
        {{ $t('Create Contract') }}
      </ui-button-loading>
    </form>
    <ui-modal
      v-model="show"
      :mask-closable="false"
      @close="closeModalHandler"
    >
      <create-contract-steps v-if="isCollateralEnough" />
      <collateral-checking v-else />
    </ui-modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import MainSettings from '@/components/create/MainSettings.vue';
import TokenSettings from '@/components/create/TokenSettings.vue';
import DepositSettings from '@/components/create/DepositSettings.vue';
import WithdrawalSettings from '@/components/create/WithdrawalSettings.vue';
import LockTimeSettings from '@/components/create/LockTimeSettings.vue';
import FlashloanSettings from '@/components/create/FlashloanSettings.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import CreateContractSteps from '@/components/create/CreateContractSteps.vue';
import CollateralChecking from '@/components/create/CollateralChecking.vue';

export default {
  components: {
    MainSettings,
    TokenSettings,
    DepositSettings,
    WithdrawalSettings,
    LockTimeSettings,
    FlashloanSettings,
    UiButtonLoading,
    UiModal,
    CreateContractSteps,
    CollateralChecking,
  },

  computed: {
    ...mapState('create', {
      isShowModal: 'isShowModal',
      loadingMainCreateBtn: 'loadingMainCreateBtn',
      isCollateralEnough: 'isCollateralEnough',
      activeStep: 'activeStep',
    }),

    show: {
      get() {
        return this.isShowModal;
      },

      set(value) {
        this.showModal(value);
      },
    },
  },

  methods: {
    ...mapMutations('create', ['showModal', 'setIsCollateralEnough', 'setActiveStep']),
    ...mapActions('create', ['checkCollateral']),

    createHandler() {
      this.checkCollateral();
    },

    closeModalHandler() {
      if (this.activeStep === 1 || this.activeStep === 4) {
        setTimeout(() => {
          this.setIsCollateralEnough(false);
          this.setActiveStep(1);
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  @apply max-w-848px mx-auto py-4 flex flex-col gap-4;

  @include laptop {
    @apply max-w-full;
  }
}
</style>
