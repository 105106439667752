<template>
  <div>
    <div class="card-settings-title">
      <div class="flex items-center gap-2">
        <span>{{ $t('Withdrawal fee') }}</span>
        <span class="font-600 text-gray-600">
          ({{ $t('before locktime ending') }})
        </span>
      </div>
    </div>
    <div class="card-settings-body h-scroll">
      <table class="w-full">
        <tbody>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Withdrawal fee') }}</th>
          <td>{{ settings['10'] }} %</td>
          <td class="pl-3 text-right">
            <span :class="[fixs['10'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['10'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Users minimal fee') }}</th>
          <td>{{ `${settings['12']} ${collateralSymbol}`}}</td>
          <td class="pl-3 text-right">
            <span :class="[fixs['12'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['12'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        <tr class="text-left">
          <th class="pr-3 py-1 w-360px">{{ $t('Owners percent of users fee') }}</th>
          <td>{{ settings['13'] }} %</td>
          <td class="pl-3 text-right">
            <span :class="[fixs['13'] ? 'text-green-500' : 'text-red-500']">
              {{ fixs['13'] ? $t('Fixed') : $t('Unfixed') }}
            </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('contractsettings', {
      settings: 'settings',
      fixs: 'fixs',
      collateralSymbol: 'collateralSymbol',
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
