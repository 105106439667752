<template>
  <div>
    <div class="flex justify-between items-center my-2">
      <div class="text-sm uppercase font-500 text-card-title-color overflow-auto">
        <div class="px-6 py-1 border-l-5 border-solid border-primary-500 <phone-lg:px-4">
          {{ $t('Flashloan (Advanced)') }}
        </div>
      </div>
      <ui-switcher
        class="mr-6"
        :checked="isShowFlashloanSettings"
        @switched="showFlashloanSettings"
      />
    </div>

    <ui-expand :is-expanded="isShowFlashloanSettings">
      <div class="px-6 pt-3 pb-6 <phone-lg:px-4 flex flex-col gap-6">
        <div class="grid grid-cols-[1fr,84px] gap-4">
          <div class="form-group">
            <label
              for="settings0"
              class="self-start flex items-center gap-2"
            >
              {{ $t('_settings[0]') }}
            </label>
            <input
              v-model="settings0"
              id="settings0"
              class="input input-blue w-full"
              type="number"
            />
          </div>
          <div class="form-group justify-self-end">
            <span>
              {{ $t('Fixed')  }}
            </span>
            <div class="h-42px flex items-center gap-2">
              <ui-switcher
                :checked="this.fixs['0']"
                @switched="setFixsHandler(0)"
              />
              <span class="inline-block w-36px">
                {{ this.fixs['0'] }}
              </span>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-[1fr,84px] gap-4">
          <div class="form-group">
            <label
              for="settings1"
              class="self-start flex items-center gap-2"
            >
              {{ $t('_settings[1]') }}
            </label>
            <input
              v-model="settings1"
              id="settings1"
              class="input input-blue w-full"
              type="number"
            />
          </div>
          <div class="form-group justify-self-end">
            <span>
              {{ $t('Fixed')  }}
            </span>
            <div class="h-42px flex items-center gap-2">
              <ui-switcher
                :checked="this.fixs['1']"
                @switched="setFixsHandler(1)"
              />
              <span class="inline-block w-36px">
                {{ this.fixs['1'] }}
              </span>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-[1fr,84px] gap-4">
          <div class="form-group">
            <label
              for="settings2"
              class="self-start flex items-center gap-2"
            >
              {{ $t('_settings[2]') }}
            </label>
            <input
              v-model="settings2"
              id="settings2"
              class="input input-blue w-full"
              type="number"
            />
          </div>
          <div class="form-group justify-self-end">
            <span>
              {{ $t('Fixed')  }}
            </span>
            <div class="h-42px flex items-center gap-2">
              <ui-switcher
                :checked="this.fixs['2']"
                @switched="setFixsHandler(2)"
              />
              <span class="inline-block w-36px">
                {{ this.fixs['2'] }}
              </span>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-[1fr,84px] gap-4">
          <div class="form-group">
            <label
              for="settings3"
              class="self-start flex items-center gap-2"
            >
              {{ $t('_settings[3]') }}
            </label>
            <input
              v-model="settings3"
              id="settings3"
              class="input input-blue w-full"
              type="number"
            />
          </div>
          <div class="form-group justify-self-end">
            <span>
              {{ $t('Fixed')  }}
            </span>
            <div class="h-42px flex items-center gap-2">
              <ui-switcher
                :checked="this.fixs['3']"
                @switched="setFixsHandler(3)"
              />
              <span class="inline-block w-36px">
                {{ this.fixs['3'] }}
              </span>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-[1fr,84px] gap-4">
          <div class="form-group">
            <label
              for="settings4"
              class="self-start flex items-center gap-2"
            >
              {{ $t('_settings[4]') }}
            </label>
            <input
              v-model="settings4"
              id="settings4"
              class="input input-blue w-full"
              type="number"
            />
          </div>
          <div class="form-group justify-self-end">
            <span>
              {{ $t('Fixed')  }}
            </span>
            <div class="h-42px flex items-center gap-2">
              <ui-switcher
                :checked="this.fixs['4']"
                @switched="setFixsHandler(4)"
              />
              <span class="inline-block w-36px">
                {{ this.fixs['4'] }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-2">
          <p>{{ $t('settings0') }}</p>
          <p>{{ $t('settings1') }}</p>
          <p>{{ $t('settings2') }}</p>
          <p>{{ $t('settings3') }}</p>
          <p>{{ $t('settings4') }}</p>
        </div>
      </div>
    </ui-expand>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import UiSwitcher from '@/components/ui/UiSwitcher.vue';
import UiExpand from '@/components/ui/UiExpand.vue';

export default {
  components: {
    UiSwitcher,
    UiExpand,
  },

  data() {
    return {
      isShowFlashloanSettings: false,
    };
  },

  computed: {
    ...mapState('createsettings', {
      settings: 'settings',
      fixs: 'fixs',
    }),

    settings0: {
      get() {
        return this.settings['0'];
      },
      set(value) {
        this.setSettings({ value, i: 0 });
      },
    },

    settings1: {
      get() {
        return this.settings['1'];
      },
      set(value) {
        this.setSettings({ value, i: 1 });
      },
    },

    settings2: {
      get() {
        return this.settings['2'];
      },
      set(value) {
        this.setSettings({ value, i: 2 });
      },
    },

    settings3: {
      get() {
        return this.settings['3'];
      },
      set(value) {
        this.setSettings({ value, i: 3 });
      },
    },

    settings4: {
      get() {
        return this.settings['4'];
      },
      set(value) {
        this.setSettings({ value, i: 4 });
      },
    },
  },

  methods: {
    ...mapMutations('createsettings', [
      'setSettings',
    ]),
    ...mapMutations('createsettings', [
      'setFixs',
    ]),

    showFlashloanSettings() {
      this.isShowFlashloanSettings = !this.isShowFlashloanSettings;
    },

    setFixsHandler(i) {
      this.setFixs({ value: !this.fixs[i], i });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  @apply flex flex-col gap-2;
}
</style>
