<template>
  <table class="w-full">
    <thead>
    <tr
      class="border-b-1 border-solid border-border-color"
      :class="{ 'border-t-1': borderTop }"
    >
      <th class="py-3 px-2 align-top">
        <ui-skeleton-box
          height="24.5px"
          width="100%"
        />
      </th>
    </tr>
    </thead>
    <tbody>
    <template v-for="i in 1" :key="i">
      <tr class="border-b-1 border-solid border-border-color">
        <td class="py-6 px-2">
          <ui-skeleton-box
            height="33px"
            width="100%"
          />
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';

export default {
  components: {
    UiSkeletonBox,
  },

  props: {
    borderTop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
