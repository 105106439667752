<template>
  <form
    @submit.prevent="changeSettingsHandler"
    class="flex flex-col gap-4"
  >
    <div class="card-settings relative">
      <div class="flex items-center justify-center gap-3">
        <button
          v-if="edit"
          class="back-btn"
          @click="backBtnHandler"
        >
          <feather-icon type="arrow-left" size="18" />
          <span>{{ $t('Back') }}:</span>
        </button>
        <div class="text-lg uppercase">
          {{ $t('Actions Settings') }}
        </div>
        <button
          v-if="editable && !edit"
          class="edit-btn"
          @click="editBtnHandler"
        >
          <span>{{ $t('Edit') }}:</span>
          <feather-icon type="edit" size="18" />
        </button>
      </div>
      <actions-settings-view v-if="!edit" />
      <actions-settings-edit v-if="editable && edit" />
      <ui-loading :loading="loading" />
    </div>
    <button
      v-if="editable && edit"
      type="submit"
      class="button button-primary px-3 py-8px w-full"
    >
      {{ $t('Change Settings') }}
    </button>
  </form>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ActionsSettingsView from '@/components/contract/ActionsSettingsView.vue';
import ActionsSettingsEdit from '@/components/contract/ActionsSettingsEdit.vue';
import UiLoading from '@/components/ui/UiLoading.vue';

export default {
  components: {
    ActionsSettingsView,
    ActionsSettingsEdit,
    UiLoading,
  },

  data() {
    return {
      edit: false,
      loading: false,
    };
  },

  computed: {
    ...mapState('metamask', {
      address: 'address',
    }),
    ...mapState('contractsettings', {
      owner: 'owner',
    }),

    contractAddress() {
      return this.$route.params.contract_address;
    },

    editable() {
      return !this.address
        ? false
        : this.address.toLowerCase() === this.owner.toLowerCase();
    },
  },

  watch: {
    address() {
      this.edit = false;
    },
  },

  methods: {
    ...mapActions('contract', ['getTokenSettings']),
    ...mapMutations('contract', ['showModal']),

    editBtnHandler() {
      this.edit = true;
    },

    async backBtnHandler() {
      this.edit = false;
      try {
        this.loading = true;
        await this.getTokenSettings(this.contractAddress);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    changeSettingsHandler() {
      this.showModal(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.back-btn,
.edit-btn {
  @apply flex items-center gap-2 bg-primary-100 hover:bg-primary-200 rounded-md px-2 py-1;
  @apply cursor-pointer;
}
</style>
