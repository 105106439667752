<template>
  <div class="relative mt-10">
    <h1 class="page-title mb-3">
      {{ $t('My Created Contracts') }}
    </h1>

    <div class="navlink-wrapper">
      <router-link
        class="navlink"
        :to="{ name: 'verified', params: { address } }"
      >
        {{ $t('Verified') }}
      </router-link>
      <router-link
        class="navlink"
        :to="{ name: 'unverified', params: { address } }"
      >
        {{ $t('Unverified') }}
      </router-link>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  computed: {
    address() {
      return this.$route.params.address;
    },
  },
};
</script>

<style lang="scss" scoped>
.navlink-wrapper {
  @apply flex flex-nowrap relative z-2 whitespace-nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.navlink-wrapper {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.navlink {
  @apply py-3 mr-8;

  &:last-child {
    @apply mr-0;
  }
}

.navlink.router-link-exact-active,
.navlink.router-link-active {
  @apply border-b-2 border-solid border-primary-500;
}

.line {
  @apply absolute z-1 bottom-0 h-2px w-full bg-gray-200;
}
</style>
