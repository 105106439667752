import { Decimal } from 'decimal.js';
import {
  calculatePrice,
  numberFormatFloat,
  paginate,
  sliceIntoChunks,
  supplyFormat,
  currencyPriceFormatter,
} from '@/utils';
import DerivativeNextFabAbi from '@/abis/DerivativeNextFab.json';
import VerifiedAddressesAbi from '@/abis/VerifiedAddresses.json';
import NativePriceContractAbi from '@/abis/NativePriceContract.json';

export default {
  namespaced: true,

  state: {
    derivativeList: [],
    derivativeListPaginate: [],
    derivativeListTotal: 0,
    derivativeListLoaded: false,
    derivativeListChunkSize: 10,

    unverifiedDerivativeList: [],
    unverifiedDerivativeListPaginate: [],
    unverifiedDerivativeListTotal: 0,
    unverifiedDerivativeListLoaded: false,
    unverifiedDerivativeListChunkSize: 10,
  },

  getters: {
    derivativeList(state, getters, rootState, rootGetters) {
      const wbtcPrice = rootGetters['app/wbtcPrice'];

      return [...state.derivativeList].map((item, i) => {
        const priceUsd = new Decimal(item.price).mul(wbtcPrice);
        const marketCapUsd = new Decimal(item.marketCap).mul(wbtcPrice);

        return {
          ...item,
          index: i + 1,
          price: currencyPriceFormatter(priceUsd),
          marketCap: currencyPriceFormatter(marketCapUsd),
        };
      });
    },

    unverifiedDerivativeList(state, getters, rootState, rootGetters) {
      const wbtcPrice = rootGetters['app/wbtcPrice'];

      return [...state.unverifiedDerivativeList].map((item, i) => {
        const priceUsd = new Decimal(item.price).mul(wbtcPrice);
        const marketCapUsd = new Decimal(item.marketCap).mul(wbtcPrice);

        return {
          ...item,
          index: i + 1,
          price: currencyPriceFormatter(priceUsd),
          marketCap: currencyPriceFormatter(marketCapUsd),
        };
      });
    },
  },

  mutations: {
    setDerivativeList(state, value) {
      state.derivativeList = value;
    },

    setDerivativeListPaginate(state, value) {
      state.derivativeListPaginate = value;
    },

    setDerivativeListTotal(state, value) {
      state.derivativeListTotal = value;
    },

    setDerivativeListLoaded(state, value) {
      state.derivativeListLoaded = value;
    },

    setUnverifiedDerivativeList(state, value) {
      state.unverifiedDerivativeList = value;
    },

    setUnverifiedDerivativeListPaginate(state, value) {
      state.unverifiedDerivativeListPaginate = value;
    },

    setUnverifiedDerivativeListTotal(state, value) {
      state.unverifiedDerivativeListTotal = value;
    },

    setUnverifiedDerivativeListLoaded(state, value) {
      state.unverifiedDerivativeListLoaded = value;
    },
  },

  actions: {
    async loadDerivativeList(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', true, { root: true });
        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });

        const contract = new web3.eth.Contract(
          VerifiedAddressesAbi,
          context.rootGetters['app/verifyContract'].contract,
        );

        const options = {
          fromBlock: context.rootGetters['app/verifyContract'].block,
          toBlock: 'latest',
        };

        const response = await contract.getPastEvents('ContractVerified', options);

        const result = response.map((item) => (
          ({ token }) => (
            { token }
          )
        )(item.returnValues));

        context.commit('setDerivativeListTotal', result.length);

        const addressArray = result.map((item) => item.token);
        const chunksArray = sliceIntoChunks(addressArray, context.state.derivativeListChunkSize);

        const tokensInfo = (await Promise.all(chunksArray.map(async (chunk) => {
          return context.dispatch('getTokensInfo', chunk);
        }))).flat();

        const tokensInfoObjects = tokensInfo.map((item, i) => {
          const supply = supplyFormat(item.totalSupply, item.decimals);
          const price = calculatePrice(item.value, item.decimals);
          const marketCap = new Decimal(price).mul(supply);

          return {
            current: addressArray[i],
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            totalSupply: supply,
            tokenAddress: item.tokenAddress,
            owner: item.owner,
            price: price.toFixed(),
            marketCap: marketCap.toFixed(),
          };
        });

        const marketCapAscSorted = tokensInfoObjects.sort((a, b) => {
          return Number(b.marketCap) - Number(a.marketCap);
        });

        context.commit('setDerivativeList', marketCapAscSorted);
        context.commit('setDerivativeListLoaded', true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    getDerivativeListPaginate(context, { page, perPage }) {
      const paginateArray = paginate(context.getters.derivativeList, page, perPage);
      context.commit('setDerivativeListPaginate', paginateArray);
    },

    async loadUnverifiedDerivativeList(context) {
      try {
        const web3 = await context.dispatch('app/web3Instance', true, { root: true });
        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });

        const contract = new web3.eth.Contract(
          DerivativeNextFabAbi,
          context.rootGetters['app/factoryContract'].contract,
        );

        const options = {
          fromBlock: context.rootGetters['app/factoryContract'].block,
          toBlock: 'latest',
        };

        const response = await contract.getPastEvents('CreateDerivativeNext', options);

        const result = response.map((item) => (
          ({ current }) => {
            return { current };
          }
        )(item.returnValues));

        const addressArray = result.map((item) => item.current);
        const chunksArray = sliceIntoChunks(addressArray, context.state.unverifiedDerivativeListChunkSize);

        const tokensInfo = (await Promise.all(chunksArray.map(async (chunk) => {
          return context.dispatch('getTokensInfo', chunk);
        }))).flat();

        const addCurrentArray = tokensInfo.map((item, i) => ({
          current: addressArray[i],
          ...item,
        }));

        const isVerifiedArray = await Promise.all(addCurrentArray.map(async (item) => {
          const isVerified = await context.dispatch('verify/isVerified', item.current, { root: true });
          return { ...item, isVerified };
        }));

        const unverified = isVerifiedArray.filter((item) => !item.isVerified);

        context.commit('setUnverifiedDerivativeListTotal', unverified.length);

        const tokensInfoObjects = unverified.map((item) => {
          const supply = supplyFormat(item.totalSupply, item.decimals);
          const price = calculatePrice(item.value, item.decimals);
          const marketCap = new Decimal(price).mul(supply);

          return {
            current: item.current,
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            totalSupply: supply,
            tokenAddress: item.tokenAddress,
            owner: item.owner,
            price: price.toFixed(),
            marketCap: marketCap.toFixed(),
          };
        });

        const marketCapAscSorted = tokensInfoObjects.sort((a, b) => {
          return Number(b.marketCap) - Number(a.marketCap);
        });

        context.commit('setUnverifiedDerivativeList', marketCapAscSorted);
        context.commit('setUnverifiedDerivativeListLoaded', true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    getUnverifiedDerivativeListPaginate(context, { page, perPage }) {
      const paginateArray = paginate(context.getters.unverifiedDerivativeList, page, perPage);
      context.commit('setUnverifiedDerivativeListPaginate', paginateArray);
    },

    async getTokensInfo(context, addressArray) {
      try {
        const web3 = await context.dispatch('app/web3Instance', null, { root: true });
        const contract = new web3.eth.Contract(
          NativePriceContractAbi,
          context.rootGetters['app/nativePriceContract'].contract,
        );
        return await contract.methods.getTokenInfoWithPrice(
          [...addressArray],
          context.rootGetters['app/derivativeRoot'].contract,
          '1000000000000000000',
        ).call();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async loadDerivativeListOld(context, { page, perPage, filter = null }) {
      try {
        const web3 = await context.dispatch('app/web3Instance', true, { root: true });

        const chainId = await web3.eth.getChainId();
        context.commit('app/setNetworkContract', chainId, { root: true });

        const contract = new web3.eth.Contract(
          DerivativeNextFabAbi,
          context.rootGetters['app/factoryContract'].contract,
        );

        const options = {
          fromBlock: context.rootGetters['app/factoryContract'].block,
          toBlock: 'latest',
        };

        if (filter) {
          options.filter = filter;
        }

        const response = await contract.getPastEvents('CreateDerivativeNext', options);

        const result = response.map((item) => (
          ({ token, owner, decimals, name, symbol, current, timestamp }) => (
            { token, owner, decimals, name, symbol, current, timestamp }
          )
        )(item.returnValues));

        context.commit('setDerivativeListTotal', result.length);

        const paginatable = paginate(result, page, perPage);
        const addressArray = paginatable.map((item) => item.current);

        const tokensInfo = await context.dispatch('getTokensInfo', addressArray);

        const tokensInfoObjects = tokensInfo.map((item, i) => {
          const supply = supplyFormat(item.totalSupply, item.decimals);
          return {
            current: addressArray[i],
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            totalSupply: numberFormatFloat(supply),
            tokenAddress: item.tokenAddress,
            owner: item.owner,
            price: item.price,
          };
        });

        context.commit('setDerivativeList', tokensInfoObjects);
        context.commit('setDerivativeListLoaded', true);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
